/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSpecificNote } from "../store/selectors/notes/get_notes_selector"
import { useLocation, useParams } from "react-router-dom"
import { createNote, setSpecificNote } from "../store/actions/notes/create_note"
import { isEmpty, debounce } from "lodash"
import { Input } from "antd"
import { getPageTitle } from "../store/actions/links/get-page-title"
import {
  getFilesPendingStatus,
  getLinksPendingStatus,
  getSpecificCompany,
} from "../store/selectors"
import {
  updateDiary,
  updateDiaryActivity,
} from "../store/actions/diaries/edit-diary"
import { createDiaryActivity } from "../store/actions/diaries/create-diary"
import { createNewLink, updateLink } from "../store/actions/links/create-link"
import { removeChipFromCompanyNote } from "../store/actions/companies/remove-company"
import { downloadFile } from "../store/actions/file_manager/download-file"
import { URLS } from "../common/urls"
import { setLinksPendingStatus } from "../store/actions/links/get_links"
import { editNote } from "../store/actions/notes/edit_note"
import { FeaturesTypes } from "../common/constants"
import { getDraftTagsNote } from "../store/selectors/notes/get_notes_selector"
import { setDraftTagsNote } from "../store/actions/notes/create_note"
import { useAction } from "./useAction"
import { updateModalProps } from "../store/actions/ui/update_ui"

export const useNoteEditor = (
  handleAddNote,
  handleEditNote,
  title,
  onClose,
  is_diary,
  onSaveCallback,
  saveCompany
) => {
  const editorRef = useRef()

  const location = useLocation()
  const [showEditor, setShowEditor] = useState(false)
  const [loadingQuill, setLoadingQuill] = useState(false)
  const [prevent, setPrevent] = useState(false)
  const [urlTitle, setUrlTitle] = useState("")
  const [url, setUrl] = useState("")
  const [addTagStep, setAddTagStep] = useState(0)
  const specificNote = useSelector(getSpecificNote)
  const links = useMemo(() => specificNote.links, [specificNote])
  const atts = useMemo(() => specificNote.atts, [specificNote])
  const chips = useMemo(
    () => [...(specificNote.links || []), ...(specificNote.atts || [])],
    [specificNote]
  )
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const inputRef = useRef()
  const titleRef = useRef()
  const company = useSelector(getSpecificCompany)
  const file_is_pending = useSelector(getFilesPendingStatus)
  const link_is_pending = useSelector(getLinksPendingStatus)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const [created, setCreated] = useState(false)
  const currentTags = useSelector(getDraftTagsNote)
  const [data, setData] = useState({
    ...specificNote,
  })

  const [showQuillChild, setShowQuillChild] = useState(
    !!specificNote?.body_html?.replace(/(<([^>]+)>)/gi, "")
  )

  const createNoteAction = useAction(createNote)
  const editNoteAction = useAction(editNote)
  const updateDiaryAction = useAction(updateDiary)
  const createDiaryActivityAction = useAction(createDiaryActivity)
  const updateDiaryActivityAction = useAction(updateDiaryActivity)
  const setSpecificNoteAction = useAction(setSpecificNote)
  const updateModalPropsAction = useAction(updateModalProps)
  const onCloseEditor = useCallback(() => {
    onClose()
  }, [onClose])
  const createNoteBefore = useCallback(async () => {
    let _data = {
      ...data,
      note_type: "N",
      tags: currentTags,
    }

    if (saveCompany && !company?.id) {
      const com = await saveCompany()
      if (com?.id) {
        _data.company_id = com.id
        _data.note_type = "C"
      }
    }

    if (company?.id) {
      _data.note_type = "C"
      _data.company_id = company.id
    }

    if (!Number(_data.workspace_id)) {
      delete _data.workspace_id
    }

    const note = await createNoteAction(_data, true)

    await updateModalPropsAction({
      isEdit: true,
      status: "edit",
      isAdd: false,
      type: FeaturesTypes.NOTE,
    })

    return note
  }, [params, data, company, dispatch, currentTags])
  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 27 && specificNote.title?.trim()) {
        if (isEdit) {
          e.stopPropagation()
          setIsEdit(false)
        } else {
          onClose()
        }
      }
    },
    [isEdit, onClose, specificNote.title]
  )
  const handleChangeUrl = useCallback(
    (e) => {
      if (urlTitle) {
        setUrlTitle(null)
      }
      e.target.focus()
      setUrl(e.target.value)
    },
    [urlTitle]
  )
  const handleInputBlur = useCallback(() => {
    if (specificNote.title?.trim()) {
      setIsEdit(false)
    }
  }, [specificNote.title])
  const handlePressEnter = useCallback(() => {
    if (data.title.trim()) {
      setIsEdit(false)
    }
    handleEditNote(true, data)
  }, [data, handleEditNote])
  const openEditMode = () => {
    setIsEdit(true)
  }
  const renderNoteModalTitle = useCallback(() => {
    return isEmpty(specificNote) ? (
      title || "Create New Note"
    ) : (
      <div>
        {isEdit ? (
          <Input
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            value={data.title}
            onChange={(e) => {
              setData({ ...data, title: e.target.value })
            }}
            onPressEnter={handlePressEnter}
          />
        ) : (
          <div onDoubleClick={openEditMode}>{data.title}</div>
        )}
      </div>
    )
  }, [
    data,
    handleInputBlur,
    handleKeyDown,
    handlePressEnter,
    isEdit,
    specificNote,
    title,
  ])

  const actionAfterTyping = async (d) => {
    setLoadingQuill(true)
    try {
      if (is_diary) {
        await handleAutoSave(d)
      } else if (d.id) {
        delete d.tags
        const r = await editNoteAction(d)
        if (location.pathname?.includes(URLS.HOME)) {
          await updateDiaryActivityAction({
            data: r,
            activity_key: FeaturesTypes.NOTE,
          })
        }
      } else {
        await handleAutoSave(d)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingQuill(false)
    }
  }

  const debouncedAction = useCallback(debounce(actionAfterTyping, 500), [])

  useEffect(() => {
    return () => {
      debouncedAction.cancel()
    }
  }, [debouncedAction])

  const handFocusQuill = useCallback(() => {
    setShowQuillChild(true)
    editorRef?.current?.editor?.focus()
  }, [])

  const onNoteChange = useCallback(
    (body_html, deltaObj, source, editor) => {
      setData({
        ...data,
        ...specificNote,
        title: data.title,
        body_html,
        body_editor: editor.getContents(),
        body_txt: editor.getText(),
      })
      if (source === "user") {
        if (loadingQuill) return
        debouncedAction({
          ...data,
          ...specificNote,
          tags: currentTags,
          title: data.title,
          body_html,
          body_editor: editor.getContents(),
          body_txt: editor.getText(),
        })
      }
    },
    [data, currentTags, specificNote, loadingQuill]
  )
  const handleTitleChange = useCallback(
    (d) => {
      !loadingQuill &&
        debouncedAction({ ...d, tags: currentTags, id: specificNote.id })
    },
    [data, specificNote, currentTags, loadingQuill]
  )

  const handleSave = useCallback(async () => {
    if (!url.trim()) {
      return
    }
    setPopoverIsOpen(false)
    let note_id = specificNote.id
    if (!created && !note_id) {
      const _note = await createNoteBefore()
      setCreated(true)
      dispatch(
        setSpecificNote({
          ..._note,
          links: [...(specificNote.links || [])],
          atts: [...(specificNote.atts || [])],
        })
      )
      note_id = _note?.id
    }
    setUrl("")
    setUrlTitle(null)
    if (!note_id) {
      return
    }
    return dispatch(createNewLink({ url, note_id }, true)).then((data) => {
      setPrevent(false)
      return data
    })
  }, [specificNote, url, dispatch, specificNote.id, created])
  const handlePressEnterOnLink = useCallback(async () => {
    setPrevent(true)
    dispatch(setLinksPendingStatus(true))
    if (prevent) {
      return
    }
    try {
      const { id } = await handleSave()
      const title = await getPageTitle(url)
      dispatch(updateLink(id, { caption: title }, true))
      setPrevent(false)
      handFocusQuill()
    } catch (e) {
      console.log(e)
    }

    //eslint-disable-next-line
  }, [dispatch, handleSave, url, prevent])
  const togglePopover = useCallback(() => {
    setPopoverIsOpen((actual) => !actual)
  }, [])

  const handleAutoSave = useCallback(
    async (d) => {
      delete d.links
      delete d.atts
      !d.company_id && delete d.company_id
      !d.workspace_id && delete d.workspace_id
      const _note = {
        company_id: company && company.id,
        ...d,
        note_type: location.pathname.includes(URLS.LIBRARY) ? "N" : "C",
      }
      try {
        if (!isEdit && !created && !specificNote.id) {
          if (is_diary) {
            if (_note.id) {
              const res = await updateDiaryAction({ ..._note, note_type: "D" })
              await setSpecificNoteAction(res.payload)
              setData(res.payload)
              onSaveCallback && onSaveCallback(res.payload)
            } else {
              const res = await createDiaryActivityAction({
                ..._note,
                note_type: "D",
              })
              await setSpecificNoteAction(res)
              // setData(res)
              onSaveCallback && onSaveCallback({ ...res, tags: d.tags })
            }
          } else {
            if (saveCompany && !company?.id) {
              const company = await saveCompany()
              _note.company_id = company.id
            }
            const res = await createNoteAction(_note, true)

            await setSpecificNoteAction({ ...res, tags: d.tags })
            // setData({ ...res, tags: d.tags })
            onSaveCallback && onSaveCallback({ ...res, tags: d.tags })
          }
        } else {
          if (_note.id) {
            const res = await updateDiaryAction({ ..._note, note_type: "D" })
            await setSpecificNoteAction(res.payload)
            // setData(res.payload)
            onSaveCallback && onSaveCallback(res.payload)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    [isEdit, created, specificNote.id, dispatch, location.pathname, company]
  )
  const handleSubmit = useCallback(async () => {
    const _data = Object.assign({}, data)
    delete _data.tags
    delete _data.links
    delete _data.atts
    !_data.company_id && delete _data.company_id
    !_data.workspace_id && delete _data.workspace_id
    if (!isEdit && !created && !specificNote.id) {
      const _note = {
        company_id: company && company.id,
        ..._data,
        note_type: location.pathname.includes(URLS.LIBRARY) ? "N" : "C",
        tags: currentTags,
      }
      if (is_diary) {
        handleAddNote({ ..._note, note_type: "D" })
      } else {
        if (saveCompany && !company?.id && !isEdit) {
          const company = await saveCompany()
          _note.company_id = company.id
        }
        await createNoteAction(_note, true)
        onClose()
      }
    } else {
      handleEditNote(false, { ..._data, id: specificNote.id }, true)
    }
    dispatch(setSpecificNote({}))
  }, [
    params,
    data,
    isEdit,
    created,
    specificNote.id,
    dispatch,
    location.pathname,
    currentTags,
    is_diary,
    handleAddNote,
    onClose,
    handleEditNote,
    company,
  ])
  const handleRemoveChip = useCallback(
    (chip) => {
      const key = chip.orig_name ? "atts" : "links"
      dispatch(removeChipFromCompanyNote(chip, key))
    },
    [dispatch]
  )
  const handleDownloadFile = useCallback(
    (file) => {
      dispatch(downloadFile(file))
    },
    [dispatch]
  )
  useEffect(() => {
    if (!data.title?.trim() && !isEmpty(specificNote) && specificNote.id) {
      setIsEdit(true)
    }
    return () => {
      dispatch(setSpecificNote({}))
      dispatch(setDraftTagsNote([]))
    }
    //eslint-disable-next-line
  }, [])
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setShowEditor(true), 1)
    return () => {
      clearTimeout(timeout)
    }
  })
  return {
    editorRef,
    addTagStep,
    inputRef,
    specificNote,
    isEdit,
    params,
    data,
    handleKeyDown,
    handleInputBlur,
    handlePressEnter,
    openEditMode,
    renderNoteModalTitle,
    onNoteChange,
    handleSubmit,
    setData,
    setAddTagStep,
    urlTitle,
    setUrlTitle,
    handleChangeUrl,
    url,
    handlePressEnterOnLink,
    popoverIsOpen,
    setPopoverIsOpen,
    file_is_pending,
    link_is_pending,
    togglePopover,
    handleSave,
    handleRemoveChip,
    handleDownloadFile,
    links,
    atts,
    created,
    setCreated,
    chips,
    createNoteBefore,
    onCloseEditor,
    showEditor,
    loadingQuill,
    showQuillChild,
    setShowQuillChild,
    handFocusQuill,
    handleTitleChange,
    titleRef,
  }
}
