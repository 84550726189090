import {
  SET_WORKSPACE_PENDING_STATUS,
  SET_WORKSPACE_LIST,
  SET_NEW_WORKSPACE,
  EDIT_WORKSPACE,
  DELETE_WORKSPACE,
} from "../../action-types/workspaces"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  workspaces: [],
  isPending: false,
}

export const workspacesReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        workspaces: [],
        isPending: false,
      }
    }
    case DELETE_WORKSPACE: {
      return {
        ...state,
        workspaces: state.workspaces.filter((w) => w.id !== action.payload),
      }
    }
    case EDIT_WORKSPACE: {
      return {
        ...state,
        workspaces: state.workspaces.map((w) =>
          w.id === action.payload.id ? action.payload : w
        ),
      }
    }
    case SET_NEW_WORKSPACE: {
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload],
      }
    }
    case SET_WORKSPACE_LIST: {
      return {
        ...state,
        workspaces: action.payload,
      }
    }
    case SET_WORKSPACE_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.status,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
