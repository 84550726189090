import { SET_SEARCH_PENDING_STATUS, SET_TAGS } from "../../action-types/search"
import { request } from "../../../api/axios_helper"
import { TAG_API_URL } from "../../../common/constants"
import { SET_TAGS_LIST } from "../../action-types/tags"
import { getAllTagsSelector } from "../../selectors/tags/get-tag-pending-status"
import { getSearchQuery } from "../../selectors/search/get-search-results"

export const setSearchPendingStatus = (payload) => ({
  type: SET_SEARCH_PENDING_STATUS,
  payload,
})
export const setTags = (payload) => {
  return {
    type: SET_TAGS,
    payload,
  }
}
export const getTags = (query) => async (dispatch, getState) => {
  try {
    const allTags = getAllTagsSelector(getState())
    const query = getSearchQuery(getState())
    if (query.trim() && Array.isArray(allTags)) {
      const _query = query.substring(query.lastIndexOf("#") + 1)
      dispatch(
        setTags(
          allTags
            .filter((item) =>
              item.tag?.toLowerCase()?.startsWith(_query.toLowerCase())
            )
            .map((item) => item.tag)
        )
      )
    }
    dispatch(setSearchPendingStatus(true))
    // const response = await request().get(`${TAGS_API_URL}?q=${query}`)
    // if(response?.status === 200) {
    //     dispatch(setTags(response.data))
    //     dispatch(setSearchPendingStatus(false))
    // }
  } catch (err) {
  } finally {
    dispatch(setSearchPendingStatus(false))
  }
}
export const setAllTags = (payload) => {
  return {
    type: SET_TAGS_LIST,
    payload,
  }
}
export const getAllTags = () => async (dispatch) => {
  request()
    .get(TAG_API_URL, dispatch)
    .then((res) => {
      dispatch(setAllTags(res.data))
    })
}
