import { useCallback, useMemo } from "react"
import { DATE_FORMAT } from "../common/config"
import moment from "moment"
import { useAction } from "./useAction"
import { deleteNote } from "../store/actions/notes/remove"
import { useDrag } from "react-dnd"
import { FeaturesTypes } from "../common/constants"
import { editNote } from "../store/actions/notes/edit_note"

const NO_CONTENT_TEXT = "Empty note, click to add"

export const useNoteItem = (note, handleEditNote, disableDrag) => {
  const editNoteAction = useAction(editNote)
  // eslint-disable-next-line no-empty-pattern
  const [{}, drag] = useDrag(() => ({
    canDrag: !disableDrag,
    type: FeaturesTypes.NOTE,
    item: { ...note },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        editNoteAction(
          {
            ...item,
            workspace_id: dropResult.id,
          },
          false,
          false,
          true
        )
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))
  const atts_count = useMemo(() => (note.atts ? note.atts.length : 0), [note])
  const links_count = useMemo(
    () => (note.links ? note.links.length : 0),
    [note]
  )
  const removeNoteAction = useAction(deleteNote)
  const created_date = useMemo(
    () => moment(note.comm_dt || note.create_dt).format(DATE_FORMAT),
    [note]
  )
  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      removeNoteAction(note.id)
    },
    [note.id, removeNoteAction]
  )
  const handleClickOnNote = useCallback(() => {
    handleEditNote(note)
  }, [handleEditNote, note])
  const content = useMemo(() => {
    const title = note.title && note.title.trim()
    const cutText = (t) => {
      if (t.length > 36) {
        return `${t.substring(0, 36)} ...`
      }
      return t
    }
    const result = title ? (
      cutText(title)
    ) : note.body_txt ? (
      cutText(note.body_txt)
    ) : (
      <span style={{ color: "rgb(210, 210, 210)" }}>{NO_CONTENT_TEXT}</span>
    )
    return result
  }, [note])
  return {
    drag,
    atts_count,
    links_count,
    created_date,
    handleDelete,
    handleClickOnNote,
    content,
  }
}
