import {
  EDIT_TAG_SUCCESS,
  REMOVE_TAG_FROM_CONTACT,
  REMOVE_TAG_FROM_LEAD,
  REMOVE_TAG_SUCCESS,
  SELECT_TAG,
  UPDATE_CURRENT_TAGS,
} from "../../action-types/tags"
import { request } from "../../../api/axios_helper"
import { TAG_API_URL } from "../../../common/constants"
import {
  getCurrentTags,
  getSelectedTag,
} from "../../selectors/tags/get-tags-selector"
import { setCurrentTags, setSelectedTag } from "./add-tag"

export const editTagSuccess = (tag, editedData, prevTag) => {
  return {
    type: EDIT_TAG_SUCCESS,
    payload: tag,
    editedData,
    prevTag,
  }
}

export const removeTagFromContact = (tag) => {
  return {
    type: REMOVE_TAG_FROM_CONTACT,
    payload: tag,
  }
}
export const removeTagFromLead = (tag) => {
  return {
    type: REMOVE_TAG_FROM_LEAD,
    payload: tag,
  }
}
export const selectTag = (tag) => {
  return {
    type: SELECT_TAG,
    payload: tag,
  }
}
export const updateCurrentTags = (payload, prevValue) => ({
  type: UPDATE_CURRENT_TAGS,
  payload,
  tagPrevName: prevValue,
})
export const editTag = (id, editedData, prevValue) => (dispatch, getState) => {
  const selectedTag = getSelectedTag(getState())
  request()
    .edit(`${TAG_API_URL}${id}/`, editedData, dispatch, "put")
    .then((res) => {
      selectedTag.id === res.data.id && dispatch(setSelectedTag(res.data))
      dispatch(updateCurrentTags(res.data, prevValue))
      dispatch(editTagSuccess(res.data, editedData, { tag: prevValue }))
    })
}
export const removeTagSuccess = (tag_id, removedTag) => {
  return {
    type: REMOVE_TAG_SUCCESS,
    payload: tag_id,
    removedTag,
  }
}
export const deleteTag = (data) => async (dispatch, getState) => {
  const newCurrentTags = getCurrentTags(getState()).filter(
    (item) => item.tag.trim() !== data.tag.trim()
  )
  await request().delete(`${TAG_API_URL}${data.id}/`, dispatch, () => {
    dispatch(removeTagSuccess(data.id, data))
    dispatch(setCurrentTags(newCurrentTags))
  })
}
