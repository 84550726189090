import { Popover } from "antd"

export default function TitlePopup({ title = "", maxLength = 35 }) {
    if (title.length > maxLength) {
        return (
            <Popover content={title} trigger="hover">
                <span>{title?.slice(0, maxLength - 1) + (title?.slice(0, maxLength - 1)[maxLength - 1] === " " ? "..." : " ...")}</span>
            </Popover>
        )
    }
    return (
        <span>{title}</span>
    )
}