import React from "react"
import { Badge, Tooltip } from "antd"
import withMemo from "../../store/HOC/with_memo"
import { getBadgeIcon, getBadgeStyle } from "../../common/helpers"

const DiaryBadgeItem = ({ count, title = "", activity_type }) => {
  return (
    <Tooltip title={title === "Todo_done" ? "Task Done" : title === "Todo_due" ? "Task Due" : title}>
      <div
        style={{
          display: "flex",
          marginRight: "11px",
          alignItems: "center",
        }}
      >
        {getBadgeIcon(title)}
        <Badge count={count} key={activity_type} style={getBadgeStyle(title)} />
      </div>
    </Tooltip>
  )
}

export default withMemo(DiaryBadgeItem)
