import { ADD_JOB, SET_DRAFT_TAGS_JOB } from "../../action-types/jobs"
import { getCommunicationsSelector, getDraftTodoList } from "../../selectors"
import _ from "lodash"
import { createTodoTH } from "../todos/create_todo"
import { createCommunicationTH } from "../communication/create-communication"
import { JOB_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import { URLS } from "../../../common/urls"
import { getTodos } from "../todos/get-todos"

const setNewJob = (payload) => {
  return {
    type: ADD_JOB,
    payload,
  }
}

export const setDraftTagsJob = (payload) => {
  return {
    type: SET_DRAFT_TAGS_JOB,
    payload,
  }
}
export const addLead = (data) => async (dispatch, getState) => {
  try {
    const { drafts } = getCommunicationsSelector(getState())
    const draftTodoList = getDraftTodoList(getState())

    const res = await request().create(JOB_API_URL, data, dispatch)
    if (res?.status === 201) {
      await dispatch(setNewJob(res.data))
      if (!_.isEmpty(drafts)) {
        for (let communication of drafts) {
          delete communication["id"]
          communication["job"] = res.data.id
          await dispatch(createCommunicationTH(communication, true))
        }
      }
      if (!_.isEmpty(draftTodoList)) {
        for (let todo of draftTodoList) {
          todo["job"] = res.data.id
          await dispatch(createTodoTH(todo, true))
        }
        if (window.location.pathname === URLS.HOME) {
          await dispatch(getTodos())
        }
      }
      return res.data
    }
  } catch (err) {}
}
