import { createSelector } from "reselect"

const getTags = (state) => state.search.tags
const TagsSelector = (state) => state.tags
const getSearch = (state) => state.search
const getTagsPendingStatus = createSelector(getSearch, (val) => {
  return val.pending
})
const getAllTagsSelector = createSelector(TagsSelector, (state) => {
  return state.items
})
export {
  getTagsPendingStatus,
  getSearch,
  getTags,
  TagsSelector,
  getAllTagsSelector,
}
