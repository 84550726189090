import { EDIT_CONTACT_SUCCESS } from "../../action-types/contacts"
import { request } from "../../../api/axios_helper"
import { CONTACT_API_URL, FeaturesTypes } from "../../../common/constants"
import { updateContainerTitle } from "../todos/update-todo"
import { editSearchPage } from "../search/get-search"
import { updateDiaryActivity } from "../diaries/edit-diary"

export const edit = (payload) => {
  return {
    type: EDIT_CONTACT_SUCCESS,
    payload,
  }
}

export const contactEdit = (data) => async (dispatch) => {
  try {
    const response = await request().edit(
      `${CONTACT_API_URL}${data.id}/`,
      data,
      dispatch,
      "patch"
    )
    if (!response) {
      return
    }
    dispatch(updateContainerTitle(response.data))
    dispatch(edit(response.data))
    dispatch(editSearchPage(response.data, FeaturesTypes.CONTACT))
    dispatch(
      updateDiaryActivity({
        data: response.data,
        activity_key: FeaturesTypes.CONTACT,
      })
    )
  } catch (err) {
    throw err
  }
}
