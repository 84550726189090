import React, { useCallback, useEffect, useState } from "react"
import { Button, Input, Typography, Col, Row } from "antd"
import { Link, useHistory, useLocation } from "react-router-dom"
import { passwordResetConfirm, setAuthErrors } from "../store/actions/user"
import { useDispatch, useSelector } from "react-redux"
import Logo from "../assets/images/logo.svg"
import { URLS } from "../common/urls"
import { PASSWORD_RESET_SUCCESS_CONTENT } from "../common/constants"
import { getAuthErrors } from "../store/selectors/user/get-login-status"
import { isEmpty } from "lodash"
import { config } from "../common/config"
import { Alert } from "antd"

const { Title, Text } = Typography

export const ResetPasswordConfirm = () => {
  const [new_password1, setNewPassword1] = useState("")
  const [new_password2, setNewPassword2] = useState("")
  const errors = useSelector(getAuthErrors)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [extraData, setExtraData] = useState({
    uid: "",
    token: "",
  })
  const handleChangePassword = useCallback((e) => {
    setNewPassword1(e.target.value)
  }, [])
  const handleChangeConfirmPassword = useCallback((e) => {
    setNewPassword2(e.target.value)
  }, [])
  const clearState = () => {
    setExtraData({
      uid: "",
      token: "",
    })
  }
  const handleSubmit = useCallback(() => {
    dispatch(
      passwordResetConfirm({
        ...extraData,
        new_password2,
        new_password1,
      })
    ).then((res) => {
      clearState()
      history.push({
        pathname: URLS.LOGIN,
        state: {
          message: PASSWORD_RESET_SUCCESS_CONTENT,
        },
      })
    })
  }, [dispatch, extraData, history, new_password1, new_password2])
  const renderError = useCallback(
    (key) => {
      if (errors && "token" in errors) {
        return
      }
      return errors[key] ? (
        <Text style={{ display: "block", marginTop: "4px", color: "#F25041" }}>
          {[...(errors[key] || [])].join()}
        </Text>
      ) : null
    },
    [errors]
  )
  const renderAdditionalErrors = useCallback(() => {
    const no_specific_error = Boolean(
      errors && !("new_password1" in errors) && !("new_password2" in errors)
    )
    if ("token" in errors) {
      return (
        <Alert
          message={config.expiry_token_message}
          style={{ position: "absolute", top: "-70px", width: "422px" }}
          type="error"
        />
      )
    }
    if (no_specific_error && !isEmpty(errors)) {
      return (
        <Alert
          message={config.generic_error_message}
          style={{ position: "absolute", top: "-70px", width: "422px" }}
          type="error"
        />
      )
    }
  }, [errors])
  useEffect(() => {
    const { extra_data } = { ...(location.state || {}) }
    if (extra_data) {
      setExtraData(extra_data)
    }
    return () => {
      clearState()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isEmpty(errors)) {
      setTimeout(() => dispatch(setAuthErrors([])), 3000)
    }
  }, [dispatch, errors])
  return (
    <>
      <Row>
        <Col
          style={{
            marginTop: "32px",
            marginBottom: "134px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div>
            <a href="https://savvyy.com/">
              <img src={Logo} alt="" />
            </a>
          </div>
        </Col>
        <Col
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row style={{ display: "block", width: "422px" }}>
            {renderAdditionalErrors()}
            <div
              style={{
                padding: "24px 32px",
                width: "422px",
                border: "1px solid #D9D9D9",
                borderRadius: "6px",
              }}
            >
              <Row>
                <div style={{ width: "100%" }}>
                  <Title
                    style={{ marginBottom: "32px", textAlign: "center" }}
                    level={3}
                  >
                    New password
                  </Title>
                  <div className="authForm">
                    <Text style={{ display: "block", marginBottom: "8px" }}>
                      Please write new password
                    </Text>
                    <Input
                      placeholder={"Password"}
                      type={"password"}
                      data-key={"new_password1"}
                      value={new_password1}
                      onChange={handleChangePassword}
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        height: "32px",
                        border: "1px solid #D9D9D9",
                        borderRadius: "2px",
                      }}
                    />
                    {renderError("new_password1")}
                    <Input
                      placeholder={"Confirm password"}
                      type={"password"}
                      data-key={"new_password2"}
                      value={new_password2}
                      onChange={handleChangeConfirmPassword}
                      style={{
                        width: "100%",
                        marginTop: "12px",
                        height: "32px",
                        border: "1px solid #D9D9D9",
                        borderRadius: "2px",
                      }}
                    />
                    {renderError("new_password2")}
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      style={{
                        display: "block",
                        width: "100%",
                        marginBottom: "20px",
                        color: "#ffffff",
                        backgroundColor: "#009EEF",
                        borderRadius: "2px",
                        borderColor: "#009EEF",
                        marginTop: "24px",
                      }}
                    >
                      Save
                    </Button>
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "#009EEF",
                        textDecoration: "underline",
                      }}
                      to={URLS.LOGIN}
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  )
}
