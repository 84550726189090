import { createSelector } from "reselect"
import { isEmpty, sortBy } from "lodash"
import { getFilterByTags } from "../filers/get_filters_selector"
import { sortByDate } from "../../../common/helpers"

export const NotesSelector = (state) => state.notes

export const getNotesSelector = createSelector(NotesSelector, (note) => {
  return sortByDate(note?.items || [], "create_dt")
})

export const getNotePendingStatus = createSelector(NotesSelector, (note) => {
  return note.isPending
})

export const getDraftTagsNote = createSelector(NotesSelector, (note) => {
  return note?.draftTags
})
export const getSpecificNote = createSelector(NotesSelector, (note) => {
  if (!note.specificNote) {
    return {}
  }
  return note.specificNote
})
export const getPendingStatus = createSelector(NotesSelector, (note) => {
  return note.isPending
})

export const selectUsedTagsInNotes = createSelector(
  getNotesSelector,
  (notes) => {
    if (!notes || isEmpty(notes)) {
      return []
    }
    const uniqTags = []
    notes
      .filter((item) => !!item.tags && !isEmpty(item.tags))
      .map((item) => item.tags)
      .flat()
      .forEach((item) => {
        if (uniqTags.every((tag) => tag.tag !== item.tag)) {
          uniqTags.push(item)
        }
      })

    return sortBy(uniqTags, ({ tag }) => tag.trim().toLowerCase(), "asc")
  }
)
function checkIfTagIsExist(tags, selectedTags) {
  for (const tag of selectedTags) {
    if (!tags.map((i) => i.tag).includes(tag)) {
      return false
    }
  }
  return true
}

export const selectFilteredNotes = createSelector(
  [getNotesSelector, getFilterByTags],
  (notes, selectedTags) => {
    if (!notes || isEmpty(notes)) {
      return []
    }
    if (!selectedTags || isEmpty(selectedTags)) {
      return notes
    }
    return sortByDate(
      notes
        .filter((note) => note.tags && !isEmpty(note.tags))
        .filter((note) => {
          return checkIfTagIsExist(note.tags, selectedTags)
        }),
      "create_dt"
    )
  }
)
