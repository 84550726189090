import { useState, useEffect } from 'react'
import { Card, Checkbox, Popover } from "antd"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useAction } from '../../hooks/useAction'
import { getDesktopLayout } from '../../store/selectors/user/getUserData'
import { setDesktopLayout, editProfile } from '../../store/actions/user'
import { difference, isEqual } from 'lodash'
import { ReactComponent as DragOutlined } from "../../assets/images/drag_icon.svg"



const Item = ({ text, index, onCheckClick, layout, sub_type }) => {
    return (
        <Draggable draggableId={text} index={index} isDragDisabled={!layout.includes(text)}>
            {provided => (
                <Card
                    className={`drag-card-option ${layout.includes(text) ? "checked" : ""}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <DragOutlined style={{ marginRight: "15px" }} />
                    {index === 2 && layout.length === 2 ? <Popover content="Max 2 tables supported." trigger="click" placement="topLeft">
                        <Checkbox checked={layout.includes(text)} value={text}>
                            <span style={{ fontSize: "16px", marginLeft: "5px" }}>
                                {text === 'leads' ? sub_type === "CRM" ? "Leads" : "Jobs" : text.toUpperCase().charAt(0) + text.slice(1, text.length)}
                            </span>
                        </Checkbox>
                    </Popover> : <Checkbox checked={layout.includes(text)} value={text} onChange={onCheckClick}>
                        <span style={{ fontSize: "16px", marginLeft: "5px" }}>
                            {text === 'leads' ? sub_type === "CRM" ? "Leads" : "Jobs" : text.toUpperCase().charAt(0) + text.slice(1, text.length)}
                        </span>
                    </Checkbox>}


                </Card>
            )}
        </Draggable>
    )
}

const Column = ({ col: { list, id }, onCheckClick, layout, sub_type }) => {
    return (
        <Droppable droppableId={id}>
            {provided => (
                <div style={{ padding: 10, }}>
                    <div  {...provided.droppableProps} ref={provided.innerRef}>
                        {list.map((text, index) => (
                            <Item key={text} text={text} index={index} onCheckClick={onCheckClick} layout={layout} sub_type={sub_type} />
                        ))}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    )
}

export default function DragHomeTable({ sub_type }) {
    const layout = useSelector(getDesktopLayout)
    const setDesktopLayoutAction = useAction(setDesktopLayout)
    const editUserDataAction = useAction(editProfile)
    const [myLayout] = useState(["leads", "contacts", "companies"])


    const initialColumns = {
        option: {
            id: 'option',
            list: layout.concat(difference(myLayout, layout))
        }
    }



    const onCheckClick = (e) => {
        if (e.target.checked) {
            if (layout.length === 2) return
            else {
                setDesktopLayoutAction([...layout, e.target.value])
                editUserDataAction({ profile: { desktop_layout: [...layout, e.target.value] } })
            }

        } else {
            if (layout.length === 1) return
            setDesktopLayoutAction(layout.filter(item => item !== e.target.value))
            editUserDataAction({ profile: { desktop_layout: layout.filter(item => item !== e.target.value) } })
        }
    }



    const onDragEnd = ({ source, destination }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null

        // Make sure we're actually moving the item
        if (
            source.droppableId === destination.droppableId &&
            destination.index === source.index
        )
            return null

        const newLayout = Array.from(layout);

        if (!newLayout[source.index]) {
            return null
        }


        // Swap the items
        const [movedItem] = newLayout.splice(source.index, 1);
        newLayout.splice(destination.index, 0, movedItem);

        if (isEqual(newLayout, layout)) return null
        setDesktopLayoutAction(newLayout)
        editUserDataAction({ profile: { desktop_layout: newLayout } })
        return null
    }
    useEffect(() => {
        if (!layout.length) {
            setDesktopLayoutAction(["leads"])
            editUserDataAction({ profile: { desktop_layout: ["leads"] } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {Object.values(initialColumns).map(col => (
                <Column col={col} key={col.id} onCheckClick={onCheckClick} layout={layout} sub_type={sub_type} />
            ))}


        </DragDropContext>
    )
}
