import React, { useEffect, useRef, useState } from "react"
import { Button, Input, Popconfirm, Tooltip } from "antd"
import { useDispatch } from "react-redux"
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons"
import { deleteTag, editTag } from "../store/actions/tags/edit_tag"
import { isEmpty } from "lodash"
import { useColors } from "../hooks/useColors"

export const SelectTagColor = ({
  onClose,
  changeTagsQuery,
  setTagsStep,
  position,
  selectedTag,
}) => {
  const dispatch = useDispatch()
  const { allColors } = useColors()
  const [data, setData] = useState({
    tag: selectedTag.tag || "",
    color: "",
    ...selectedTag,
  })
  const inputRef = useRef()
  const handleEditTag = (item) => {
    changeTagsQuery("")
    setTagsStep(1)
    dispatch(
      editTag(selectedTag.id, { tag: data.tag, color: selectedTag.color }, item)
    )
  }

  const confirm = () => {
    dispatch(deleteTag(selectedTag))
    onClose()
  }
  const onSelectColor = (item) => {
    dispatch(
      editTag(
        selectedTag.id,
        { code: item.code, color: item.name?.toLowerCase(), tag: data.tag },
        selectedTag.tag
      )
    )
  }
  const isSelected = (color) => {
    if (isEmpty(selectedTag)) {
      return false
    }
    return selectedTag.color.toLowerCase() === color.toLowerCase()
  }

  useEffect(() => {
    if (selectedTag.id !== data.id) {
      setData(selectedTag)
    }
    //eslint-disable-next-line
  }, [selectedTag])

  useEffect(() => { inputRef.current?.focus() }, [inputRef])

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation()
          setTagsStep(1)
        }
      }}
      className="select_color"
      style={position}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <div style={{ display: "flex" }}>
        <Tooltip title="Press Enter to save">
          <Input
            tabIndex="-1"
            style={{ height: "24px", marginBottom: "8px", marginRight: "8px" }}
            ref={inputRef}
            onPressEnter={() => handleEditTag(selectedTag.tag)}
            defaultValue={selectedTag.tag}
            value={data.tag}
            onChange={(e) => {
              setData({ ...data, tag: e.target.value })
            }}
          />
        </Tooltip>
        <Popconfirm
          overlayClassName="delete-popconfirm-tag"
          title="Deleting will remove this tag everywhere. There is no undo."
          onConfirm={confirm}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button className="delete-button">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
      <div style={{ width: "100%" }}>
        <ul>
          {Object.values(allColors).map((color) => {
            return (
              <li
                className="contact-modal tag-color"
                onClick={(e) => {
                  inputRef.current?.focus()
                  setData({ ...data, color: color.code, code: color.code })
                  onSelectColor(color)
                }}
                key={color.code}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip placement="bottom" title={color.name}>
                  <span
                    style={{
                      height: "28px",
                      width: "28px",
                      padding: "3px",
                      backgroundColor: color.code,
                      position: "relative",
                    }}
                  >
                    {isSelected(color.name) && <CheckOutlined style={{ position: "absolute", top: "6px", right: "12px" }} />}
                  </span>
                </Tooltip>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
