import React from "react"
import { isEqual } from "lodash"

const withShouldUpdate = (Component, restProps) => {
  return class wrappedComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
      return !isEqual(nextProps, this.props)
    }
    render() {
      return <Component {...this.props} />
    }
  }
}
export default withShouldUpdate
