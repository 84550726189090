import { createSelector } from "reselect"
import {
  getSearch,
  getTags,
  getAllTagsSelector,
} from "../tags/get-tag-pending-status"
const getMentions = (state) => state.search.mentions
export const getSearchMentions = createSelector(
  [getTags, getAllTagsSelector, getMentions],
  (tags, allTags, mentions) => {
    const allTagsArray = allTags.map((item) => item.tag)
    return {
      "#": tags.length > 0 ? tags.sort() : allTagsArray.sort(),
      "@": mentions,
    }
  }
)

export const getSearchResults = createSelector(getSearch, (search) => {
  return search.results
})
export const getSearchPendingStatus = createSelector(getSearch, (search) => {
  return search.isPending
})

export const getSearchQuery = createSelector(getSearch, (state) => {
  if (typeof state.query !== "string") {
    return ""
  }
  return state.query
})
