import { createSelector } from "reselect"
import { sortByDate } from "../../../common/helpers"

export const CompanySelector = (state) => state.company
export const getCompanyResults = createSelector(
  CompanySelector,
  ({ results }) => {
    return results
  }
)

export const getCompaniesPagination = createSelector(CompanySelector, (val) => {
  return val?.pagination
})

export const getCompaniesPendingStatus = createSelector(
  CompanySelector,
  (val) => {
    return val?.pending
  }
)
export const getCompaniesSelector = createSelector(CompanySelector, (value) => {
  if (!value.companies || !Array.isArray(value.companies)) {
    return []
  }
  const companies = value.companies.filter((item) => item?.id)
  return [...companies].sort((a, b) => {
    const _a = a?.name?.toLowerCase()?.charAt(0)
    const _b = b?.name?.toLowerCase()?.charAt(0)
    return _a < _b ? -1 : _a > _b ? 1 : 0
  })
})

export const getSpecificCompany = createSelector(CompanySelector, (val) => {
  return val.specificCompany
})

export const getDraftTagsCompany = createSelector(CompanySelector, (val) => {
  return val.draftTags
})

export const getTotalCompany = createSelector(CompanySelector, (state) => {
  return state.total_count
})

export const CompanyNotesSelector = createSelector(CompanySelector, (state) => {
  return sortByDate(state.company_notes)
})

export const CompaniesJobsPendingStatusSelector = createSelector(
  CompanySelector,
  (state) => state.jobs_pending
)
export const CompaniesJobsSelector = createSelector(
  CompanySelector,
  (state) => state.jobs
)
