import {
  CREATE_NOTE,
  SET_NEW_NOTE_IN_COMPANY,
  SET_SPECIFIC_NOTE,
  SET_DRAFT_TAGS_NOTE,
} from "../../action-types/notes"
import { request } from "../../../api/axios_helper"
import { NOTE_API_URL } from "../../../common/constants"

export const setNewNote = (payload) => {
  return {
    type: CREATE_NOTE,
    payload,
  }
}
export const setNewNoteInCompany = (payload) => ({
  type: SET_NEW_NOTE_IN_COMPANY,
  payload,
})
export const setDraftTagsNote = (payload) => ({
  type: SET_DRAFT_TAGS_NOTE,
  payload,
})
export const createNote = (data, can_dispatch) => async (dispatch) => {
  return new Promise((resolve) => {
    request()
      .create(NOTE_API_URL, data, dispatch)
      .then((response) => {
        if (can_dispatch) {
          dispatch(setNewNoteInCompany(response.data))
        }
        dispatch(setNewNote(response.data))
        resolve(response.data)
      })
  })
}
export const setSpecificNote = (payload) => {
  return {
    type: SET_SPECIFIC_NOTE,
    payload,
  }
}
