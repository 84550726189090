import { combineReducers } from "redux"
import { userReducer as user } from "./user"
import { jobsReducer as jobs } from "./jobs"
import { todosReducer as todos } from "./todos"
import { diariesReducer as diaries } from "./diaries"
import { filtersReducer as filters } from "./filters"
import { companyReducer as company } from "./companies"
import { communicationReducer as communications } from "./communications"
import { contactsReducer as contacts } from "./contacts"
import { searchReducer as search } from "./search"
import { colorsReducer as colors } from "./colors"
import { notesReducer as notes } from "./notes"
import { filesList as files } from "./file_manager"
import { errorsReducer as errors } from "./error"
import { linksReducer as links } from "./links"
import { tagsReducer as tags } from "./tags"
import { reducer as formReducer } from "redux-form"
import { uiReducer as ui } from "./ui"
import { workspacesReducer } from "./workspace"

export default combineReducers({
  workspace: workspacesReducer,
  tags,
  links,
  errors,
  files,
  search,
  notes,
  user,
  jobs,
  todos,
  diaries,
  filters,
  company,
  communications,
  contacts,
  colors,
  form: formReducer,
  ui,
})
