import { request } from "./axios_helper"
import {
  STRIPE_API_URL,
  subscriptionProcessors,
  SUBSCRIPTION_API_URL,
} from "../common/constants"

export const createStripeCustomer = () => {
  return request().create(`${STRIPE_API_URL}create-customer`)
}

export const createStripeSubscription = (opt) => {
  return request().create(`${STRIPE_API_URL}create-subscription`, opt)
}

export const createStripeIntent = () => {
  return request().create(`${STRIPE_API_URL}create-setup-intent`)
}

export const updateStripeSubscription = (opt) => {
  return request().create(`${STRIPE_API_URL}update-subscription`, opt)
}

export const cancelStripeSubscription = (opt) => {
  return request().create(`${STRIPE_API_URL}cancel-subscription`, opt)
}

export const getStripeStatus = (processor) => {
  if (processor === subscriptionProcessors.APPLE) {
    return request().get(`${SUBSCRIPTION_API_URL}status`)
  }
  return request().get(`${STRIPE_API_URL}status`)
}

export const getPaymentInfo = () => {
  return request().get(`${STRIPE_API_URL}get-payment-info`)
}
