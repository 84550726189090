import React, { useState } from "react"
import { Radio, Form } from "antd"
import { Typography } from "antd"

const { Title } = Typography

export const ProjectMode = () => {
  const [type, setType] = useState(1)

  const onSelectType = (e) => {
    setType(e.target.value)
  }
  return (
    <>
      <Title level={2}>Savvyy Mode</Title>

      <Form.Item label="Mode">
        <Radio.Group onChange={onSelectType} value={type}>
          <Radio value={1}>Job Search</Radio>
          <Radio value={2}>CRM</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}
