import NewPreloader from "../assets/images/new_preloader.svg"

export const WaitPage = () => {
  return (
    <>
      <div
        style={{
          height: "100%",
          textAlign: "center",
        }}
      >
        <img style={{ maxHeight: "40%" }} src={NewPreloader} alt="" />
      </div>
    </>
  )
}
