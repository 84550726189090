import { createSelector } from "reselect"
import { uniqBy } from "lodash"

const getJob = (state) => state.jobs

export const getSpecificJob = createSelector(getJob, (jobs) => {
  return jobs.specificLead
})

export const getSpecificJobOwnTodo = createSelector(getJob, (jobs) => {
  return jobs?.specificLead?.todos?.length > 0 ? jobs.specificLead.todos[0] : {}
})

export const getJobTodosSelector = createSelector(
  [getJob, getSpecificJob],
  (state, job) => {
    if (!state.todos || !Array.isArray(state.todos)) {
      return []
    }
    return uniqBy(
      state.todos.filter((item) => item.job === job.id),
      "id"
    )
  }
)

export const getJobFilters = createSelector(getJob, (job_state) => {
  return job_state.filterBy
})
