import {
  ADD_COMPANY,
  EDIT_NOTE_IN_COMPANY,
  FETCH_COMPANIES,
  HANDLE_COMPANY_SEARCH,
  REMOVE_COMPANY,
  REMOVE_CONTACT_FROM_COMPANY,
  SAVE_EDITED_COMPANY,
  SET_COMPANY_CONTACTS,
  SET_COMPANY_JOB_PENDING_STATUS,
  SET_COMPANY_JOBS,
  SET_COMPANY_NOTES,
  SET_COMPANY_PENDING_STATUS,
  SET_NEW_COMPANY_CONTACT,
  SET_SPECIFIC_COMPANY,
  SET_DRAFT_TAGS_COMPANY,
  GET_COMPANY,
  RESET_COMPANIES,
} from "../../action-types/companies"
import {
  EDIT_SPECIFIC_NOTE_WITH_LINKS,
  REMOVE_LINK_FROM_SPECIFIC_NOTE,
} from "../../action-types/notes"
import { EDIT_TAG_SUCCESS, REMOVE_TAG_SUCCESS } from "../../action-types/tags"

import { isEmpty } from "lodash"
import {
  FILTER_COMPANY_NOTES,
  REMOVE_NOTE,
  SET_NEW_NOTE_IN_COMPANY,
} from "../../action-types/notes"
import {
  DELETE_CONTACT,
  EDIT_CONTACT_SUCCESS,
} from "../../action-types/contacts"
import {
  getArrayUniqueValue,
  removeTagFromTables,
} from "../../../common/helpers"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  companies: [],
  results: [],
  query: null,
  pending: false,
  specificCompany: {},
  company_notes: [],
  contacts: [],
  jobs: [],
  draftTags: [],
  jobs_pending: false,
  total_count: 0,
}

export const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case REMOVE_CONTACT_FROM_COMPANY: {
      return {
        ...state,
        companies: state.companies.map((item) =>
          item.id === action.payload
            ? { ...item, contact_count: item.contact_count - 1 }
            : item
        ),
      }
    }
    case SET_COMPANY_JOBS: {
      return {
        ...state,
        jobs: action.payload,
      }
    }
    case SET_COMPANY_JOB_PENDING_STATUS: {
      return {
        ...state,
        jobs_pending: action.payload,
      }
    }
    case DELETE_CONTACT: {
      return {
        ...state,
        contacts: state.contacts.filter((item) => {
          return item.id !== action.payload
        }),
      }
    }
    case EDIT_CONTACT_SUCCESS: {
      return {
        ...state,
        contacts: state.contacts.map((item) => {
          return item.id === action.payload.id ? action.payload : item
        }),
      }
    }
    case SET_NEW_COMPANY_CONTACT: {
      return {
        ...state,
        contacts: [action.payload].concat(state.contacts),
      }
    }
    case SET_COMPANY_CONTACTS: {
      return {
        ...state,
        contacts: action.payload,
      }
    }
    case FILTER_COMPANY_NOTES: {
      return {
        ...state,
        company_notes: state.company_notes.filter(
          (item) => item.id !== action.payload.id
        ),
      }
    }
    case EDIT_NOTE_IN_COMPANY: {
      const exist = state.company_notes.some(
        (item) => item.id === action.payload.id
      )
      return {
        ...state,
        company_notes: exist
          ? state.company_notes.map((item) => {
              if (item.id === action.payload.id) {
                return action.payload
              }
              return item
            })
          : [action.payload].concat(state.company_notes),
      }
    }
    case REMOVE_NOTE: {
      return {
        ...state,
        company_notes: state.company_notes.filter(
          (item) => item.id !== action.payload
        ),
      }
    }
    case EDIT_SPECIFIC_NOTE_WITH_LINKS: {
      const company_notes = state.company_notes.map((item) => {
        if (item.id === action?.payload?.note_id) {
          const newLinks = getArrayUniqueValue(item?.links || [], [
            action.payload,
          ])
          return {
            ...item,
            links: newLinks,
          }
        } else if (item.id === action?.payload?.note) {
          const newAtts = getArrayUniqueValue(item?.atts || [], [
            action.payload,
          ])
          return {
            ...item,
            atts: newAtts,
          }
        }
        return item
      })
      return {
        ...state,
        company_notes: company_notes,
      }
    }
    case REMOVE_LINK_FROM_SPECIFIC_NOTE: {
      const company_notes = state.company_notes.map((item) => {
        if (item.id === action?.payload?.note_id) {
          return {
            ...item,
            links: item?.links?.filter((l) => l.id !== action.payload.id),
          }
        } else if (item.id === action?.payload?.note) {
          return {
            ...item,
            atts: item?.atts?.filter((l) => l.id !== action.payload.id),
          }
        }
        return item
      })
      return {
        ...state,
        company_notes: company_notes,
      }
    }
    case SET_COMPANY_NOTES: {
      return {
        ...state,
        company_notes: action.payload,
      }
    }
    case SET_NEW_NOTE_IN_COMPANY: {
      return {
        ...state,
        company_notes: [action.payload].concat(state.company_notes),
      }
    }
    case REMOVE_TAG_SUCCESS: {
      let newState = {
        ...state,
        draftTags: state.draftTags?.filter(
          (item) => item?.tag !== action.removedTag.tag
        ),
        companies: state.companies.map((company) => {
          return {
            ...company,
            tags: removeTagFromTables(company.tags, action.removedTag.tag),
          }
        }),
        contacts: state.contacts?.map((contact) => {
          return {
            ...contact,
            tags: removeTagFromTables(contact.tags, action.removedTag.tag),
          }
        }),
      }

      if (!isEmpty(state.specificCompany)) {
        newState.specificCompany = {
          ...state.specificCompany,
          tags: state.specificCompany?.tags?.filter((item) => {
            return item.tag !== action.removedTag.tag
          }),
        }
      }
      return {
        ...newState,
      }
    }
    case EDIT_TAG_SUCCESS: {
      const getDTagSuccess = (array = []) =>
        array.map((item) => {
          return {
            ...item,
            tags: item?.tags?.map((item) => {
              return item.tag?.trim() === action.prevTag.tag?.trim()
                ? action.payload
                : item
            }),
          }
        })
      if (isEmpty(state.specificCompany)) {
        return {
          ...state,
          draftTags: state.draftTags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        }
      }
      return {
        ...state,
        draftTags: state.draftTags?.map((item) => {
          return item.tag?.trim() === action.prevTag.tag?.trim()
            ? action.payload
            : item
        }),
        specificCompany: {
          ...state.specificCompany,
          tags: state.specificCompany?.tags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        },
        contacts: state.contacts?.map((item) => {
          return {
            ...item,
            tags: item?.tags?.map((item) => {
              return item.tag?.trim() === action.prevTag.tag?.trim()
                ? action.payload
                : item
            }),
          }
        }),
        companies: getDTagSuccess(state.companies),
      }
    }
    case RESET_APPLICATION_DATA: {
      return {
        companies: [],
        results: [],
        query: null,
        pending: false,
        specificCompany: {},
        company_notes: [],
        contacts: [],
        jobs: [],
        jobs_pending: false,
        draftTags: [],
      }
    }
    case FETCH_COMPANIES: {
      const companies = getArrayUniqueValue(
        state.companies,
        action.payload.data
      )
      return {
        ...state,
        companies,
        pagination: { next: action.payload.next },
        total_count: action.payload.total,
      }
    }
    case RESET_COMPANIES: {
      return {
        ...state,
        companies: [],
        pagination: {},
      }
    }
    case ADD_COMPANY: {
      const companies = getArrayUniqueValue(state.companies, [action.payload])
      return {
        ...state,
        companies,
        total_count: state.total_count + 1,
      }
    }
    case GET_COMPANY: {
      const companies = getArrayUniqueValue(state.companies, [action.payload])
      return {
        ...state,
        companies,
      }
    }
    case HANDLE_COMPANY_SEARCH: {
      const companies = getArrayUniqueValue(state.companies, action.payload)
      return {
        ...state,
        companies,
        results: action.payload,
      }
    }
    case REMOVE_COMPANY: {
      return {
        ...state,
        companies: state.companies.filter(
          (company) => company.id !== action.payload
        ),
        total_count: state.total_count - 1,
      }
    }
    case SET_SPECIFIC_COMPANY: {
      return {
        ...state,
        specificCompany: action.payload,
      }
    }
    case SET_COMPANY_PENDING_STATUS: {
      return {
        ...state,
        pending: action.payload,
      }
    }
    case SET_DRAFT_TAGS_COMPANY: {
      return {
        ...state,
        draftTags: action.payload,
      }
    }
    case SAVE_EDITED_COMPANY: {
      const companies = getArrayUniqueValue(state.companies, [action.payload])

      let data = {
        ...state,
        companies,
      }
      if (!isEmpty(state.specificCompany)) {
        data.specificCompany = action.payload
      }
      return data
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
