import React from "react"
import "../../styles/loader.css"

export const Loader = ({ showMask }) => {
  return (
    <div className="loader_container">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      {showMask && <div className="loader_mask" />}
    </div>
  )
}
