import React from "react"
import TagItem from "./TagItem.jsx"

export const TagsList = ({ allTags = [], onTagSelect, onSelectTag }) => {
  return (
    <ul className="tags_list">
      {allTags.map((item) => {
        return (
          <li
            key={item.id}
            className="d-flex"
            style={{ justifyContent: "space-between" }}
            onClick={() => {
              onSelectTag(item)
            }}
          >
            <TagItem item={item} onMenuOpen={onTagSelect} />
          </li>
        )
      })}
    </ul>
  )
}
