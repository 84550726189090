import { DISCARD_JOB } from "../../action-types/jobs"
import { setErrorMessage, setErrorState } from "../errors/set_errors"
const saveLastDiscardedJobStatus = (data) => {
  return {
    type: DISCARD_JOB,
    payload: data,
  }
}
export const discardJob = (status_id) => async (dispatch) => {
  try {
    dispatch(saveLastDiscardedJobStatus(status_id))
  } catch (err) {
    dispatch(setErrorMessage(err.message))
    dispatch(setErrorState(true))
  }
}
