import { useEffect, useState, useCallback } from "react";
import { fetchContactByIdIfNeeded } from "../../store/actions/contacts/get-contacts-list";
import { fetchJobByIdIfNeeded } from "../../store/actions/jobs/get-jobs";
import { useAction } from "../../hooks/useAction";
import { Spin } from "antd";

export default function TodoIconPop({ todo }) {
    const getContactAction = useAction(fetchContactByIdIfNeeded);
    const getJobAction = useAction(fetchJobByIdIfNeeded);

    const [job, setJob] = useState(null);
    const [contact, setContact] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const getEntity = useCallback(async () => {
        setIsLoading(true)
        try {
            if (todo.job) {
                const j = await getJobAction(todo.job)
                setJob(j)
            } else if (todo.contact) {
                const c = await getContactAction(todo.contact);
                setContact(c)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }

    }, [todo.job, todo.contact, getJobAction, getContactAction])



    useEffect(() => {
        getEntity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div>
        {isLoading && <Spin />}
        {job && <div>{job?.company?.name && job?.company?.name + ", "}{job?.title}</div>}
        {contact && <div> {contact?.company?.name && contact?.company?.name + ", "}{contact?.first_name}</div>}
    </div>

}