import { DELETE_CONTACT } from "../../action-types/contacts"
import { request } from "../../../api/axios_helper"
import { CONTACT_API_URL, FeaturesTypes } from "../../../common/constants"
import { setContactsPendingStatus } from "./get-contacts-list"
import { removeSearchResult } from "../search/get-search"
import { REMOVE_CONTACT_FROM_COMPANY } from "../../action-types/companies"
import { removeDiaryActivity } from "../diaries/delete-diary"

const deleteSuccess = (id) => {
  return {
    type: DELETE_CONTACT,
    payload: id,
  }
}

export const removeContactFromCompany = (payload) => ({
  type: REMOVE_CONTACT_FROM_COMPANY,
  payload,
})
export const deleteContactTH = (id, contact) => async (dispatch) => {
  try {
    dispatch(setContactsPendingStatus(true))
    await request().delete(`${CONTACT_API_URL}${id}/`, dispatch, () => {
      dispatch(removeSearchResult(id, "contact"))
      dispatch(deleteSuccess(id))
      dispatch(
        removeDiaryActivity({ id, activity_type: FeaturesTypes.CONTACT })
      )
      if (contact?.id && contact?.company?.id) {
        dispatch(removeContactFromCompany(contact.company.id))
      }
    })
  } catch (err) {
  } finally {
    dispatch(setContactsPendingStatus(false))
  }
}
