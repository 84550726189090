export const SET_JOBS_DATA = "SET_JOBS_DATA"
export const APPLY_JOB = "APPLY_JOB"
export const UPDATE_JOB_DATA_SUCCESS = "UPDATE_JOB_DATA"
export const CLEAR_SAVE_STATUS = "CLEAR_SAVE_STATUS"
export const DISCARD_JOB = "DISCARD_JOB"
export const REVERT_JOB = "REVERT_JOB"
export const DELETE_JOB = "DELETE_JOB"
export const ADD_JOB = "ADD_JOB"
export const REMOVE_TODO_FROM_LEAD = "REMOVE_TODO_FROM_LEAD"
export const SET_SPECIFIC_LEAD = "SET_SPECIFIC_LEAD"
export const UPDATE_SPECIFIC_JOB = "UPDATE_SPECIFIC_JOB"
export const CREATE_EDITABLE_ROW = "CREATE_EDITABLE_ROW"
export const DISABLE_EDITABLE_ROW = "DISABLE_EDITABLE_ROW"
export const SET_JOB_TODOS = "SET_JOB_TODOS"
export const SET_NEW_JOB_TODO = "SET_NEW_JOB_TODO"
export const UPDATE_JOB_FILTERS = "UPDATE_JOB_FILTERS"
export const SET_JOB_PENDING_STATUS = "SET_JOB_PENDING_STATUS"
export const SET_JOB_FETCHING = "SET_JOB_FETCHING"
export const SET_JOB_TO_LIST = "SET_JOB_TO_LIST"
export const CLEAR_JOB_FETCHING = "CLEAR_JOB_FETCHING"
export const SET_DRAFT_TAGS_JOB = "SET_DRAFT_TAGS_JOB"
