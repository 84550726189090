import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import { useCallback } from "react"
import { useAction } from "./useAction"
import { getWorkspaceNotes } from "../store/actions/workspaces/getWorkspaces"
import { dragWorkspace } from "../store/actions/workspaces/edit-workspace"

export const useWorkspaceTabs = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const params = useParams()
  const location = useLocation()
  const dragWorkspaces = useAction(dragWorkspace)
  const workspaceNotesAction = useAction(getWorkspaceNotes)
  const handleChangeTab = useCallback(
    (e) => {
      e.stopPropagation()
      const key = e.currentTarget.dataset.workspace_id
      if (!key) {
        return
      }
      const { path } = match
      const url = `${path.substring(0, path.lastIndexOf("/"))}/${key}`
      if (location.pathname === url) {
        return
      }
      history.push(url)
      workspaceNotesAction(key)
    },
    [history, location.pathname, match, workspaceNotesAction]
  )
  const getClassName = useCallback(
    (tabId) => {
      return Number(params?.workspace_id) === Number(tabId)
        ? "ant-tabs-tab-active"
        : ""
    },
    [params]
  )
  return {
    params,
    handleChangeTab,
    getClassName,
    dragWorkspaces,
  }
}
