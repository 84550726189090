import {
  CLOSE_ANTD_MODALS,
  RESET_MODALS,
  SET_ANTD_MODALS,
  UPDATE_UI,
  UPDATE_MODAL_PROPS,
  IS_LOADING_UI,
} from "../../action-types/ui"

export const updateUI = (payload) => {
  return {
    type: UPDATE_UI,
    payload,
  }
}

export const updateModalProps = (payload) => {
  return {
    type: UPDATE_MODAL_PROPS,
    payload,
  }
}

export const resetModals = () => {
  return {
    type: RESET_MODALS,
  }
}

export const updateAntdModals = (payload) => ({
  type: SET_ANTD_MODALS,
  payload,
})

export const closeAntdModals = (payload) => ({
  type: CLOSE_ANTD_MODALS,
  payload,
})

export const isLoadingUI = (payload) => ({
  type: IS_LOADING_UI,
  payload,
})
