import { Typography, Flex, Breadcrumb } from "antd"

const { Title, Text } = Typography

const SavvyyGlossary = ({ setBreadCrumb, breadCrumbs }) => {
    return (
        <>
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    maxWidth: "750px",
                    marginBottom: "82px"
                }}>

                <Breadcrumb
                    separator=">"
                    style={{
                        marginTop: "30px",
                        marginBottom: "30px"
                    }}
                    items={[
                        {
                            title: <Text className="bread-crumb" onClick={() => setBreadCrumb(breadCrumbs[0])}>Help</Text>,
                        },
                        {
                            title: <span style={{ color: "#009EEF" }} href="">Glossary</span>,
                        }
                    ]}
                />

                <Title
                    style={{
                        marginBottom: "40px"
                    }}
                >
                    Glossary
                </Title>

                <Title
                    level={3}
                    style={{
                        marginBottom: "20px"
                    }}
                >
                    {/* Savvyy: Empowering Your Business Management */}
                </Title>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Company</b> - businesses and organizations that you want to track, plus a tool to create regular updates, e.g. quarterly reports, M&A activity, leadership changes, anything and everything.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Company Updates</b> - notes associated with a particular company. As with all notes on Savvyy, these can be tagged, color coded and include both links and attachments. Very handy.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Contact</b> - information about a particular contact - contact details, employment and title, notes - all in one place. Plus tasks and conversations, associated with a particular contact. Also, you can create automated follow up reminders and the system will remind you when it’s time to reach out. The follow ups can be scheduled at 3, 6, 9 and 12 month cadence.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Conversations</b> - a summary of your interactions with a particular contact which can either be self-typed notes or forwarded correspondence between you and a particular contact. These can be emailed to [yourself]@savvyy.com and the system will correctly identify and save them with the right contact. See Emailing Savvyy for more details.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Daily Emails</b> - Savvyy will send you daily emails summarizing your activities for the day, listing your upcoming tasks and a reminder to jot down a diary for the day. These are configurable and can be turned off.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Dashboard</b> - the page that shows you all your activity, your tasks and leads that you are working on.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Diary</b> - ability to maintain a daily diary of your activities and happenings. These can either be created on the website, or by responding to the daily emails that Savvyy sends as a reminder to recap what happened that day.
                    </Text>

                </Flex>


                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Emailing yourself</b> @savvyy.com - we often email ourselves things we don’t want to forget or lose. These could be links, PDF files, thoughts, reminders, etc. This is what Emailing Savvyy does - you send all sorts of information to [yourself]@savvyy.com and the system will save it for you, so you can pick up where left off. You can find your @savvyy.com handle on the My Profile Settings page. Send us a note to help@savvyy.com if you have any questions or can’t find this. We’d be thrilled to help you.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Jobs</b> - employment leads that you would like to track, which move through the New→Applied→Discarded pipeline and help you stay on top of the application process. You can also create one-click follow up reminders so you can stand out from the crowd.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Library</b> (of Notes and Workspaces) - general notes about anything - news articles, research reports, company updates, essays, design ideas. Notes can have links and files attached to them. They can be placed in Workspaces. And they can be tagged and color coded for easy organization and searching.
                    </Text>

                </Flex>


                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Timeline</b> - a visual on the Dashboard showing a quick summary of your activities on a particular day, e.g. added three new contacts, had a conversation with someone, new company update.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        <b>Tasks</b> - basic todo manager with due dates of “Today”, a specific date in the future or just “Later”. We find these buckets most helpful.
                    </Text>

                </Flex>

            </div>
        </>
    )
}

export default SavvyyGlossary