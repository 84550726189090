import { createSelector } from "reselect"
import { filter, isEmpty, sortBy } from "lodash"
import {
  JOB_APPLIED,
  JOB_DISCARDED,
  JOB_NOT_APPLIED,
} from "../../../common/constants"
import { CompanySelector } from "../companies/get-companies-selector"
import { sortByDate } from "../../../common/helpers"

const getJobs = (state) => state.jobs
export const getJobsFilterBySelector = (state) => state.jobs.filterBy
export const getJobsSelector = createSelector(getJobs, (value) => value.jobs)
export const getNewJobs = createSelector(getJobs, (state) => {
  const filteredJobs = state.jobs.filter(
    (item) => item.status_id === JOB_NOT_APPLIED
  )
  return sortByDate(filteredJobs, "create_dt")
})

export const getDraftTagsJobs = createSelector(getJobs, (state) => {
  return state?.draftTags
})
export const getAppliedJobs = createSelector(getJobs, (state) => {
  const filteredJobs = state.jobs.filter(
    (item) => item.status_id === JOB_APPLIED
  )
  return sortByDate(filteredJobs, "apply_dt")
})

export const getHomeJobs = createSelector(getJobs, (state) => {
  const newJobsFilter = state.jobs.filter(
    (item) => item.status_id === JOB_NOT_APPLIED
  )
  const appliedJobsFilter = state.jobs.filter(
    (item) => item.status_id === JOB_APPLIED
  )
  const newJobs = sortBy(sortByDate(newJobsFilter, "create_dt"), [
    "seasoning",
  ]).reverse()
  const appliedJobs = sortBy(sortByDate(appliedJobsFilter, "apply_dt"), [
    "seasoning",
  ]).reverse()
  return [...newJobs, ...appliedJobs]
})

export const getSortedJobs = createSelector(
  [getJobs, CompanySelector],
  (job_state, company_state) => {
    if (!job_state.jobs || !Array.isArray(job_state.jobs)) {
      return []
    }
    const isCompanyModal = !isEmpty(company_state.specificCompany)

    const jobs = isCompanyModal
      ? job_state.jobs.filter(
          (item) => item?.company?.id === company_state?.specificCompany?.id
        )
      : job_state.jobs

    return filter(
      sortBy(jobs, [
        "status_id",
        "seasoning",
        (val) => {
          return -new Date(val.create_dt).getTime()
        },
      ]),
      (item) => {
        return !isCompanyModal ? item.status_id === job_state.filterBy : true
      }
    ).reverse()
  }
)

export const getJobsCountByStatus = createSelector(getJobs, (value) => {
  let _new = 0
  let discarded = 0
  let applied = 0
  if (value.jobs) {
    for (const job of value.jobs) {
      switch (job.status_id) {
        case JOB_NOT_APPLIED: {
          _new += 1
          break
        }
        case JOB_APPLIED: {
          applied += 1
          break
        }
        case JOB_DISCARDED: {
          discarded += 1
          break
        }
        default: {
          break
        }
      }
    }
  }
  return {
    _new,
    applied,
    discarded,
  }
})
