import React, { useCallback } from "react"
import withMemo from "../../store/HOC/with_memo"
import { TagsList } from "../tags/TagsList"
import { isEmpty } from "lodash"
import { useAction } from "../../hooks/useAction"
import { setTagsFilter } from "../../store/actions/filters"

export const FilterByTag = withMemo(
  ({ tags, allColors, tagListStyles, resetFilters, closable }) => {
    const setTagFilter = useAction(setTagsFilter)

    const handleClickOnTag = useCallback(
      (tag) => {
        setTagFilter(tag)
      },
      [setTagFilter]
    )

    return (
      <>
        {!isEmpty(tags) && (
          <div
            className="library-tags-list"
            style={{
              alignItems: "center",
              border: "1px solid #F0F0F0",
              height: "648px",
              overflowY: "scroll",
              borderRadius: "6px",
              padding: "13px 16px",
              background: "#ffffff",
              marginRight: "16px",
              float: "left",
              maxWidth: "164px",
              cursor: "pointer",
            }}
          >
            <div className="docs-tagfilter">
              <TagsList
                allColors={allColors}
                closable={closable}
                tags={tags}
                restStyles={tagListStyles}
                handleClickOnTag={handleClickOnTag}
                resetFilters={resetFilters}
              />
            </div>
          </div>
        )}
      </>
    )
  }
)
