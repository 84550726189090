import React from "react"
import { Button, Radio, Typography, Popconfirm } from "antd"
import { QuestionCircleOutlined } from '@ant-design/icons';
import withMemo from "../../store/HOC/with_memo"
import { useDispatch, useSelector } from "react-redux"
import { getTodoCurrentFilter } from "../../store/selectors"
import { updateTodoFilter } from "../../store/actions/todos/update-todo"
import { clearAllActive } from "../../store/actions/todos/delete"
import { getTodos } from "../../store/actions"
import { DeleteOutlined } from "@ant-design/icons"

const { Title } = Typography

const TodoActions = () => {
  const dispatch = useDispatch()
  const currentFilter = useSelector(getTodoCurrentFilter)
  const setTodoFilter = (e) => {
    dispatch(updateTodoFilter(e.target.value))
    dispatch(getTodos())
  }
  const handleClearAllActive = () => {
    dispatch(clearAllActive())
  }
  return (
    <div
      className="todo-group"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        justifyContent: "space-between",
      }}
    >
      <Title className="desktop-title" level={4}>
        Tasks
      </Title>
      <Radio.Group
        onChange={setTodoFilter}
        defaultValue={currentFilter}
        buttonStyle="solid"
        size="small"
      >
        <Radio.Button value={2} style={{ borderRadius: "6px 0px 0px 6px" }}>
          All
        </Radio.Button>
        <Radio.Button value={1} style={{ borderRadius: "0px 6px 6px 0px" }}>
          Active
        </Radio.Button>
      </Radio.Group>

      <Popconfirm
        placement="topLeft"
        title='Delete completed tasks? There is no undo.'
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={handleClearAllActive}
        okText="Yes"
        cancelText="No"
      >

        <Button

          size={"small"}
          danger
          type="primary"
          style={{
            marginLeft: "24px",
          }}
        >

          <DeleteOutlined className="custom-delete-color" /> Clear Done
        </Button>
      </Popconfirm>
    </div>
  )
}
export default withMemo(TodoActions)
