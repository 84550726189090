import { SET_ALL_COLORS } from "../../action-types/colors"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  allColors: {},
}

export const colorsReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        allColors: {},
      }
    }
    case SET_ALL_COLORS: {
      return {
        ...state,
        allColors: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
