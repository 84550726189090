import { Typography, Flex, Breadcrumb } from "antd"

const { Title, Text } = Typography

const WhatIsSavvy = ({ setBreadCrumb, breadCrumbs }) => {
    return (
        <>
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    maxWidth: "750px",
                    marginBottom: "82px"
                }}>

                <Breadcrumb
                    separator=">"
                    style={{
                        marginTop: "30px",
                        marginBottom: "30px"
                    }}
                    items={[
                        {
                            title: <Text className="bread-crumb" onClick={() => setBreadCrumb(breadCrumbs[0])}>Help</Text>,
                        },
                        {
                            title: <span style={{ color: "#009EEF" }} href="">What is Savvyy?</span>,
                        }
                    ]}
                />

                <Title
                    style={{
                        marginBottom: "40px"
                    }}
                >
                    What is Savvyy?
                </Title>

                <Title
                    level={3}
                    style={{
                        marginBottom: "20px"
                    }}
                >
                    Savvyy ... Designed Differently for Your Success
                </Title>

                <Text
                    style={{
                        color: "#000000",
                        marginBottom: "20px"
                    }}
                >
                    If finding that dream job was as dreamy/easy/effortless as it sounds, wouldn’t we all have one? The reality is that job searches can be exhausting, even causing some low blows to our self esteem. Savvyy is here to organize your job leads, contacts, company contacts – all in one cloud-based platform. Savvyy handles emails for those things you don’t want to forget or just can’t lose: research, notes, to-dos & follow up lists. Every powerful tool available at your fingertips to make your next job search, well … dreamy!
                </Text>

                <Title
                    level={4}
                    style={{
                        marginBottom: "20px"
                    }}
                >
                    Different How?
                </Title>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        Most businesses, especially the ones in the B2B space, leverage and pay tens of billions for a CRM (customer relationship manager) because, it is no secret that relationships still matter! The CRM Market is expected to hit $96 billion by 2026.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        As one individual person, we don’t usually have those outside contacts like big business partners, customers, suppliers, and regulators. Within the context of our job searches we will certainly have or gather tracking leads, the need to follow up with recruiters and HR contacts, as well as general networking, and establish relationships.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="flex-start"
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginTop: "9px" }}></div>

                    <Text
                        style={{
                            color: "#000000",
                        }}
                    >
                        It becomes very valuable, very quickly tto have the following pieces all in one place:  tasks/to- dos, diary and notes, and the ability to send things into this central place and then continue with the process possibly later (that value of continuity).
                    </Text>

                </Flex>

                <div style={{ height: "1px", background: "#DADADA", margin: "40px 0" }}></div>

                <Title
                    level={3}
                    style={{
                        marginBottom: "20px"
                    }}
                >
                    CRM. Savvyy. You. Your Job Search. How it All Fits Together? Perfectly!
                </Title>

                <Text
                    style={{
                        color: "#000000",
                    }}
                >
                    With so much going on, there is no overabundance of time, energy or focus remaining to dedicate to the search for the new career. You are generating:
                </Text>

                <Flex
                    justify="flex-start"
                    align="center"
                    style={{
                        marginBottom: "10px",
                        marginTop: "20px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginBottom: "-2px" }}></div>

                    <Text
                        style={{
                            color: "#000000"
                        }}
                    >
                        a flood of information from conversations about prospective openings.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="center"
                    style={{
                        marginBottom: "10px"
                    }}
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginBottom: "-2px" }}></div>

                    <Text
                        style={{
                            color: "#000000"
                        }}
                    >
                        valuable information is landing on sticky notes & the backs of business cards.
                    </Text>

                </Flex>

                <Flex
                    justify="flex-start"
                    align="center"
                >

                    <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginBottom: "-2px" }}></div>

                    <Text
                        style={{
                            color: "#000000"
                        }}
                    >
                        emails, notepads, things you might even be trying to remember.
                    </Text>

                </Flex>

                <div style={{ height: "1px", background: "#DADADA", margin: "40px 0" }}></div>

                <Text
                    style={{
                        color: "#000000",
                    }}
                >
                    Details can get lost, confused or forgotten. Phone calls, emails, texts and social media are all coming at you. Do you respond? What were the questions? How long did it take you to respond?
                </Text>

                <Title
                    level={4}
                    style={{
                        marginBottom: "20px",
                        marginTop: "32px"
                    }}
                >
                    Savvyy Brings CRM to Your Job Search
                </Title>

                <Title
                    level={4}
                    style={{
                        marginTop: "20px"
                    }}
                >
                    Invite Savvyy into your world to bring all of the pieces together
                </Title>

            </div>
        </>
    )
}

export default WhatIsSavvy
