import {
  UPDATE_UI,
  RESET_MODALS,
  SET_ANTD_MODALS,
  CLOSE_ANTD_MODALS,
  UPDATE_MODAL_PROPS,
  IS_LOADING_UI,
} from "../../action-types/ui"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  modal: null,
  modal_message: "",
  antd_modals: [],
  isLoading: false,
}

export const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        modal: null,
        modal_message: "",
        antd_modals: [],
        isLoading: false,
      }
    }
    case CLOSE_ANTD_MODALS: {
      return {
        ...state,
        antd_modals: action.payload
          ? state.antd_modals.filter((m) => m.id !== action.payload.id)
          : [],
      }
    }
    case SET_ANTD_MODALS: {
      return {
        ...state,
        antd_modals: state.antd_modals
          .filter((i) => i.type !== action.payload.type)
          .concat(action.payload),
      }
    }
    case UPDATE_MODAL_PROPS: {
      return {
        ...state,
        antd_modals: state.antd_modals.map((m) => {
          return m.type === action.payload.type
            ? {
                ...m,
                props: {
                  ...m.props,
                  ...action.payload,
                },
              }
            : m
        }),
      }
    }
    case UPDATE_UI: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET_MODALS: {
      return {
        ...state,
        modal: null,
        antd_modals: [],
        showInput: false,
        modal_message: "",
      }
    }
    case IS_LOADING_UI: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
