import {
  CREATE_LINK,
  UPDATE_LINK,
  SET_SPECIFIC_LINK,
} from "../../action-types/links"
import { LINKS_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import { getSpecificNote } from "../../selectors/notes/get_notes_selector"
import { editSpecificNoteWithLinks } from "../notes/edit_note"
import { setLinksPendingStatus } from "./get_links"

export const setNewLink = (payload) => ({ type: CREATE_LINK, payload })

export const setUpdateLink = (linkId, payload) => ({
  type: UPDATE_LINK,
  linkId,
  payload,
})

export const setSpecificLink = (payload) => ({
  type: SET_SPECIFIC_LINK,
  payload,
})

export const createNewLink = (payload, isEdit) => (dispatch, getState) => {
  dispatch(setLinksPendingStatus(true))
  const note = getSpecificNote(getState())
  return request()
    .create(LINKS_API_URL, payload, dispatch)
    .then((res) => {
      dispatch(setNewLink(res.data))
      if (isEdit) {
        dispatch(editSpecificNoteWithLinks(res.data, "links", note))
      }

      return res.data
    })
    .finally((data) => {
      dispatch(setLinksPendingStatus(false))
      return data
    })
}

export const updateLink = (id, payload, isEdit) => (dispatch, getState) => {
  const note = getSpecificNote(getState())
  return request()
    .edit(`${LINKS_API_URL}${id}/`, payload, dispatch, "patch")
    .then((res) => {
      dispatch(setUpdateLink(id, res.data))
      if (isEdit) {
        dispatch(editSpecificNoteWithLinks(res.data, "links", note))
      }

      return res.data
    })
}
