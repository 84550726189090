import { createSelector } from "reselect"

export const getUiState = (state) => state.ui

export const getUiModals = createSelector(
  getUiState,
  (ui_state) => ui_state.modal
)

export const getAntdModals = createSelector(
  getUiState,
  (state) => state.antd_modals
)

export const getIsUILoading = createSelector(
  getUiState,
  (state) => state.isLoading
)
