import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import DraggableWorkspaceTabItem from "../workspaces/draggable-workspace-tab-item"
import withMemo from "../../store/HOC/with_memo"
import { FeaturesTypes } from "../../common/constants"

export const WorkspacesList = withMemo(
  ({
    workspaces,
    handleChangeTab,
    getClassName,
    handleDragWorkspace,
    ...rest
  }) => {
    const onDragEnd = (result) => {
      if (!result.destination) {
        return
      }
      handleDragWorkspace(result)
    }
    const getListStyle = (isDraggingOver) => ({
      background: isDraggingOver ? "lightblue" : "transparent",
      display: "flex",
      paddingLeft: "4px",
      overflow: "auto",
    })

    return (
      <div className="d-flex">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className="ant-tabs ant-tabs-top ant-tabs-editable ant-tabs-card ant-tabs-editable-card flex-row"
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {workspaces.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={`workspace-tab custom-tab 
                      ${getClassName(item.id)}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DraggableWorkspaceTabItem
                          data={item}
                          type={FeaturesTypes.workspace}
                          tab={item}
                          handleChangeTab={handleChangeTab}
                          getClassName={getClassName}
                          {...rest}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
)
