import React from "react"
import CloseIcon from "../../assets/images/close_icon.svg"

const ModalCloseIcon = () => {
  return (
    <span className="custom-closeicon">
      <img src={CloseIcon} alt="close-icon" />
    </span>
  )
}
export default ModalCloseIcon
