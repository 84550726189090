import { useCallback, useEffect } from "react"
import { useAction } from "./useAction"
import {
  closeAntdModals,
  updateAntdModals,
} from "../store/actions/ui/update_ui"
import {
  generateId,
  renderTitleByType,
  focusOnLastModal,
} from "../common/helpers"
import { setSpecificLead } from "../store/actions/jobs/update-job"
import {
  AddLead,
  DeleteCompany,
  NoteEditor,
  WhatTodoModal,
  FileUploadModal,
} from "../components"
import { useSelector } from "react-redux"
import { setSpecificContact } from "../store/actions/contacts/get-contacts-list"
import AddContact from "../components/modals/add-contact"
import { BookmarkModal } from "../components/modals/BookmarkModal"
import { setSpecificLink } from "../store/actions/links/create-link"
import { downloadFile } from "../store/actions/file_manager/download-file"
import { setSpecificNote } from "../store/actions/notes/create_note"
import { editNote } from "../store/actions/notes/edit_note"
import { deleteNote } from "../store/actions/notes/remove"
import { setSpecificCommunication } from "../store/actions/communication/create-communication"
import EditCompany from "../components/modals/edit-company"
import { AddCommunications } from "../components/modals/add-communications"
import { setSpecificCompany } from "../store/actions/companies/get-companies"
import { getSpecificJob } from "../store/selectors"
import { getAntdModals } from "../store/selectors/ui/get-ui"
import { FeaturesTypes } from "../common/constants"
import { getCommunicationById } from "../store/actions/communication/get-communications"
import { getNotesByParams } from "../store/actions/notes/get_notes"
import { defaultModalConfig } from "../common/modal.config"
import { CommunicationDrawer } from "../components/communications/CommunicationDrawer"
import { getJobById } from "../store/actions/jobs/get-jobs"
import { getSpecificContact } from "../store/selectors/contacts/get-contacts-selector"
import { useLocation } from "react-router-dom"
import { URLS } from "../common/urls"

export const useActivityItem = (props = {}) => {
  const location = useLocation()
  const { activity_type, activity } = props
  const closeAntdModal = useAction(closeAntdModals)
  const openModalAction = useAction(updateAntdModals)
  const setLeadAction = useAction(setSpecificLead)
  const setLinkAction = useAction(setSpecificLink)
  const setNoteAction = useAction(setSpecificNote)
  const editNoteAction = useAction(editNote)
  const setCompanyAction = useAction(setSpecificCompany)
  const setCommunicationAction = useAction(setSpecificCommunication)
  const downloadFileAction = useAction(downloadFile)
  const setContactAction = useAction(setSpecificContact)
  const deleteNoteAction = useAction(deleteNote)
  const communicationActionById = useAction(getCommunicationById)
  const notesByParams = useAction(getNotesByParams)
  const getJob = useAction(getJobById)
  const lead = useSelector(getSpecificJob)
  const contact = useSelector(getSpecificContact)
  const antd_modals = useSelector(getAntdModals)

  const renderTitle = useCallback(() => {
    return renderTitleByType(activity, activity_type)
  }, [activity, activity_type])
  const modalOpenMiddleware = useCallback(
    (
      e,
      Modal,
      actionCreator = async () => {},
      activity,
      newProps = {},
      type
    ) => {
      const id = e.id || Number(e?.currentTarget?.dataset?.id) || activity.id

      const props = {
        ...defaultModalConfig,
        onClose: async (e) => {
          await closeAntdModal({ id: id || e })
          await actionCreator({})

          const modalContents = focusOnLastModal()
          if (location.pathname.includes(URLS.HOME)) {
            !modalContents?.length &&
              document.querySelector(".ant-drawer")?.focus()
          }
          document
            .querySelectorAll(".diary_edit_button_close")
            ?.forEach((el) => el?.click())

          newProps?.callBackFun && (await newProps.callBackFun())
        },
        handleEditNote: (isInline, data, canUpdateTags) => {
          const val = { ...data }
          !val.company_id && delete val.company_id
          editNoteAction(val, false, canUpdateTags, false)
          if (!isInline) {
            closeAntdModal({ id })
          }
        },
        handleOpenCompanyDeleteModal: () => {
          const id = generateId()
          openModalAction({
            element: DeleteCompany,
            id,
            props: {
              handleClose: () => {
                closeAntdModal({ id })
              },
              visible: true,
            },
          })
        },
        handleDeleteNote: (noteId) => {
          deleteNoteAction(noteId)
          closeAntdModal({ id })
        },
        visible: true,
        ...newProps,
      }
      actionCreator(activity)
      openModalAction({ element: Modal, id, props, type })
    },
    [
      closeAntdModal,
      deleteNoteAction,
      editNoteAction,
      location.pathname,
      openModalAction,
    ]
  )

  const handleAutoClose = useCallback(
    async (type) => {
      const isOpen = antd_modals.find((m) => m.type === type)
      if (isOpen) {
        await closeAntdModal({ id: isOpen.id })
      }
    },
    [antd_modals, closeAntdModal]
  )

  const handleClick = useCallback(
    async (e, activity_type, activity, newProps = {}) => {
      switch (activity_type) {
        case FeaturesTypes.COMPANY: {
          await handleAutoClose(FeaturesTypes.COMPANY)

          activity?.id && notesByParams(activity.id)
          modalOpenMiddleware(
            e,
            EditCompany,
            setCompanyAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.COMMUNICATION: {
          if (contact?.id !== activity.contact && activity.contact) {
            communicationActionById(activity.id)
          }
          if (lead?.id !== activity.job && activity.job) {
            getJob(activity.job)
          }
          modalOpenMiddleware(
            e,
            CommunicationDrawer,
            setCommunicationAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.LEAD: {
          await handleAutoClose(FeaturesTypes.LEAD)
          modalOpenMiddleware(
            e,
            AddLead,
            setLeadAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.WHAT_TODO: {
          await handleAutoClose(FeaturesTypes.WHAT_TODO)
          modalOpenMiddleware(
            e,
            WhatTodoModal,
            () => {},
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.CONTACT: {
          await handleAutoClose(FeaturesTypes.CONTACT)
          modalOpenMiddleware(
            e,
            AddContact,
            setContactAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.LINK: {
          if (e.ctrlKey || e.metaKey) {
            const link = activity.url.startsWith("https")
              ? activity.url
              : `https://${activity.url}`
            window.open(link)
            return
          }
          modalOpenMiddleware(
            e,
            BookmarkModal,
            setLinkAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.FILES: {
          downloadFileAction(activity)
          break
        }
        case FeaturesTypes.NOTE: {
          modalOpenMiddleware(
            e,
            NoteEditor,
            setNoteAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.MINI_COMMUNICATION: {
          modalOpenMiddleware(
            e,
            AddCommunications,
            setCommunicationAction,
            activity,
            newProps,
            activity_type
          )
          break
        }
        case FeaturesTypes.FILE_UPLOAD: {
          modalOpenMiddleware(
            e,
            FileUploadModal,
            async () => {},
            activity,
            newProps,
            activity_type
          )
          break
        }
        default: {
          return
        }
      }
    },
    [
      handleAutoClose,
      notesByParams,
      modalOpenMiddleware,
      setCompanyAction,
      contact?.id,
      lead?.id,
      setCommunicationAction,
      communicationActionById,
      getJob,
      setLeadAction,
      setContactAction,
      setLinkAction,
      downloadFileAction,
      setNoteAction,
    ]
  )
  useEffect(() => {
    return () => {
      // setCompanyAction({})
      // setCommunicationAction({})
      // setLeadAction({})
      // setContactAction({})
      setLinkAction({})
      setNoteAction({})
    }
  }, [
    setCommunicationAction,
    setCompanyAction,
    setContactAction,
    setLeadAction,
    setLinkAction,
    setNoteAction,
  ])

  return {
    handleClick,
    renderTitle,
  }
}
