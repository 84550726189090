import React from "react"
import moment from "moment"

import { DATE_FORMAT } from "../../common/config"
import { Typography } from "antd"

import withMemo from "../../store/HOC/with_memo"
import styles from "./style.module.css"

const { Text } = Typography

export const KeyDate = withMemo(({ title, date, style }) => {
  return (
    <Text className={styles.text}>
      <div style={style} className="d-flex">
        <span style={{ marginRight: 0 }} className={styles.title}>{title}</span>
        {
          date && <span className={styles.date}> {title && ":"}{moment(date).format(DATE_FORMAT)}</span>
        }
      </div>
    </Text>
  )
})
