import { useCallback, useEffect, useState } from "react"
import { useAction } from "./useAction"
import { createWorkspaceThunk } from "../store/actions/workspaces/create-workspace"
import {
  dragWorkspace,
  editWorkspaceThunk,
} from "../store/actions/workspaces/edit-workspace"

export const useWorkspaceModal = (
  current_workspace,
  isEdit,
  handleCloseWorkspaceModal
) => {
  const [showInput, setShowInput] = useState(false)
  const [title, setTitle] = useState("")
  const handleChange = useCallback((e) => {
    setTitle(e.target.value)
  }, [])
  const editWorkspace = useAction(editWorkspaceThunk)
  const dragWorkspaceAction = useAction(dragWorkspace)
  const createWorkspace = useAction(createWorkspaceThunk)
  const handleSubmit = useCallback(() => {
    if (isEdit) {
      editWorkspace({
        ...current_workspace,
        title,
      })
    } else {
      createWorkspace({ title })
    }
    setTitle("")
    setShowInput(false)
    handleCloseWorkspaceModal()
  }, [
    createWorkspace,
    current_workspace,
    editWorkspace,
    handleCloseWorkspaceModal,
    isEdit,
    title,
  ])

  useEffect(() => {
    if (current_workspace?.id && !title.trim()) {
      setTitle(current_workspace.title)
    }
    //eslint-disable-next-line
  }, [current_workspace?.id, current_workspace?.title])
  return {
    title,
    handleSubmit,
    handleChange,
    showInput,
    setShowInput,
    dragWorkspaceAction,
    setTitle,
  }
}
