import React, { useEffect, useMemo } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "../../styles/editor.css"
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons"

export const QuilEditor = ({
  onChange,
  content,
  editorRef,
  is_diary = false,
  focus = false,
  showChild = false,
  setShowChild = () => { },
}) => {
  // const [showChild, setShowChild] = useState(() => {
  //   return !!content?.replace(/(<([^>]+)>)/gi, "")
  // })
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["clean"],
        ],
      },
      clipboard: { matchVisual: false },
    }),
    []
  )
  const toggleEditor = (e) => {
    e.stopPropagation()
    setShowChild((prev) => !prev)
  }
  useEffect(() => {

    const timer = setTimeout(() => {
      if (focus || showChild) {
        if (content?.length) editorRef?.current?.editor.setSelection(content.length)
        if (content?.ops?.length) {
          const selectionIndex = editorRef?.current?.editor?.getText().length
          editorRef?.current?.editor.setSelection(selectionIndex)
        }
        editorRef?.current?.editor?.focus()
        editorRef?.current?.focus()
      }
    }, 800)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChild, focus, editorRef])

  return (
    <>
      <div className="main-panel" id="quill_editor">
        <div
          className="main-content"
          style={{ position: "relative", width: "100%" }}
        >
          <ReactQuill
            className={showChild || is_diary ? "enable" : ""}
            ref={editorRef}
            defaultValue={content}
            value={content}
            onChange={onChange}
            theme="snow"
            modules={modules}
          />
        </div>
      </div>
      {!is_diary &&
        (showChild ? (
          <CaretDownOutlined
            onClick={toggleEditor}
            style={{
              position: "absolute",
              top: '12px',
              right: '2%',
              color: "#009EEF",
              fontSize: "16px",
            }}
          />
        ) : (
          <CaretRightOutlined
            onClick={toggleEditor}
            style={{
              position: "absolute",
              top: '12px',
              right: '2%',
              color: "#009EEF",
              fontSize: "16px",
            }}
          />
        ))}
    </>
  )
}
