import { createSelector } from "reselect"
import { TODO_NOT_DONE_STATUS } from "../../../common/constants"

const getTodos = (state) => state.todos

export const getDraftTodoList = createSelector(getTodos, (state) => {
  return state?.draftItems || []
})

export const getTodoCurrentFilter = createSelector(getTodos, (state) => {
  return state.filter
})

export const getGroupedTodosSelector = createSelector(
  [getTodos, getTodoCurrentFilter],
  (value, filter) => {
    return value.todosGroups.filter((todo) =>
      filter === TODO_NOT_DONE_STATUS ? todo.status_id === filter : true
    )
  }
)

export const getTodosSelector = createSelector(getTodos, (value) => {
  return value.todos
})

export const getTodosPendingStatus = createSelector(
  getTodos,
  (state) => state.isPending
)
