import { createSelector } from "reselect"
import { DiariesSelector } from "./get_diaries_selector"

export const getDiariesActivitiesSelector = createSelector(
  DiariesSelector,
  (state) => state.activity
)

export const getDiariesActivitiesDateSelector = createSelector(
  DiariesSelector,
  (state) => state.activity_date
)

export const getDiariesActivitiesConfigSelector = createSelector(
  DiariesSelector,
  (state) => state.activity_config
)

export const getDiariesActivityIsPending = createSelector(
  DiariesSelector,
  (state) => state.activity_is_pending
)

export const getCurrentDiaryDate = createSelector(
  DiariesSelector,
  (state) => state.currentDiaryDate
)
