// eslint-disable-next-line
import Symbol_observable from "symbol-observable"
import { createStore, compose, applyMiddleware } from "redux"
import rootReducer from "./reducers"
import thunk from "redux-thunk"

const middlewares = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)
