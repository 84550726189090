import { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getTagsPendingStatus } from "../store/selectors/tags/get-tag-pending-status"
import { getSearchMentions } from "../store/selectors/search/get-search-results"
import { getAllTags, getTags, setTags } from "../store/actions/search/get-tags"
import { getSearch } from "../store/actions/search/get-search"
import { URLS } from "../common/urls"
import { useAction } from "./useAction"

export const useSearch = () => {
  const ANTD_SEARCHING_KEYWORD = "#ANTD_SEARCHING"
  const buttonEl = useRef(null)
  const mentionsRef = useRef(null)
  const location = useLocation()
  const tagIsFetching = useSelector(getTagsPendingStatus)
  const tagsSearchResult = useSelector(getSearchMentions)
  const dispatch = useDispatch()
  const [prefix, setPrefix] = useState("#")
  const [val, setVal] = useState("")
  const history = useHistory()
  const handleFocus = useAction(getAllTags)
  const onSearch = (query, prefix) => {
    if (query?.trim()) {
      if (prefix === "#") {
        dispatch(getTags(query))
      }
      // if (prefix === "@") {
      //   dispatch(getMentions(query))
      // }
    }
    setPrefix(prefix)
  }
  const handleChangeQuery = (v) => {
    if (v?.trim() === ANTD_SEARCHING_KEYWORD) {
      return
    }
    if (v[v.length - 1] === "#") {
      dispatch(setTags([]))
    }
    // if (val[val.length - 1] === "@") {
    //   dispatch(setMentions([]))
    // }
    setVal(v)
  }
  const handleSubmitSearch = useCallback(() => {
    dispatch(getSearch(encodeURIComponent(val)))
    history.push(URLS.SEARCH)
  }, [dispatch, history, val])
  const handleSearchKeyEnter = useCallback(
    (e) => {
      if (tagIsFetching) {
        e.preventDefault()
        return
      }
      if (e.keyCode === 13 && !e.metaKey) {
        e.preventDefault()
        setTimeout(() => {
          buttonEl.current.click()
        }, 100)
      }
      if (e.keyCode === 9 && val?.trim()[0] === "#") {
        e.preventDefault()
        const enterEvent = new KeyboardEvent("keydown", {
          key: "Enter",
          code: "Enter",
          keyCode: 13,
          charCode: 13,
          bubbles: true,
          metaKey: true,
        })
        mentionsRef.current.textarea.dispatchEvent(enterEvent)
      }
    },
    [tagIsFetching, mentionsRef, buttonEl, val]
  )

  const handleSelectTag = (option, p) => {
    if (option?.value?.trim() === ANTD_SEARCHING_KEYWORD) {
      return
    }
    const _query = val.substring(0, val.lastIndexOf("#"))
    if (!option.disabled) {
      setVal(`${_query}${p + option.value}`)
    }
  }

  useEffect(() => {
    if (location.pathname !== URLS.SEARCH && val?.trim()) {
      setVal("")
    }
    //eslint-disable-next-line
  }, [location.pathname])

  return {
    handleSearchKeyEnter,
    query: val,
    tagIsFetching,
    tagsSearchResult,
    handleSubmitSearch,
    buttonEl,
    prefix,
    onSearch,
    handleChangeQuery,
    handleSelectTag,
    handleFocus,
    mentionsRef,
  }
}
