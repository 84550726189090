import React from "react"
import { isEqual } from "lodash"

const withMemo = (Component, restProps) => {
  return React.memo(
    (props) => {
      return <Component {...props} {...restProps} />
    },
    (prevProps, nextProps) => {
      return isEqual(prevProps, nextProps)
    }
  )
}
export default withMemo
