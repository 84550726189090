import {
  ON_CHANGE_UPLOAD_PROGRESS,
  ON_UPLOAD_ERROR,
  REMOVE_FILE,
  SET_NEW_FILE,
} from "../../action-types/file_manager"
import { setFilesPendingStatus } from "./get_files_list"
import { request } from "../../../api/axios_helper"
import { FILES_API_URL } from "../../../common/constants"
import { editSpecificNoteWithLinks } from "../notes/edit_note"
import { getSpecificNote } from "../../selectors/notes/get_notes_selector"

const setUploadedProgress = (payload) => {
  return {
    type: ON_CHANGE_UPLOAD_PROGRESS,
    payload,
  }
}
export const setNewFile = (payload) => {
  return {
    type: SET_NEW_FILE,
    payload,
  }
}
export const uploadFile = (file, isEditNote) => async (dispatch, getState) => {
  try {
    dispatch(setFilesPendingStatus(true))
    const onUploadProgress = (progressEvent) => {
      dispatch(setUploadedProgress(progressEvent.loaded))
    }
    const res = await request().create(
      FILES_API_URL,
      file,
      dispatch,
      onUploadProgress
    )
    dispatch(setNewFile(res.data))
    if (isEditNote) {
      const note = getSpecificNote(getState())
      dispatch(editSpecificNoteWithLinks(res.data, "atts", note))
    }
    return res
  } catch (err) {
    dispatch({ type: ON_UPLOAD_ERROR })
  } finally {
    dispatch(setFilesPendingStatus(false))
  }
}

const onDeleteFile = (payload) => {
  return {
    type: REMOVE_FILE,
    payload,
  }
}
export const removeFile = (file) => async (dispatch) => {
  try {
    await request().delete(`${FILES_API_URL}${file}/`, dispatch, () => {
      dispatch(onDeleteFile(file))
    })
  } catch (err) {}
}
