import {
  SET_GLOBAL_SEARCH_PENDING_STATUS,
  EDIT_SEARCH_PAGE,
  RESET_SEARCH_PAGE,
  SET_SEARCH_RESULTS,
  REMOVE_SEARCH_RESULT,
  SET_SEARCH_QUERY,
} from "../../action-types/search"
import { setSearchPendingStatus } from "./get-tags"
import { request } from "../../../api/axios_helper"
import { SEARCH_API_URL } from "../../../common/constants"

export const setSearchResults = (payload) => {
  return {
    type: SET_SEARCH_RESULTS,
    payload,
  }
}
export const setGlobalSearchPendingStatus = (payload) => ({
  type: SET_GLOBAL_SEARCH_PENDING_STATUS,
  payload,
})
export const getSearch = (query) => async (dispatch) => {
  try {
    dispatch(setSearchPendingStatus(true))
    dispatch(setGlobalSearchPendingStatus(true))
    const response = await request().get(
      `${SEARCH_API_URL}?q=${query}`,
      dispatch
    )
    dispatch(setSearchResults(response.data))
    dispatch(setSearchPendingStatus(false))
  } catch (err) {
  } finally {
    dispatch(setGlobalSearchPendingStatus(false))
  }
}

export const resetSearchPage = () => ({ type: RESET_SEARCH_PAGE })

export const editSearchPage = (payload, key) => {
  return {
    type: EDIT_SEARCH_PAGE,
    payload,
    key,
  }
}

export const removeSearchResult = (id, key) => {
  return {
    type: REMOVE_SEARCH_RESULT,
    payload: { id, key },
  }
}

export const setQuery = (payload) => ({
  type: SET_SEARCH_QUERY,
  payload,
})
