import React, { useCallback } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input, Typography } from "antd"
import { useWorkspaceModal } from "../hooks/useWorkspaceModal"
import { WorkspacesList } from "./docs/workspaces-list"

const { Text } = Typography

export const DraggableCustomWorkspaceTabs = ({
  workspaces,
  handleDragWorkspace,
  isEditable,
  getClassName,
  handleEditWorkspace,
  handleChangeTab,
  handleRemoveWorkspace,
  isEdit,
  current_workspace,
  handleCloseWorkspaceModal,
}) => {
  const {
    title,
    setTitle,
    handleChange,
    handleSubmit,
    showInput,
    setShowInput,
  } = useWorkspaceModal(current_workspace, isEdit, handleCloseWorkspaceModal)
  const renderInput = useCallback(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode !== 27) {
        return
      }
      if (!isEdit) {
        setShowInput(false)
        return
      }
      setTitle("")
      handleCloseWorkspaceModal()
    }
    const handleBlur = () => {
      if (!isEdit) {
        setShowInput(false)
        return
      }
      setTitle("")
      handleCloseWorkspaceModal()
    }
    return (
      <Input
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        value={title}
        onChange={handleChange}
        onPressEnter={handleSubmit}
        className="ant-tabs-tab"
        autoFocus
        style={{ maxWidth: "150px", cursor: "text", color: "#000" }}
      />
    )
    //eslint-disable-next-line
  }, [isEdit, handleChange, handleCloseWorkspaceModal, handleSubmit, title])
  return (
    <div className="docs-custom-tabs d-flex">
      <div
        data-workspace_id={0}
        className={`custom-tab ${getClassName(0)}`}
        onClick={handleChangeTab}
        style={{
          height: "40px",
          borderRadius: "6px",
        }}
      >
        <div style={{ width: "67px", textAlign: "center" }}>
          <Text style={{ fontWeight: "500" }}>Inbox</Text>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: "4px",
        }}
      >
        <WorkspacesList
          renderInput={renderInput}
          handleDragWorkspace={handleDragWorkspace}
          workspaces={workspaces}
          handleCloseWorkspaceModal={handleCloseWorkspaceModal}
          isEdit={isEdit}
          current_workspace={current_workspace}
          handleChangeTab={handleChangeTab}
          getClassName={getClassName}
          handleEditWorkspace={handleEditWorkspace}
          handleRemoveWorkspace={handleRemoveWorkspace}
          isEditable
        />
        {showInput && renderInput()}
        {isEditable && (
          <Button
            className="ant-tabs-nav-add workspace-add-button"
            icon={<PlusOutlined className="docs-tabs-plus" />}
            onClick={() => setShowInput(true)}
          />
        )}
      </div>
    </div>
  )
}
