import React from "react"
import "./App.css"
import { RenderRouter } from "./routes"
import { connect } from "react-redux"
import {
  authWithLinkedin,
  confirmUserEmail,
  notifyServerWhenLogout,
  verifyUserToken,
} from "./store/actions/user"
import { AuthHeader } from "./components"
import { withRouter } from "react-router-dom"
import { Layout, notification } from "antd"
import { getUserData } from "./store/selectors/user/getUserData"
import { isEqual } from "lodash"
import { getErrorsSelector } from "./store/selectors/errors/get-errors-selector"
import { getUserIsAuthenticated } from "./store/selectors/user/get-login-status"
import { ModalManager } from "./store/HOC/modal-manager"
import { getUiModals } from "./store/selectors/ui/get-ui"
import {
  ACCOUNT_CONFIRMATION,
  PASSWORD_RESET,
  redirectExclusionUrls,
} from "./common/constants"
import queryString from "query-string"
import { checkRedirect } from "./common/helpers"
import withShouldUpdate from "./store/HOC/withShouldUpdate"
import { compose } from "redux"
import {
  setErrorMessage,
  setErrorState,
} from "./store/actions/errors/set_errors"
import { URLS } from "./common/urls"
import { RenderAntdModals } from "./components/modals/RenderAntdModals"
import { resetModals } from "./store/actions/ui/update_ui"
import { ConfigProvider } from "antd"
import GlobalStyles from "./styles/globalStyles"

const { Content } = Layout
class AppComp extends React.Component {
  constructor() {
    super()
    this.checkIsLoggedIn = () => { }
  }
  componentDidMount() {
    this.checkIsLoginLinkedin()
    this.props.verifyToken()
    this.checkEmailVerified()
    this.checkPasswordIsConfirmRequest()
    checkRedirect.call(this)
    this.checkIsLoggedIn = setInterval(() => {
      if (!Number(localStorage.getItem("logged_in"))) {
        this.props.logout(this.props.location.pathname)
      }
    }, 5000)
  }
  checkEmailVerified = () => {
    const { pathname } = this.props.location
    const urlArr = pathname.split("/").filter((item) => !!item.trim())
    if (!urlArr.includes(ACCOUNT_CONFIRMATION)) {
      return
    }
    this.props.confirmEmail(urlArr[urlArr.length - 1])
  }
  checkIsLoginLinkedin = () => {
    const { search } = this.props.location
    if (!search.includes("code")) {
      return
    }
    this.props.loginWithLinkedin(queryString.parse(search))
  }
  checkPasswordIsConfirmRequest = () => {
    const { pathname } = this.props.location
    if (!pathname.includes(PASSWORD_RESET)) {
      return
    }
    const parsedUrl = pathname.split("/").filter((item) => !!item)
    this.props.history.push({
      pathname: URLS.FORGOT_PASS_CONFIRM,
      state: {
        extra_data: {
          uid: parsedUrl[parsedUrl.length - 2],
          token: parsedUrl[parsedUrl.length - 1],
        },
      },
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      errors: { hasError, errorMessage },
    } = this.props
    if (
      (prevProps?.profile?.profile?.redirect_profile?.next !== this.props.profile?.profile?.redirect_profile?.next) ||
      prevProps.isAuth !== this.props.isAuth
    ) {
      checkRedirect.call(this)
    }
    if (
      !isEqual(this.props.errors, prevProps.errors) &&
      hasError &&
      errorMessage?.trim()
    ) {
      this.notificationCb.call(this)
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.resetModals()
    }
  }

  notificationCb = () => {
    const { setErrorMessage, setErrorState } = this.props
    notification["error"]({
      message: "Something went wrong",
      duration: 2.5,
      description: this.props.errors.errorMessage,
      onClose() {
        setErrorMessage("")
        setErrorState(false)
      },
    })
  }
  componentWillUnmount() {
    clearInterval(this.checkIsLoggedIn)
  }

  render() {
    const { modal: Modal } = this.props
    return (
      <>
        <ConfigProvider theme={GlobalStyles}>
          <Layout
            style={{
              height: "100%",
            }}
          >
            <AuthHeader />
            <Layout>
              <Content>
                <RenderRouter />
              </Content>
            </Layout>
          </Layout>
          {this.props.modal && (
            <ModalManager>
              <Modal
                content={this.props.modal_message}
                showInput={this.props.showInput}
                btn_message={this.props.btn_message}
                child={this.props.child}
                modalCallback={this.props.modalCallback}
              />
            </ModalManager>
          )}
          <RenderAntdModals />
        </ConfigProvider>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    child: state.ui.child,
    btn_message: state.ui.btn_message,
    modalCallback: state.ui.modal_callback,
    showInput: state.ui.showInput,
    modal_message: state.ui.modal_message,
    modal: getUiModals(state),
    profile: getUserData(state),
    errors: getErrorsSelector(state),
    isAuth: getUserIsAuthenticated(state),
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: (location) => {
      const cantSeePrivatePages = !redirectExclusionUrls.some((item) =>
        item.includes(location)
      )
      if (cantSeePrivatePages) dispatch(notifyServerWhenLogout())
    },
    resetModals: () => {
      dispatch(resetModals())
    },
    loginWithLinkedin: ({ code }) => {
      dispatch(authWithLinkedin(code))
    },
    confirmEmail: (code) => dispatch(confirmUserEmail(code)),
    verifyToken: () => dispatch(verifyUserToken()),
    setErrorMessage: (msg) => dispatch(setErrorMessage(msg)),
    setErrorState: (state) => dispatch(setErrorState(state)),
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withShouldUpdate
)(AppComp)
