import React from "react"
import { Button, Mentions } from "antd"
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined"
import { useSearch } from "../hooks/useSearch"
import { SEARCH_PREFIXES } from "../common/constants"


export const Search = () => {
  const {
    handleSearchKeyEnter,
    query,
    tagIsFetching,
    tagsSearchResult,
    handleSubmitSearch,
    buttonEl,
    prefix,
    onSearch,
    handleChangeQuery,
    handleSelectTag,
    handleFocus,
    mentionsRef
  } = useSearch()

  return (
    <div className="d-flex" onKeyDown={handleSearchKeyEnter}>
      <Mentions
        ref={mentionsRef}
        className="search-mentions-input"
        onFocus={handleFocus}
        placeholder="input # to mention tag"
        prefix={SEARCH_PREFIXES}
        onSearch={onSearch}
        value={query}
        placement="bottom"
        onChange={handleChangeQuery}
        onSelect={handleSelectTag}
        loading={tagIsFetching}
        id="mentions_input"
        options={
          tagsSearchResult[prefix].map((value) => ({ label: value, value }))
        }
      />
      <Button type="primary" onClick={handleSubmitSearch} ref={buttonEl}>
        <SearchOutlined />
      </Button>
    </div>
  )
}
