import {
  SET_DIARIES_DATA,
  SET_DIARIES_LOADING_PROGRESS,
  SET_TODAY_DIARY_PENDING,
} from "../../action-types/diaries"
import {
  DIARIES_API_URL,
  DIARIES_TODAY_API_URL,
} from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import { setSpecificNote } from "../notes/create_note"
import { setCurrentDiaryDate } from "../notes/edit_note"

export const setDiaries = (payload) => {
  return {
    type: SET_DIARIES_DATA,
    payload,
  }
}
export const setDiariesLoadingProgress = (payload) => {
  return {
    type: SET_DIARIES_LOADING_PROGRESS,
    payload,
  }
}
export const getDiaries = (isOnClose) => async (dispatch) => {
  try {
    !isOnClose && dispatch(setDiariesLoadingProgress(true))
    const res = await request().get(DIARIES_API_URL, dispatch)
    if (res.status === 200) {
      dispatch(setDiaries(res.data))
    }
  } catch (err) {
    throw err
  } finally {
    dispatch(setDiariesLoadingProgress(false))
  }
}

export const setTodayDiaryPending = (payload) => {
  return {
    type: SET_TODAY_DIARY_PENDING,
    payload,
  }
}
export const getTodayDiary = (cb) => async (dispatch) => {
  dispatch(setTodayDiaryPending(true))
  request()
    .get(DIARIES_TODAY_API_URL, dispatch)
    .then((res) => {
      const { date, note } = res.data
      dispatch(setSpecificNote(note || {}))
      dispatch(setCurrentDiaryDate(date))
      if (cb) {
        cb()
      }
    })
    .finally(() => dispatch(setTodayDiaryPending(false)))
}
