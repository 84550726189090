import {
  SET_TODO_FILTER,
  UPDATE_CONTAINER_TITLE,
  UPDATE_TODO,
} from "../../action-types/todos"
import { setTodoPendingStatus } from "./create_todo"
import { request } from "../../../api/axios_helper"
import { TODOS_API_URL } from "../../../common/constants"

const updateToDoAc = (data, containerId, status) => {
  return {
    type: UPDATE_TODO,
    payload: data,
    containerId,
    status,
  }
}

export const updateContainerTitle = (payload) => ({
  type: UPDATE_CONTAINER_TITLE,
  payload,
})

export const updateTodoTh =
  (id, data, containerId, status) => async (dispatch) => {
    try {
      dispatch(setTodoPendingStatus(true))
      const res = await request().edit(
        `${TODOS_API_URL}${id}/`,
        data,
        dispatch,
        "patch"
      )
      if (res.status === 200) {
        dispatch(updateToDoAc(res.data, containerId, status))
      }
    } catch (err) {
      throw err
    } finally {
      dispatch(setTodoPendingStatus(false))
    }
  }

export const updateTodoFilter = (payload) => ({
  type: SET_TODO_FILTER,
  payload,
})
