import React from "react"
import moment from "moment"
import { isEmpty } from "lodash"
import { generateId } from "../../common/helpers"
import { KeyDate } from "../keyDate"
import Text from "antd/es/typography/Text"
import styles from "../keyDate/style.module.css"

export const KeyDates = ({ lead, applied, follow_up, discarded }) => {


  const _containerStyle = React.useMemo(
    () => (
      {
        maxHeight: "68px",
        textAlign: "right",
        marginTop: "10px"
      }
    ),
    []
  )

  const dates = React.useMemo(
    () => [
      {
        id: generateId(),
        condition: !isEmpty(lead),
        date: lead.create_dt,
        title: "Added",
      },
      {
        id: generateId(),
        condition: !isEmpty(lead) && (lead.apply_dt || applied),
        date: lead.apply_dt ? lead.apply_dt : moment(),
        title: "Applied",
      },
      {
        id: generateId(),
        condition: !isEmpty(lead),
        date: lead.last_edit_dt,
        title: "Last edit",
      },
      {
        id: generateId(),
        condition: !isEmpty(lead) && (!isEmpty(lead.todos) || follow_up()),
        title: "Follow up",
        date:
          !isEmpty(lead.todos) &&
            (lead.todos[0]?.done_dt || lead.todos?.[0]?.due_dt)
            ? lead.todos[0]?.done_dt || lead.todos?.[0]?.due_dt
            : follow_up()
              ? moment().add(1, "week")
              : null,
      },
    ],
    [applied, follow_up, lead]
  )

  return (
    <>
      <div style={_containerStyle}>
        <KeyDate style={{ marginBottom: '5px' }} key={lead.title} title={`${(lead.title || "")} ${lead?.company?.name ? (" @ " + lead?.company?.name) : ""}`} />
        {dates
          .filter((item) => !!item.condition)
          .map((item, index) => (
            <KeyDate style={{ marginBottom: (index === dates.filter((item) => !!item.condition).length - 1) ? "0px" : "5px" }} key={item.id} title={item.title} date={item.date} />
          ))}
        {discarded && <Text className={styles.text}>Status: Discarded</Text>}
      </div>
    </>
  )
}
