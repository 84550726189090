import React, { useCallback, useLayoutEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Field, Form, reduxForm } from "redux-form"
import { Button, Typography, Col, Row } from "antd"
import { SocialLogin } from "../components/social_login"
import { Link } from "react-router-dom"
import Logo from "../assets/images/logo.svg"
import withMemo from "../store/HOC/with_memo"
import { URLS } from "../common/urls"
import { Alert } from "antd"

const { Title, Text } = Typography

const Register = withMemo(({ handleSubmit, errors = {}, full_error }) => {
  const params = useLocation().search
  const history = useHistory()


  useLayoutEffect(() => {
    const plan = new URLSearchParams(params).get("s_p")
    if (typeof plan === "string" && (plan === "0" || plan === "1" || plan === "2")) {
      document.cookie = `savvyy_plan=${plan};secure;path=/;expires=Thu, 17 Oct 2030 14:28:00 GMT`;
      history.replace({ search: "" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderError = useCallback(() => {
    if (!full_error) {
      return null
    }
    return (
      <Alert
        message={full_error}
        style={{ position: "absolute", top: "-48px", width: "422px" }}
        type="error"
      />
    )
  }, [full_error])
  return (
    <>
      <Row>
        <Col
          style={{
            marginTop: "32px",
            marginBottom: "134px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div>
            <a href="https://savvyy.com/">
              <img src={Logo} alt="" />
            </a>
          </div>
        </Col>
        <Col
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row style={{ display: "block", width: "422px" }}>
            {renderError()}
            <div
              style={{
                padding: "24px 32px",
                width: "422px",
                border: "1px solid #D9D9D9",
                borderRadius: "6px",
              }}
            >
              <Row>
                <div style={{ width: "100%" }}>
                  <Title
                    style={{ marginBottom: "32px", textAlign: "center" }}
                    level={3}
                  >
                    Sign Up
                  </Title>
                  <SocialLogin register={true} />
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "-10px",
                    }}
                  >
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "rgba(217, 217, 217, 0.65)",
                        marginRight: "14.5px",
                        width: "157.5px",
                        marginTop: "11px",
                      }}
                    ></div>
                    <div>or</div>
                    <div
                      style={{
                        height: "1px",
                        backgroundColor: "rgba(217, 217, 217, 0.65)",
                        marginLeft: "14.5px",
                        width: "157.5px",
                        marginTop: "11px",
                      }}
                    ></div>
                  </div>
                  <div className="authForm">
                    <Form onSubmit={handleSubmit}>
                      <Text style={{ display: "block", marginBottom: "8px" }}>
                        Email
                      </Text>
                      <Field
                        name="email"
                        component="input"
                        type="email"
                        id="email_form"
                        style={{
                          width: "100%",
                          height: "32px",
                          border: "1px solid #D9D9D9",
                          borderRadius: "2px",
                        }}
                      />
                      {errors.email && (
                        <Text
                          style={{
                            display: "block",
                            marginTop: "4px",
                            color: "#F25041",
                          }}
                        >
                          {errors.email}
                        </Text>
                      )}
                      <Text
                        style={{
                          display: "block",
                          marginBottom: "8px",
                          marginTop: "12px",
                        }}
                      >
                        Password
                      </Text>
                      <Field
                        name="password1"
                        component="input"
                        type="password"
                        id="form_password"
                        style={{
                          width: "100%",
                          height: "32px",
                          border: "1px solid #D9D9D9",
                          borderRadius: "2px",
                        }}
                      />
                      {errors.password1 && (
                        <Text
                          style={{
                            display: "block",
                            marginTop: "4px",
                            color: "#F25041",
                          }}
                        >
                          {errors.password1}
                        </Text>
                      )}
                      <Text
                        style={{
                          display: "block",
                          marginBottom: "8px",
                          marginTop: "12px",
                        }}
                      >
                        Confirm Password
                      </Text>
                      <Field
                        name="password2"
                        component="input"
                        type="password"
                        id="form_password2"
                        style={{
                          width: "100%",
                          height: "32px",
                          border: "1px solid #D9D9D9",
                          borderRadius: "2px",
                          outline: "unset",
                          padding: "0 8px",
                        }}
                      />
                      {errors.password2 && (
                        <Text
                          style={{
                            display: "block",
                            marginTop: "4px",
                            color: "#F25041",
                          }}
                        >
                          {errors.password2}
                        </Text>
                      )}
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        style={{
                          display: "block",
                          width: "100%",
                          marginBottom: "20px",
                          color: "#ffffff",
                          backgroundColor: "#009EEF",
                          borderRadius: "2px",
                          borderColor: "#009EEF",
                          marginTop: "24px",
                        }}
                      >
                        Sign Up
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          style={{
                            fontWeight: "500",
                            color: "#009EEF",
                            textDecoration: "underline",
                          }}
                          to={URLS.LOGIN}
                        >
                          Login
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  )
})

export default reduxForm({
  form: "register",
})(Register)
