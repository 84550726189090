import React, { useCallback } from "react"
import { Badge, Divider } from "antd"
import withMemo from "../store/HOC/with_memo"
import { renderTitleByType, sortByDate } from "../common/helpers"
import "../styles/search.css"
import { KeyDate } from "./keyDate"

const SearchResultItem = ({ title, item, openModal }) => {
  const renderTitle = useCallback((title) => {
    if (typeof title !== "string") return ""
    return title.charAt(0).toUpperCase() + title.slice(1)
  }, [])
  return (
    <>
      <div className={`default ${title} result_item`}>
        <div className="space-between d-flex align-center">
          <span style={{ fontWeight: "500" }}>{renderTitle(title)}</span>
          <Badge
            className={`result_badge ${title}_badge`}
            count={
              Array.isArray(item) && item.length > 0 ? item.length : "Not found"
            }
          />
        </div>
        <div
          style={{
            height: "2px",
            background: "red",
            position: "absolute",
          }}
        />
      </div>
      <div className="result-column">
        {sortByDate(item).map((val) => {
          return (
            <div
              key={val.id}
              className="search-column-item"
              onClick={() => openModal(title, val)}
            >
              <div
                className="search-column-item-x"
                style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  padding: "6px 0",
                }}
              >
                <span>
                  <div className="content-text">
                    {renderTitleByType(val, title).trim()}
                    {renderTitleByType().trim() && "-"}
                  </div>
                  <KeyDate title={"Added"} date={val.create_dt} />
                </span>
              </div>
              <Divider style={{ margin: "0" }} />
            </div>
          )
        })}
      </div>
    </>
  )
}
export default withMemo(SearchResultItem)
