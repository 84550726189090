import { useAction } from "./useAction"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import {
  getJobsSelector,
  getSpecificJob,
  getSpecificJobOwnTodo,
} from "../store/selectors"
import { isEmpty } from "lodash"
import { getJobs } from "../store/actions"

export const useLeads = () => {
  const leads = useSelector(getJobsSelector)
  const specificLead = useSelector(getSpecificJob)
  const specificLeadOwnTodo = useSelector(getSpecificJobOwnTodo)
  const getAll = useAction(getJobs)
  useEffect(() => {
    if (isEmpty(leads)) {
      getAll()
    }
    //eslint-disable-next-line
  }, [getAll])
  return {
    leads,
    specificLead,
    specificLeadOwnTodo,
  }
}
