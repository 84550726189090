import React, { Fragment } from "react"
import withMemo from "../../store/HOC/with_memo"
import { useSelector } from "react-redux"
import { getAntdModals } from "../../store/selectors/ui/get-ui"

export const RenderAntdModals = withMemo(() => {
  const antdModals = useSelector(getAntdModals)
  const minZIndex = 1002
  return (
    <Fragment>
      {antdModals.map(({ element: Modal, id, props }, i) => {
        return <Modal key={id} {...props} style={{ zIndex: minZIndex + i }} />
      })}
    </Fragment>
  )
})
