import React from "react"
import { Alert } from "antd"

export const VerifyEmailAlert = ({ onVerifyAcc }) => {
  return (
    <>
      <Alert
        message="Please verify your email address before proceeding"
        description={
          <div>
            <span>
              If you did not receive the verification email, please check your
              Spam folder or click
            </span>
            <span
              style={{ cursor: "pointer", color: "#009EEF" }}
              onClick={onVerifyAcc}
            >
              {" "}
              a link{" "}
            </span>
            <span>to resend</span>
          </div>
        }
        type="warning"
        showIcon
        style={{ marginTop: "-24px", marginBottom: "24px" }}
      />
    </>
  )
}
