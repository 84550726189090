import {
  EDIT_NOTE,
  EDIT_SPECIFIC_NOTE_WITH_LINKS,
  SET_NOTE_PENDING_STATUS,
} from "../../action-types/notes"
import { request } from "../../../api/axios_helper"
import { FeaturesTypes, NOTE_API_URL } from "../../../common/constants"
import { editNoteInCompany } from "../companies/edit-company"
import {
  getNotesSelector,
  getSpecificNote,
} from "../../selectors/notes/get_notes_selector"
import { SET_CURRENT_DIARY_DATE } from "../../action-types/diaries"
import { removeNote } from "./remove"
import { editSearchPage } from "../search/get-search"
import { CompanyNotesSelector } from "../../selectors"
import { isEmpty } from "lodash"
import { setCompanyNotes } from "../companies/get-companies"
import { updateDiaryActivity } from "../diaries/edit-diary"

export const editNotes = (payload) => {
  return {
    type: EDIT_NOTE,
    payload,
  }
}
export const setPendingStatus = (payload) => {
  return {
    type: SET_NOTE_PENDING_STATUS,
    payload,
  }
}

export const editSpecificNoteWithLinks = (payload, key, note) => ({
  type: EDIT_SPECIFIC_NOTE_WITH_LINKS,
  payload,
  key,
  note,
})

export const setCurrentDiaryDate = (payload) => {
  return {
    type: SET_CURRENT_DIARY_DATE,
    payload,
  }
}
export const editNote =
  (data, in_company, inDiary, isDragging) => async (dispatch, getState) => {
    try {
      dispatch(setPendingStatus(true))
      const notesList = getNotesSelector(getState())
      const company_notes = CompanyNotesSelector(getState())
      const note = getSpecificNote(getState())
      !data.company_id && delete data.company_id
      !data.workspace_id && delete data.workspace_id
      const url = `${NOTE_API_URL}${data.id}/`
      const response = await request().edit(url, data, dispatch, "patch")
      dispatch(editSearchPage(response.data, FeaturesTypes.NOTE))
      if (in_company) {
        dispatch(
          editNoteInCompany({
            ...response.data,
            links: note.links || [],
            atts: note.atts || [],
          })
        )
      } else if (!isEmpty(company_notes)) {
        dispatch(
          setCompanyNotes(
            company_notes.map((item) =>
              item.id === response.data.id ? response.data : item
            )
          )
        )
      }
      // if (inDiary) {
      dispatch(
        updateDiaryActivity({
          data: response.data,
          activity_key: FeaturesTypes.NOTE,
        })
      )
      // }

      if (isDragging) {
        const note = notesList?.find((item) => item.id === data.id)
        if (note.workspace_id !== data.workspace_id) {
          dispatch(removeNote(response.data.id))
        }
      }
      const { links, atts } = note
      dispatch(
        editNotes({
          ...response.data,
          links,
          atts,
        })
      )
      return response.data
    } catch (err) {
      throw err
    } finally {
      dispatch(setPendingStatus(false))
    }
  }
