import React, { useCallback } from "react"
import BannerDelete from "../../assets/images/dalete_company_svy.svg"
import {
  removeCompany,
  removeCompanyFromActivity,
} from "../../store/actions/companies/remove-company"
import { Button, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getSpecificCompany } from "../../store/selectors"
import { isEmpty } from "lodash"
import withMemo from "../../store/HOC/with_memo"
import { resetModals } from "../../store/actions/ui/update_ui"
import { setSpecificCompany } from "../../store/actions/companies/get-companies"

const DeleteCompany = ({ handleClose, visible }) => {
  const dispatch = useDispatch()
  const company = useSelector(getSpecificCompany)
  const handleRemove = useCallback(() => {
    if (isEmpty(company)) {
      return
    }
    handleClose()
    dispatch(setSpecificCompany({}))
    dispatch(removeCompanyFromActivity(company.id))
    dispatch(resetModals())
    dispatch(removeCompany(company.id))
  }, [company, dispatch, handleClose])
  return (
    <Modal
      className="delete-modal"
      open={visible}
      footer={null}
      onCancel={handleClose}
      closable={false}
    >
      <div className="delete-modal">
        <div className="delete-modal__content">
          <div className="delete-modal__img">
            <img src={BannerDelete} alt="delete" className="img-fluid" />
          </div>
          <div className="delete-modal__text">
            <span className="delete-modal__title">Delete Company?</span>
            <span className="delete-modal__subtitle">
              Are you sure you want to delete?
            </span>
          </div>
        </div>
        <div className="delete-modal__actions">
          <Button
            className="btn-style btn-cancel"
            type="primary"
            ghost
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="primary" danger onClick={handleRemove}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withMemo(DeleteCompany)
