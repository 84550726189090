export const SET_DIARIES_DATA = "SET_DIARIES_DATA"
export const SET_DIARIES_ACTIVITIES = "SET_DIARIES_ACTIVITIES"
export const SET_DIARIES_ACTIVITIES_CONFIG = "SET_DIARIES_ACTIVITIES_CONFIG"
export const SET_DIARIES_ACTIVITIES_DATE = "SET_DIARIES_ACTIVITIES_DATE"
export const SET_DIARIES_ACTIVITY_PENDING = "SET_DIARIES_ACTIVITY_PENDING"
export const SET_DIARIES_LOADING_PROGRESS = "SET_DIARIES_LOADING_PROGRESS"
export const SET_NEW_DIARY = "SET_NEW_DIARY"
export const SET_UPDATED_DIARY = "SET_UPDATED_DIARY"
export const REMOVE_DIARY_SUCCESS = "REMOVE_DIARY_SUCCESS"
export const RESET_ACTIVITIES = "RESET_ACTIVITIES"
export const UPDATE_DIARY_ACTIVITY = "UPDATE_DIARY_ACTIVITY"
export const SET_CURRENT_DIARY_DATE = "SET_CURRENT_DIARY_DATE"
export const EDIT_NOTE_IN_DIARY = "EDIT_NOTE_IN_DIARY"
export const SET_TODAY_DIARY_PENDING = "SET_TODAY_DIARY_PENDING"
export const REMOVE_ACTIVITY_BY_KEY = "REMOVE_ACTIVITY_BY_KEY"
