import React from "react"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"
import { Typography } from "antd"
import { useNoteItem } from "../../hooks/useNoteItem"
import { TagsList } from "../tags/TagsList"
import {
  EditOutlined,
  LinkOutlined,
  PaperClipOutlined,
} from "@ant-design/icons"
import { LinksList } from "../docs/links-list"
import { FeaturesTypes } from "../../common/constants"
import DeleteButtonWithModal from "../../common/components/DeleteButtonWithModal"
import { KeyDate } from "../keyDate"

const { Text } = Typography

export const HorizontalNoteItem = withShouldUpdate(
  ({ note, openEditor, allColors, disableDrag, cantDelete, isDrawer, isModal, isCompanyModal }) => {
    const {
      atts_count,
      links_count,
      created_date,
      handleDelete,
      handleClickOnNote,
      content,
      drag,
    } = useNoteItem(note, openEditor, disableDrag)
    return (
      <div
        onClick={handleClickOnNote}
        role="note"
        ref={drag}
        className={`docs-note-item ${content ? "" : "cursor-pointer"}`}
        data-testid={`${FeaturesTypes.NOTE}-${note.id}`}
        style={{ position: "relative", ...(isCompanyModal ? { width: "545px" } : {}) }}
      >
        <div className="notes-items" >
          {content && (

            <span
              className={`note-title ${links_count === 0 && atts_count === 0 ? "title-full-width" : ""
                }`}
            >

              {content}
              {note?.comm_dt
                &&
                <span
                  style={{
                    position: "absolute",
                    bottom: "4px",
                    right: '3px',
                  }}>
                  <KeyDate date={note.comm_dt} />
                </span>
              }
              {isModal && !note?.comm_dt && note?.create_dt
                &&
                <span
                  style={{
                    position: "absolute",
                    bottom: "2px",
                    right: '3px',
                  }}>
                  <KeyDate title={"Created"} date={note.create_dt} />
                </span>
              }
            </span>
          )}
          {links_count > 0 && (
            <>
              <div
                className="notes-items__links"
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflowX: "scroll",
                  marginRight: "11px",
                }}
              >
                <LinkOutlined
                  style={{
                    marginRight: "8px",
                    color: "#8C8C8C",
                    fontSize: "16px",
                  }}
                />
                <LinksList items={note.links || []} count={links_count} />
              </div>
            </>
          )}
          {atts_count > 0 && (
            <>
              <div
                className="notes-items__atts"
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflowX: "scroll",
                  marginRight: "11px",
                }}
              >
                <PaperClipOutlined
                  style={{
                    marginRight: "7px",
                    color: "#8C8C8C",
                    fontSize: "16px",
                  }}
                />
                <LinksList
                  items={note.atts || []}
                  count={atts_count}
                  is_attachment
                />
              </div>
            </>
          )}
          {note?.tags?.length > 0 && (
            <>
              <div
                className="docs-notes-tags"
                style={{
                  whiteSpace: "nowrap",
                  overflowX: "scroll",
                }}
              >
                <TagsList tags={note.tags} allColors={allColors} />
              </div>
            </>
          )}
          {isDrawer && (
            <Text
              style={{
                margin: "0 16px 0 0",
                color: "rgba(0, 0, 0, 0.6)",
                whiteSpace: "nowrap",
                marginLeft: isCompanyModal ? "auto" : 0,
              }}
            >
              {created_date}
            </Text>
          )}

          {isDrawer && (
            <EditOutlined
              style={{ marginRight: "12px" }}
              onClick={handleClickOnNote}
            />
          )}

          {!cantDelete && isDrawer && (
            <DeleteButtonWithModal
              type={FeaturesTypes.NOTE}
              data={note}
              handleRemove={handleDelete}
            />
          )}
        </div>
      </div>
    )
  }
)
