import React from "react"
import ColorTagItem from "./ColorTagItem"
import { Button, Space } from "antd"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"

export const TagsList = withShouldUpdate(
  ({
    allColors,
    tags,
    restStyles,
    handleClickOnTag,
    showClearButton = false,
    resetFilters,
    closable = true,
  }) => {
    return (
      <div
        className="noteTags"
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          scrollbarWidth: "none",
          ...restStyles,
        }}
      >
        {showClearButton && (
          <Button
            type="primary"
            style={{ marginBottom: "1rem" }}
            onClick={resetFilters}
          >
            Clear
          </Button>
        )}
        <Space style={restStyles}>
          {[...(tags || [])].map((item) => {
            return (
              <div key={item.tag}>
                <ColorTagItem
                  item={item}
                  handleClickOnTag={handleClickOnTag}
                  closable={closable}
                  allColors={allColors}
                />
              </div>
            )
          })}
        </Space>
      </div>
    )
  }
)
