import React from "react"
import { Button, Modal, Space } from "antd"

export const DiscardChanges = ({
  confirmModal,
  handleCancelConfirmModal,
  onSubmitConfirmModal,
}) => {
  return (
    <Modal
      header={null}
      footer={null}
      open={confirmModal}
      onCancel={handleCancelConfirmModal}
    >
      <div>Are you sure to discard your changes ?</div>
      <div className="d-flex" style={{ marginTop: "1rem" }}>
        <Space>
          <Button type={"primary"} onClick={onSubmitConfirmModal}>
            Yes
          </Button>
          <Button type={"primary"} danger onClick={handleCancelConfirmModal}>
            Cancel
          </Button>
        </Space>
      </div>
    </Modal>
  )
}
