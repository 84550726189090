import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { isEmpty, isEqual, debounce } from "lodash"
import { DATE_FORMAT } from "../common/config"
import {
  CompaniesJobsPendingStatusSelector,
  CompaniesJobsSelector,
  getCompaniesSelector,
  getCompanyContactsSelector,
  getContactsPendingStatus,
  getSortedJobs,
  getSpecificCompany,
  getUserSubscriptionTypeSelector,
} from "../store/selectors"
import { editCompany } from "../store/actions/companies/edit-company"
import { getAllTags } from "../store/actions/search/get-tags"
import { getDraftTagsCompany } from "../store/selectors/companies/get-companies-selector"
import { getAllTagsSelector } from "../store/selectors/tags/get-tag-pending-status"
import { editNote } from "../store/actions/notes/edit_note"
import {
  getCompanyContacts,
  getCompanyJobs,
  setCompanyContacts,
  setCompanyNotes,
  setSpecificCompany,
} from "../store/actions/companies/get-companies"
import { SET_TAGS_QUERY } from "../store/action-types/tags"
import {
  addCompany,
  setDraftTagsCompany,
} from "../store/actions/companies/add-company"
import { handleCompanyQueryChange } from "../store/actions/contacts/search-company"
import { getCompanyResults } from "../store/selectors/companies/get-companies-selector"
import { removeNotesIfCompanyNotCreated } from "../store/actions/companies/remove-company"
import { deleteContactTH } from "../store/actions/contacts/delete-contact"
import DeleteButtonWithModal from "../common/components/DeleteButtonWithModal"
import { FeaturesTypes } from "../common/constants"
import { TagsList } from "../components/tags/TagsList"
import { useColors } from "./useColors"
import { setDraftTagsContact } from "../store/actions/contacts/create-contact"
import { useActivityItem } from "./useActivityItem"
import { useAction } from "./useAction"
import { updateModalProps } from "../store/actions/ui/update_ui"
import { getIsUILoading } from "../store/selectors/ui/get-ui"
import { getNotesByParams } from "../store/actions/notes/get_notes"

export const useCompanyModal = ({ isAdd: isAddOriginal, onClose }) => {
  const { allColors } = useColors()
  const [isAdd, setIsAdd] = useState(isAddOriginal)
  const [tab, setTab] = useState("1")
  const sybType = useSelector(getUserSubscriptionTypeSelector)
  const contacts = useSelector(getCompanyContactsSelector)
  const contacts_pending = useSelector(getContactsPendingStatus)
  const companies = useSelector(getCompaniesSelector)
  const companiesNames = useSelector(getCompanyResults)
  const isSearchCompanyLoading = useSelector(getIsUILoading)
  const [confirmModal, setConfirmModal] = useState(false)
  const inputRef = useRef()
  const companyNameRef = useRef()
  const leads = useSelector(getSortedJobs)
  const companyModalRef = useRef()
  const [addTagStep, setAddTagStep] = useState(0)
  const allTags = useSelector(getAllTagsSelector)
  const specificCompany = useSelector(getSpecificCompany)
  const jobs_is_pending = useSelector(CompaniesJobsPendingStatusSelector)
  const company_jobs = useSelector(CompaniesJobsSelector)
  const [data, setData] = useState({
    ...specificCompany,
  })
  const [showError, setShowError] = useState(false)
  const [locations, setLocations] = useState([])
  const dispatch = useDispatch()
  const currentTags = useSelector(getDraftTagsCompany)

  const createCompanyAction = useAction(addCompany)
  const editCompanyAction = useAction(editCompany)
  const actionSetSpecificCompany = useAction(setSpecificCompany)
  const updateModalPropsAction = useAction(updateModalProps)
  const handleSearchNames = useAction(handleCompanyQueryChange)
  const getNotesAction = useAction(getNotesByParams)
  const getContactsAction = useAction(getCompanyContacts)
  const getJobsAction = useAction(getCompanyJobs)

  const { handleClick } = useActivityItem()

  const handleChangeCompanyLocation = useCallback(
    (city) => {
      if (!confirmModal) {
        setData({ ...data, city })
      }
    },
    [confirmModal, data]
  )

  const setInputValue = useCallback(
    (payload) => {
      dispatch({ type: SET_TAGS_QUERY, payload })
    },
    [dispatch]
  )

  const handleClickSearchCompany = useCallback(
    async (name) => {
      const selectedCompany = companies.find((c) => c.name === name)

      if (selectedCompany) {
        await actionSetSpecificCompany(selectedCompany)
        await updateModalPropsAction({
          isAdd: false,
          isEdit: true,
          status: "edit",
          type: FeaturesTypes.COMPANY,
        })
        setIsAdd(false)
        await getContactsAction(selectedCompany.id)
        await getNotesAction(selectedCompany.id)
        await getJobsAction(selectedCompany.id)
      }
    },
    [
      actionSetSpecificCompany,
      companies,
      getContactsAction,
      getJobsAction,
      getNotesAction,
      updateModalPropsAction,
    ]
  )

  const handleSearchLocation = useCallback(
    (val) => {
      const filtered = [...(companies || [])]
        .map((item) => item.city)
        .filter((item) => {
          return (
            String(item)
              .toLowerCase()
              .trim()
              .includes(String(val).toLowerCase().trim()) && item !== "null"
          )
        })
        .sort()
        .filter((item, pos, ary) => {
          return (
            (!pos || item?.toLowerCase() !== ary[pos - 1]?.toLowerCase()) &&
            item?.trim()
          )
        })
      setLocations(filtered)
    },
    [companies]
  )
  const contactsColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "name",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },
    {
      title: "Date Added",
      key: "create_dt",
      dataIndex: "create_dt",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
    },
    {
      title: "Last Contact",
      dataIndex: "last_contact",
      key: "last_contact",
      render: (date) => {
        return date ? <span>{moment(date).format(DATE_FORMAT)}</span> : null
      },
    },
    {
      title: "Actions",
      render: (_, row) => {
        const handleRemoveContact = (e) => {
          e.stopPropagation()
          dispatch(deleteContactTH(row.id, row))
        }
        return (
          <DeleteButtonWithModal
            handleRemove={handleRemoveContact}
            type={FeaturesTypes.CONTACT}
            data={{ title: row.first_name }}
          />
        )
      },
    },
  ]
  const handleClose = useCallback(
    (e) => {
      if (e.keyCode === 27 && addTagStep > 0) {
        e.stopPropagation()
        e.preventDefault()
        setInputValue("")
        setAddTagStep((prev) => prev - 1)
        return
      }
      if (!isAdd && !isEqual(specificCompany, data)) {
        setConfirmModal(true)
      } else {
        if (isAdd) {
          dispatch(removeNotesIfCompanyNotCreated())
        }
        onClose()
      }
    },
    [addTagStep, data, dispatch, isAdd, onClose, setInputValue, specificCompany]
  )

  const handleChangeNote = useCallback(
    (note, data) => {
      const _data = note?.id ? note : data
      dispatch(editNote(_data, true))
    },
    [dispatch]
  )
  useEffect(() => {
    if (specificCompany.id) {
      dispatch(getCompanyContacts(specificCompany.id))
    }
    if (isEmpty(leads) && specificCompany.id) {
      dispatch(getCompanyJobs(specificCompany.id))
    }

    //eslint-disable-next-line
  }, [])

  const handleSave = useCallback(async () => {
    const editData = {
      ...data,
      tags: currentTags,
    }
    if (isAdd) {
      const newCompany = await createCompanyAction(editData)
      await actionSetSpecificCompany(newCompany)
      setIsAdd(false)
      updateModalPropsAction({
        isAdd: false,
        status: "edit",
        isEdit: true,
        type: FeaturesTypes.COMPANY,
      })
      return newCompany
    }
    if (specificCompany.id) {
    }

    delete editData.tags
    return await editCompanyAction({ ...editData, id: specificCompany.id })
  }, [
    actionSetSpecificCompany,
    createCompanyAction,
    currentTags,
    data,
    editCompanyAction,
    isAdd,
    specificCompany.id,
    updateModalPropsAction,
  ])
  const handleSubmit = useCallback(() => {
    if (data.name.trim()) {
      handleSave().then(() => onClose())
    } else {
      setShowError(true)
      setTimeout(() => companyNameRef.current?.focus(), 100)
      return
    }
  }, [handleSave, data, onClose])
  const handleOpenContactModal = useCallback(() => {
    if (data.name) {
      dispatch(setDraftTagsContact([]))
      handleClick(
        { id: "newContact" },
        FeaturesTypes.CONTACT,
        { company: { name: data.name } },
        {
          status: "add",
          isEdit: false,
          isAdd: true,
          is_communication_edit: false,
          defaultActiveKey: "1",
          saveCompany: handleSave,
        }
      )
    } else {
      setShowError(true)
      setTimeout(() => companyNameRef.current?.focus(), 100)
      return
    }
  }, [data.name, dispatch, handleClick, handleSave])

  const handleOpenEditContactModal = useCallback(
    (record) => {
      if (data.name) {
        dispatch(setDraftTagsContact([]))
        handleClick(record, FeaturesTypes.CONTACT, record, {
          status: "edit",
          isEdit: true,
          isAdd: false,
          is_communication_edit: false,
          defaultActiveKey: "2",
        })
      } else {
        setShowError(true)
        setTimeout(() => companyNameRef.current?.focus(), 100)
        return
      }
    },
    [data.name, dispatch, handleClick]
  )
  const handleChangeTab = (val) => {
    setTab(val)
  }

  const handleAddNewNote = useCallback(() => {
    if (!data.name) {
      handleChangeTab("1")
      setShowError(true)
      setTimeout(() => companyNameRef.current?.focus(), 100)
      return
    }
    handleClick(
      { id: "newNote" },
      FeaturesTypes.NOTE,
      { links: [], atts: [] },
      {
        status: "add",
        isEdit: false,
        isAdd: true,
        is_communication_edit: false,
        saveCompany: handleSave,
      }
    )
  }, [data.name, handleClick, handleSave])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHandleSearch = useCallback(debounce(handleSearchNames, 700), [])
  useEffect(() => {
    return () => {
      dispatch(setCompanyContacts([]))
      dispatch(setDraftTagsCompany([]))
      dispatch(setSpecificCompany({}))
      dispatch(setCompanyNotes([]))
    }
  }, [dispatch])
  useEffect(() => {
    if (tab === 2 && specificCompany.id) {
      dispatch(getCompanyJobs(specificCompany.id))
    }
  }, [tab, dispatch, specificCompany?.id])
  useEffect(() => {
    if (!isEmpty(specificCompany) && data?.id !== specificCompany?.id) {
      setData(specificCompany)
    }
    if (isEmpty(allTags)) {
      dispatch(getAllTags())
    }
    //eslint-disable-next-line
  }, [specificCompany])
  useEffect(() => {
    !data?.name && companyNameRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    locations,
    setInputValue,
    setAddTagStep,
    handleChangeCompanyLocation,
    handleSearchLocation,
    specificCompany,
    handleClose,
    contacts_pending,
    handleOpenContactModal,
    handleSubmit,
    handleSearchNames: onHandleSearch,
    data,
    confirmModal,
    setData,
    companiesNames,
    inputRef,
    addTagStep,
    contactsColumns,
    contacts,
    handleAddNewNote,
    setConfirmModal,
    handleChangeNote,
    jobs_is_pending,
    company_jobs,
    companyModalRef,
    handleChangeTab,
    leads,
    showError,
    setShowError,
    companyNameRef,
    tab,
    handleClick,
    handleOpenEditContactModal,
    handleClickSearchCompany,
    sybType,
    isSearchCompanyLoading,
  }
}
