import { createSelector } from "reselect"

const LinksSelector = (state) => state.links

const getLinksPendingStatus = createSelector(
  LinksSelector,
  (links_state) => links_state.isPending
)

const getSpecificLink = createSelector(
  LinksSelector,
  (state) => state.specificLink
)
export { getSpecificLink, LinksSelector, getLinksPendingStatus }
