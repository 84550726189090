import { createSelector } from "reselect"

export const DiariesSelector = (state) => state.diaries
export const getDiariesSelector = createSelector(DiariesSelector, (value) => {
  return value.diaries
})

export const getDiariesPendingStatus = createSelector(
  DiariesSelector,
  (state) => {
    return state.isPending
  }
)

export const getTodayPending = createSelector(
  DiariesSelector,
  (state) => state.todayDiaryPending
)
