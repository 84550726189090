import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getNotePendingStatus } from "../store/selectors"
import { useCallback, useState } from "react"
import { getSpecificNote } from "../store/selectors/notes/get_notes_selector"
import { createNote } from "../store/actions/notes/create_note"
import { deleteNote } from "../store/actions/notes/remove"
import { editNote } from "../store/actions/notes/edit_note"
import { setCurrentTags } from "../store/actions/tags/add-tag"
import { useAction } from "./useAction"
import { useActivityItem } from "./useActivityItem"
import { FeaturesTypes } from "../common/constants"

export const useNotes = () => {
  const isPending = useSelector(getNotePendingStatus)
  const [showEditor, setShowEditor] = useState(false)
  const specificNote = useSelector(getSpecificNote)
  const createNoteAction = useAction(createNote)
  // const setNoteAction = useAction(setSpecificNote)
  const removeNoteAction = useAction(deleteNote)
  const editNoteAction = useAction(editNote)
  const setTagsAction = useAction(setCurrentTags)
  const { handleClick } = useActivityItem()
  const { workspace_id } = useParams()
  const openEditor = useCallback(
    (note) => {
      setShowEditor(true)
      if (note) {
        // setNoteAction(note)
        handleClick(note, FeaturesTypes.NOTE, note)
      }
    },
    [handleClick]
  )
  const onCloseEditor = useCallback(() => {
    setShowEditor(false)
    setTagsAction([])
  }, [setTagsAction])
  const handleAddNote = useCallback(
    (data) => {
      const _data = { ...data }
      !_data.company_id && delete _data.company_id
      if (!Number(_data.workspace_id)) {
        delete _data.workspace_id
      }
      createNoteAction(_data)
      onCloseEditor()
    },
    [createNoteAction, onCloseEditor]
  )
  const handleDeleteNote = useCallback(() => {
    removeNoteAction(specificNote.id)
    onCloseEditor()
  }, [onCloseEditor, removeNoteAction, specificNote.id])

  const handleAddNew = useCallback(() => {
    const data =
      Number(workspace_id) === 0 || !workspace_id ? {} : { workspace_id }
    handleClick({ id: "newNote" }, FeaturesTypes.NOTE, data)
  }, [handleClick, workspace_id])
  const isLoading = useCallback(() => {
    return isPending !== null && isPending
  }, [isPending])
  const handleEditNote = useCallback(
    (isInline, data) => {
      const val = { ...data }
      !val.company_id && delete val.company_id
      if (!val.workspace_id) {
        delete val.workspace_id
      }
      editNoteAction(val)
      if (!isInline) {
        onCloseEditor()
      }
    },
    [editNoteAction, onCloseEditor]
  )
  return {
    handleAddNew,
    isLoading,
    openEditor,
    showEditor,
    onCloseEditor,
    handleEditNote,
    handleDeleteNote,
    handleAddNote,
  }
}
