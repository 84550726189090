import { WorkspaceService } from "../../services/workspaceService"
import {
  SET_WORKSPACE_LIST,
  SET_WORKSPACE_PENDING_STATUS,
} from "../../action-types/workspaces"
import { setNotes, setNotesPendingStatus } from "../notes/get_notes"

export const setWorkspacePendingStatus = (status) => {
  return {
    type: SET_WORKSPACE_PENDING_STATUS,
    status,
  }
}

export const setWorkspaceList = (payload) => {
  return {
    type: SET_WORKSPACE_LIST,
    payload,
  }
}
export const getWorkspaceList = () => (dispatch) => {
  dispatch(setWorkspacePendingStatus(true))
  WorkspaceService.getWorkspaces()
    .then((res) => dispatch(setWorkspaceList(res)))
    .finally(() => dispatch(setWorkspacePendingStatus(false)))
}

export const getWorkspaceNotes = (workspace_id) => (dispatch) => {
  dispatch(setNotesPendingStatus(true))
  WorkspaceService.getWorkspaceNotes(workspace_id)
    .then((response) => dispatch(setNotes(response)))
    .finally(() => dispatch(setNotesPendingStatus(false)))
}
