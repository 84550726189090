export const GET_ALL_FILES = "GET_ALL_FILES"
export const SET_FILES_PENDING_STATUS = "SET_FILES_PENDING_STATUS"
export const ON_CHANGE_UPLOAD_PROGRESS = "ON_CHANGE_UPLOAD_PROGRESS"
export const ON_UPLOAD_ERROR = "ON_UPLOAD_ERROR"
export const SET_NEW_FILE = "SET_NEW_FILE"
export const REMOVE_FILE = "REMOVE_FILE"
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS"
export const SET_FILE_TYPE = "SET_FILE_TYPE"
export const SET_FILTERED_ATTACHMENTS = "SET_FILTERED_ATTACHMENTS"
export const SET_EXACT_FILE_PENDING_STATUS = "SET_EXACT_FILE_PENDING_STATUS"
