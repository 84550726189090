import { HANDLE_COMPANY_SEARCH } from "../../action-types/companies"
import { setErrorMessage, setErrorState } from "../errors/set_errors"
import { COMPANY_SEARCH_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import { isLoadingUI } from "../ui/update_ui"
import { getIsUILoading } from "../../selectors/ui/get-ui"

export const searchCompanySuccess = (payload) => {
  return {
    type: HANDLE_COMPANY_SEARCH,
    payload,
  }
}
export const handleCompanyQueryChange =
  (query) => async (dispatch, getState) => {
    if (!query?.trim()) {
      dispatch(searchCompanySuccess([]))
      return
    }
    if (query.length < 2) {
      return
    }
    const isLoading = getIsUILoading(getState())
    if (isLoading) return
    dispatch(isLoadingUI(true))
    try {
      const response = await request().get(
        `${COMPANY_SEARCH_API_URL}?name=${query}`
      )
      dispatch(searchCompanySuccess(response.data))
    } catch (err) {
      dispatch(setErrorMessage(err.message))
      dispatch(setErrorState(true))
    } finally {
      dispatch(isLoadingUI(false))
    }
  }
