import React from "react"
import { DeleteOutlined } from "@ant-design/icons"
import withConfirmModal from "../../store/HOC/withConfirmModal"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"

const DeleteButtonWithModal = ({ handleDelete }) => {
  return (
    <DeleteOutlined
      component={DeleteOutlined}
      style={{ cursor: "pointer", color: "#F25041" }}
      onClick={handleDelete}
    />
  )
}

export default withConfirmModal(withShouldUpdate(DeleteButtonWithModal))
