export const FETCH_NOTES = "FETCH_NOTES"
export const CREATE_NOTE = "CREATE_NOTE"
export const EDIT_NOTE = "EDIT_NOTE"
export const SET_SPECIFIC_NOTE = "SET_SPECIFIC_NOTE"
export const SET_NOTE_PENDING_STATUS = "SET_NOTE_PENDING_STATUS"
export const REMOVE_NOTE = "REMOVE_NOTE"
export const SET_LINKS = "SET_LINKS"
export const SET_LINKS_LOADING_STATUS = "SET_LINKS_LOADING_STATUS"
export const REMOVE_LINK = "REMOVE_LINK"
export const EDIT_SPECIFIC_NOTE_WITH_LINKS = "EDIT_SPECIFIC_NOTE_WITH_LINKS"
export const REMOVE_LINK_FROM_SPECIFIC_NOTE = "REMOVE_LINK_FROM_SPECIFIC_NOTE"
export const SET_NEW_NOTE_IN_COMPANY = "SET_NEW_NOTE_IN_COMPANY"
export const FILTER_COMPANY_NOTES = "FILTER_COMPANY_NOTES"
export const SET_DRAFT_TAGS_NOTE = "SET_DRAFT_TAGS_NOTE"
