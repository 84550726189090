import { useCallback, useMemo } from "react"
import { api as config } from "../common/config"
import { uploadFile } from "../store/actions/file_manager/upload_file"
import { useAction } from "./useAction"
import {
  setErrorMessage,
  setErrorState,
} from "../store/actions/errors/set_errors"
import { removeExtension } from "../common/helpers"

export const useUploadFile = (file_type = "") => {
  const errorMessage = useMemo(
    () => `Upload file too large, maximum size ${config.file_max_size} Mb`,
    []
  )
  const uploadFileAction = useAction(uploadFile)
  const setErrorStateAc = useAction(setErrorState)
  const setErrorMessageAc = useAction(setErrorMessage)
  const fileIsLarge = (file) => {
    return parseInt(file.size / (1000 * 1000)) > config.file_max_size
  }
  const onUpload = useCallback(
    async (data, title, additional) => {
      const _file = data?.target?.files ? data.target.files[0] : data
      if (fileIsLarge(_file)) {
        setErrorStateAc(true)
        setErrorMessageAc(errorMessage)
        return
      }
      const formData = new FormData()
      formData.append("usr_file", _file)
      formData.append("usr_type", file_type)
      formData.append("file_type", "U")
      formData.append("title", title ? title : removeExtension(_file.name))
      formData.append("tags", [])
      if (additional) {
        formData.append(additional.key, additional.value)
      }
      return uploadFileAction(formData, additional)
    },
    [
      errorMessage,
      file_type,
      setErrorMessageAc,
      setErrorStateAc,
      uploadFileAction,
    ]
  )
  const uploadFileProps = {
    showUploadList: false,
    name: "file",
    customRequest: ({ file }) => {
      if (fileIsLarge(file)) {
        setErrorStateAc(errorMessage)
        setErrorMessageAc(true)
        return
      }
      onUpload(file)
    },
  }

  return {
    uploadFileProps,
    onUpload,
  }
}
