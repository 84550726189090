import {
  REMOVE_DRAFT_TODO,
  RESET_DRAFT_TODO_LIST,
  UPDATE_DRAFT_TODO,
  UPDATE_DRAFT_TODO_LIST,
} from "../../action-types/todos"

export const updateDraftTodoList = (payload) => ({
  type: UPDATE_DRAFT_TODO_LIST,
  payload,
})

export const updateDraftTodo = (payload) => ({
  type: UPDATE_DRAFT_TODO,
  payload,
})

export const removeDraftTodo = (payload) => ({
  type: REMOVE_DRAFT_TODO,
  payload,
})

export const resetDraftTodoList = () => ({ type: RESET_DRAFT_TODO_LIST })
