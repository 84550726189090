import React, { useCallback } from "react"
import { Card } from "antd"
import { setSpecificNote } from "../../store/actions/notes/create_note"

export const NoteItem = ({ note, handleOpenEditor, dispatch }) => {
  const openEditor = useCallback(() => {
    dispatch(setSpecificNote(note))
    handleOpenEditor()
  }, [dispatch, handleOpenEditor, note])
  // const getContent = useCallback(() => {
  //     if (_.isEmpty(note.body_editor)) {
  //         return note.body_html || note.body_txt
  //     }
  //     const tempCont = document.createElement("div");
  //     (new Quill(tempCont)).setContents(note.body_editor)
  //     return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
  // }, [note.body_txt, note.body_editor, note.body_html])
  return (
    <Card
      hoverable
      style={{
        width: 200,
        maxHeight: "200px",
        margin: "13px",
        overflow: "hidden scroll",
      }}
      onClick={openEditor}
      key={note.id}
    >
      <h2>{note.title}</h2>
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        <div>{note.body_txt} </div>
      </div>
    </Card>
  )
}
