import { createSelector } from "reselect"
import { formValueSelector } from "redux-form"

const getUser = (state) => state.user

export const getLoginStatus = createSelector(getUser, (user) => {
  return user.loginIsChosen
})

export const getUserIsAuthenticated = createSelector(
  getUser,
  (user_state) => user_state.isAuth
)
export const getAuthErrors = createSelector(
  getUser,
  (user_state) => user_state.errors
)
export const getEmailIsConfirmed = createSelector(
  getUser,
  (user_state) => user_state.email_confirmed
)

const selector = formValueSelector("login", "email")
export const getUserFromForm = createSelector(selector, (form) => form)
export const getLoginBadRequestError = createSelector(getUser, (user_state) => {
  return user_state.full_error
})
