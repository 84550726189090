import { EDIT_WORKSPACE } from "../../action-types/workspaces"
import { setWorkspaceList, setWorkspacePendingStatus } from "./getWorkspaces"
import { WorkspaceService } from "../../services/workspaceService"
import { dragItem } from "../../../common/helpers"
import { selectWorkspaceList } from "../../selectors"

export const editWorkspace = (payload) => {
  return {
    type: EDIT_WORKSPACE,
    payload,
  }
}

export const editWorkspaceThunk = (data) => (dispatch) => {
  dispatch(setWorkspacePendingStatus(true))
  WorkspaceService.editWorkspace(data)
    .then((res) => dispatch(editWorkspace(data)))
    .finally(() => dispatch(setWorkspacePendingStatus(false)))
}

export const dragWorkspace = (result) => (dispatch, getState) => {
  const workspaces = selectWorkspaceList(getState())
  const { source, destination } = result
  const newItems = dragItem(workspaces, source.index, destination.index)
  dispatch(setWorkspaceList(newItems))
  const items = [...(newItems || [])]
  items.forEach((item, idx) => {
    dispatch(
      editWorkspaceThunk({
        ...item,
        drag_index: idx,
      })
    )
  })
}
