import { useCallback, useRef, useState } from "react"
import { useAction } from "./useAction"
import { createNewLink } from "../store/actions/links/create-link"
import { useSelector } from "react-redux"
import { getCurrentTags } from "../store/selectors/tags/get-tags-selector"
import { getSpecificLink } from "../store/selectors"
import { editLinkTH } from "../store/actions/links/edit-link"

export const useAddBookmark = (props) => {
  const specificLink = useSelector(getSpecificLink)
  const [url, setUrl] = useState(specificLink.url || "")
  const [caption, setCaption] = useState(specificLink.caption || "")
  const [addTagStep, setAddTagStep] = useState(0)
  const inputRef = useRef()
  const currentTags = useSelector(getCurrentTags)
  const addLinkAction = useAction(createNewLink)
  const updateAction = useAction(editLinkTH)
  const handleChangeUrl = useCallback((e) => {
    const val = e.target.value
    setUrl(val)
  }, [])
  const handleChangeCaption = useCallback((e) => {
    const val = e.target.value
    setCaption(val)
  }, [])
  const handleSubmit = useCallback(() => {
    if (url === specificLink.url && caption === specificLink.caption) {
      props.onClose()
      return
    }
    if (specificLink.id) {
      updateAction({ caption, url, id: specificLink.id })
    } else {
      addLinkAction({ caption, url, tags: currentTags })
    }
    props.onClose()
  }, [
    addLinkAction,
    caption,
    currentTags,
    props,
    specificLink.caption,
    specificLink.id,
    specificLink.url,
    updateAction,
    url,
  ])
  return {
    addTagStep,
    setAddTagStep,
    inputRef,
    url,
    handleSubmit,
    caption,
    handleChangeUrl,
    handleChangeCaption,
    specificLink,
  }
}
