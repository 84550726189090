import { SET_ALL_COLORS } from "../../action-types/colors"
import { request } from "../../../api/axios_helper"
import { COLORS_API_URL } from "../../../common/constants"

export const setAllColors = (payload) => {
  return {
    type: SET_ALL_COLORS,
    payload,
  }
}
export const getAllColors = () => async (dispatch) => {
  try {
    const response = await request().get(COLORS_API_URL, dispatch)
    dispatch(setAllColors(response.data))
  } catch (err) {}
}
