import {
  GET_ALL_FILES,
  SET_FILE_TYPE,
  SET_FILES_PENDING_STATUS,
  SET_FILTERED_ATTACHMENTS,
} from "../../action-types/file_manager"
import { request } from "../../../api/axios_helper"
import { FILES_API_URL } from "../../../common/constants"

const setFilesList = (payload) => {
  return {
    type: GET_ALL_FILES,
    payload,
  }
}
export const setFilesPendingStatus = (status) => {
  return {
    type: SET_FILES_PENDING_STATUS,
    payload: status,
  }
}

export const setFileType = (payload) => {
  return {
    type: SET_FILE_TYPE,
    payload,
  }
}
export const getFilesList = () => async (dispatch) => {
  try {
    dispatch(setFilesPendingStatus(true))
    const response = await request().get(FILES_API_URL, dispatch)
    dispatch(setFilesList(response.data))
    dispatch(setFilesPendingStatus(false))
  } catch (err) {}
}

export const setFilteredAttachments = (payload) => {
  return {
    type: SET_FILTERED_ATTACHMENTS,
    payload,
  }
}
export const getFilesByParams = (params) => async (dispatch) => {
  dispatch(setFilesPendingStatus(true))
  await request().get(`${FILES_API_URL}${params}`, dispatch, (res) => {
    dispatch(setFilteredAttachments(res.data))
  })
  dispatch(setFilesPendingStatus(false))
}
