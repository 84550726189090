import React, { useCallback } from "react"
import { LinkItem } from "../notes/link_item"
import withMemo from "../../store/HOC/with_memo"
import { downloadFile } from "../../store/actions/file_manager/download-file"
import { useAction } from "../../hooks/useAction"
import { removeExtension } from "../../common/helpers"

export const LinksList = withMemo(({ items, count, is_attachment }) => {
  const downloadAction = useAction(downloadFile)
  const handleDownloadFile = useCallback(
    (file) => {
      downloadAction(file)
    },
    [downloadAction]
  )
  const renderItem = useCallback(
    (item) => {
      if (is_attachment) {
        const title = item.title || removeExtension(item.orig_name)
        return (
          <LinkItem callback={handleDownloadFile} file={item} caption={title} />
        )
      }
      return <LinkItem hideHttp link={item.url} caption={item.caption} />
    },
    [is_attachment, handleDownloadFile]
  )
  return (
    <div
      className="docs-links"
      style={{
        display: "flex",
        whiteSpace: "nowrap",
      }}
    >
      {items.map((item, i) => (
        <div style={{ marginRight: "5px" }} key={item.id}>
          {renderItem(item)}
          {count > 1 && i !== items.length - 1 && (
            <span style={{ color: "#8C8C8C" }}> /</span>
          )}
        </div>
      ))}
    </div>
  )
})
