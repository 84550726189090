import { useCallback, useEffect, useMemo, useState } from "react"
import { useAction } from "./useAction"
import { useSelector } from "react-redux"
import {
  selectFilteredNotes,
  selectUsedTagsInNotes,
} from "../store/selectors/notes/get_notes_selector"
import { resetFilters } from "../store/actions/filters"
import {
  getWorkspaceList,
  getWorkspaceNotes,
} from "../store/actions/workspaces/getWorkspaces"
import { getNotePendingStatus } from "../store/selectors/notes/get_notes_selector"
import { useHistory, useParams } from "react-router-dom"
import {
  selectWorkspaceList,
  selectWorkspacesPendingStatus,
} from "../store/selectors"
import { config } from "../common/config"
import { removeWorkspaceThunk } from "../store/actions/workspaces/remove-workspace"
import { getAllColors } from "../store/actions/colors/get_all_colors"
import { isEmpty } from "lodash"
import { useColors } from "./useColors"
import { URLS } from "../common/urls"

export const useDocs = () => {
  const params = useParams()
  const tags = useSelector(selectUsedTagsInNotes)
  const history = useHistory()
  const workspaces_is_pending = useSelector(selectWorkspacesPendingStatus)
  const isNotePending = useSelector(getNotePendingStatus)
  const workspaces = useSelector(selectWorkspaceList)
  const filteredNotes = useSelector(selectFilteredNotes)
  const resetFiltersAction = useAction(resetFilters)
  const workspaceNotesAction = useAction(getWorkspaceNotes)
  const workspaceListAction = useAction(getWorkspaceList)
  const removeWorkspace = useAction(removeWorkspaceThunk)
  const colorsAction = useAction(getAllColors)
  const { allColors } = useColors()
  const [editWorkspace, setEditWorkspace] = useState(false)
  const [current_workspace, setCurrentWorkspace] = useState({})
  const filterByTagStyles = useMemo(() => {
    return { border: "2px grey solid", minHeight: "100%" }
  }, [])
  const showFilterByTag = useMemo(() => {
    return !isEmpty(tags) || !isEmpty(filteredNotes)
  }, [tags, filteredNotes])
  const tagListStyles = useMemo(() => {
    return {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }
  }, [])
  const handleCloseWorkspaceModal = useCallback(() => {
    editWorkspace && setEditWorkspace(false)
    setCurrentWorkspace({})
  }, [editWorkspace])

  const handleEditWorkspace = useCallback(
    (e, workspace_id) => {
      e.stopPropagation()
      if (!workspace_id) {
        return
      }
      const workspace = workspaces.find((item) => item.id === workspace_id)
      setCurrentWorkspace(workspace)
      setEditWorkspace(true)
    },
    [workspaces]
  )
  const handleRemoveWorkspace = useCallback(
    (e, workspace_id) => {
      e.stopPropagation()
      if (!workspace_id) {
        return
      }
      removeWorkspace(workspace_id).then((res) => {
        workspaceNotesAction(0)
        history.push(`${URLS.LIBRARY}/0`)
      })
    },
    [history, removeWorkspace, workspaceNotesAction]
  )
  useEffect(() => {
    if (isEmpty(workspaces)) {
      workspaceListAction()
    }
    let workspace_id = config.default_workspace_id

    if (Number(params.workspace_id)) {
      workspace_id = Number(params.workspace_id)
    }
    workspaceNotesAction(workspace_id)
    return () => {
      resetFiltersAction([])
    }
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (isEmpty(allColors)) {
      colorsAction()
    }
  }, [allColors, colorsAction])
  return {
    workspaces_is_pending,
    workspaces,
    tagListStyles,
    tags,
    filteredNotes,
    filterByTagStyles,
    handleCloseWorkspaceModal,
    handleEditWorkspace,
    editWorkspace,
    current_workspace,
    handleRemoveWorkspace,
    resetFiltersAction,
    setEditWorkspace,
    showFilterByTag,
    allColors,
    isNotePending,
  }
}
