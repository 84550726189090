import { createSelector } from "reselect"

export const WorkspaceSelector = (state) => state.workspace
export const selectWorkspaceList = createSelector(
  WorkspaceSelector,
  (state) => {
    if (!state.workspaces || !Array.isArray(state.workspaces)) {
      return []
    }
    return state.workspaces.sort((a, b) => a - b)
  }
)

export const selectWorkspacesPendingStatus = createSelector(
  WorkspaceSelector,
  (state) => state.isPending
)
