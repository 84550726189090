import {
  SET_ERROR_MESSAGE,
  SET_ERROR_STATE,
} from "../../actions/errors/set_errors"

const initState = {
  hasError: false,
  errorMessage: "",
}
export const errorsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR_STATE: {
      return {
        ...state,
        hasError: action.payload,
      }
    }
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
