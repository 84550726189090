import { createSelector } from "reselect"
import { sortByDate } from "../../../common/helpers"

const getFilesState = (state) => state.files

export const getFilesSelector = createSelector(getFilesState, (files_state) => {
  const files =
    files_state.file_type === "R"
      ? files_state.files_list.filter((item) => {
          return item.usr_type === files_state.file_type
        })
      : files_state.files_list.filter((item) => {
          return item.usr_type !== "R"
        })
  return sortByDate(files)
})

export const getFilesPendingStatus = createSelector(
  getFilesState,
  (files_state) => {
    return files_state.isPending
  }
)
export const getFilesUploadProgress = createSelector(
  getFilesState,
  (files_state) => {
    return files_state.upload_progress
  }
)

export const getFilteredAttachments = createSelector(getFilesState, (state) => {
  return state.filtered_attachments
})

export const getCurrentFilePendingStatus = createSelector(
  getFilesState,
  (state) => state.currentPendingFile
)
