import { createSelector } from "reselect"

const jobData = (state) => state.jobs

export const getJobPendingStatus = createSelector(
  jobData,
  (state) => state.isPending
)
export const getJobAppliedPendingStatus = createSelector(
  jobData,
  (state) => state.isPendingAppliedJobs
)
