import {
  SET_CONTACT_TODOS,
  SET_CONTACTS_LIST,
  SET_CONTACT_TO_LIST,
  SET_CONTACTS_PAGINATION,
  SET_CONTACTS_PENDING_STATUS,
  SET_NEW_CONTACT_TODO,
  SET_SPECIFIC_CONTACT,
  SET_CONTACT_FETCHING,
  CLEAR_CONTACT_FETCHING,
  SET_COUNTERS,
  RESET_CONTACTS,
  SET_SORT_KEY_CONTACTS,
} from "../../action-types/contacts"
import {
  CONTACT_API_URL,
  FeaturesTypes,
  JOB_API_URL,
  TODOS_API_URL,
} from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import {
  getContactsPagination,
  getContactsSelector,
  getSortKey,
} from "../../selectors/contacts/get-contacts-selector"
import { isEmpty } from "lodash"

export const setContactsList = (payload) => {
  return {
    type: SET_CONTACTS_LIST,
    payload,
  }
}
export const resetContacts = () => ({
  type: RESET_CONTACTS,
})
export const setContactToList = (payload) => {
  return {
    type: SET_CONTACT_TO_LIST,
    payload,
  }
}

const setContactFetching = (contactId) => ({
  type: SET_CONTACT_FETCHING,
  payload: contactId,
})

export const clearContactFetching = (contactId) => ({
  type: CLEAR_CONTACT_FETCHING,
  payload: contactId,
})
export const setSpecificContact = (contact) => {
  return {
    type: SET_SPECIFIC_CONTACT,
    payload: contact,
  }
}

export const setContactsPagination = (payload) => ({
  type: SET_CONTACTS_PAGINATION,
  payload,
})

export const setContactsPendingStatus = (payload) => ({
  type: SET_CONTACTS_PENDING_STATUS,
  payload,
})

export const setSortKey = (payload) => {
  return {
    type: SET_SORT_KEY_CONTACTS,
    payload,
  }
}

const setCounters = (payload) => {
  return {
    type: SET_COUNTERS,
    payload,
  }
}

export const getContacts = () => async (dispatch, getState) => {
  const pagination = getContactsPagination(getState())
  const sortKey = getSortKey(getState())
  const url =
    isEmpty(pagination) || !pagination.next
      ? `${CONTACT_API_URL}?limit=50${
          sortKey !== null
            ? `&ordering=${sortKey.order + sortKey.columnKey}`
            : ""
        }`
      : pagination.next
  try {
    dispatch(setContactsPendingStatus(true))
    const response = await request().get(url, dispatch)
    dispatch(setContactsPagination({ next: response.data.next }))
    dispatch(setContactsList(response.data))
    return response.data
  } catch (err) {
  } finally {
    dispatch(setContactsPendingStatus(false))
  }
}
export const fetchContactByIdIfNeeded =
  (contactId) => async (dispatch, getState) => {
    const { fetchingContacts } = getState().contacts

    const allContacts = getContactsSelector(getState())

    const contactExists = allContacts.find(
      (contact) => contact.id === contactId
    )
    if (contactExists) return contactExists
    if (
      typeof fetchingContacts.has === "function" &&
      !fetchingContacts?.has(contactId)
    ) {
      dispatch(setContactFetching(contactId))
      return await dispatch(getContact(contactId))
    }
  }
export const setContactTodos = (payload) => {
  return {
    type: SET_CONTACT_TODOS,
    payload,
  }
}
export const setNewContactTodo = (payload) => {
  return {
    type: SET_NEW_CONTACT_TODO,
    payload,
  }
}
export const getContactTodos = (params) => async (dispatch) => {
  try {
    const response = await request().get(`${TODOS_API_URL}${params}`)
    dispatch(setContactTodos(response.data))
  } catch (err) {}
}

export const getContact = (id) => async (dispatch) => {
  dispatch(setContactsPendingStatus(true))
  try {
    const response = await getContactOrJobById(id, FeaturesTypes.CONTACT)
    if (response.data) dispatch(setContactToList(response.data))
    return response.data
  } catch (err) {
    throw err
  } finally {
    dispatch(setContactsPendingStatus(false))
  }
}

export const getContactOrJobById = (id, type) => {
  const url = type === FeaturesTypes.CONTACT ? CONTACT_API_URL : JOB_API_URL
  return request().get(`${url}${id}/`)
}

export const getContactCounters = () => async (dispatch) => {
  try {
    const response = await request().get(
      `${CONTACT_API_URL}?limit=50&offset=1000000`
    )
    dispatch(setCounters(response.data))
  } catch (err) {}
}
