import { useCallback, useEffect, useRef } from "react"
import { isEmpty } from "lodash"
import { useAction } from "./useAction"
import { useSelector } from "react-redux"
import {
  getSelectedTag,
  getTagsQuery,
} from "../store/selectors/tags/get-tags-selector"
import {
  createNewTag,
  setSelectedTag,
  setTagsQuery,
} from "../store/actions/tags/add-tag"
import { getContactDraftTags } from "../store/selectors/contacts/get-contacts-selector"
import { getDraftTagsCompany } from "../store/selectors/companies/get-companies-selector"
import { setDraftTagsCompany } from "../store/actions/companies/add-company"
import { setDraftTagsContact } from "../store/actions/contacts/create-contact"
import { addTagUtil, onOutsideClickOnTag } from "../common/helpers"
import { getAllTags } from "../store/actions/search/get-tags"
import { getFilteredTags } from "../store/selectors/tags/get-tags-selector"
import { useColors } from "./useColors"
import { FeaturesTypes } from "../common/constants"
import { getDraftTagsJobs } from "../store/selectors/jobs/get_jobs_selector"
import { setDraftTagsJob } from "../store/actions/jobs/add-job"
import { getDraftTagsNote } from "../store/selectors/notes/get_notes_selector"
import { setDraftTagsNote } from "../store/actions/notes/create_note"

export const useAddTag = (props) => {
  const { item, update, setAddTagStep, type } = props
  const tagInputRef = useRef(null)

  const updateAction = useAction(update)
  const tagsQuery = useSelector(getTagsQuery)
  const { allColors } = useColors()
  const changeTagsQuery = useAction(setTagsQuery)
  const allTagsAction = useAction(getAllTags)

  const selectTagAction = useAction(setSelectedTag)
  const createTagAction = useAction(createNewTag)

  const companyDraftTags = useSelector(getDraftTagsCompany)
  const contactDraftTags = useSelector(getContactDraftTags)
  const jobDraftTags = useSelector(getDraftTagsJobs)
  const noteDraftTags = useSelector(getDraftTagsNote)

  const updateCompanyDraftTags = useAction(setDraftTagsCompany)
  const updateContactDraftTags = useAction(setDraftTagsContact)
  const updateJobDraftTags = useAction(setDraftTagsJob)
  const updateNoteDraftTags = useAction(setDraftTagsNote)

  const selectedTag = useSelector(getSelectedTag)
  const allTags = useSelector(getFilteredTags)

  const updateDraftTagsByType = useCallback(
    (newTags) => {
      switch (type) {
        case FeaturesTypes.COMPANY:
          updateCompanyDraftTags(newTags)
          break
        case FeaturesTypes.CONTACT:
          updateContactDraftTags(newTags)
          break
        case FeaturesTypes.NOTE:
          updateNoteDraftTags(newTags)
          break
        case FeaturesTypes.LEAD:
          updateJobDraftTags(newTags)
          break
        default:
          break
      }
    },
    [
      type,
      updateCompanyDraftTags,
      updateContactDraftTags,
      updateJobDraftTags,
      updateNoteDraftTags,
    ]
  )

  const getCurrentTagsByType = useCallback(() => {
    switch (type) {
      case FeaturesTypes.COMPANY:
        return companyDraftTags
      case FeaturesTypes.CONTACT:
        return contactDraftTags
      case FeaturesTypes.NOTE:
        return noteDraftTags
      case FeaturesTypes.LEAD:
        return jobDraftTags
      default:
        return []
    }
  }, [companyDraftTags, contactDraftTags, jobDraftTags, noteDraftTags, type])

  const currentTags = getCurrentTagsByType()

  const onRemoveTag = useCallback(
    async (tag) => {
      const myTags = item?.tags || currentTags
      const tags = myTags.filter((i) => {
        return i.tag !== tag.tag
      })
      if (item.id) {
        await updateAction({ tags, id: item.id })
      } else {
        updateDraftTagsByType(tags)
      }
    },
    [currentTags, item.id, item?.tags, updateAction, updateDraftTagsByType]
  )
  const onSelectTag = useCallback(
    async (tag) => {
      const myTags = item?.tags || currentTags
      const tagIsExist = myTags?.some(
        (item) => item.tag.trim() === tag.tag.trim()
      )
      tagInputRef?.current?.focus()
      if (tagIsExist) {
        return
      }

      if (item?.id) {
        await updateAction({ tags: myTags.concat(tag), id: item.id })
      } else {
        updateDraftTagsByType(myTags.concat(tag))
      }
      await changeTagsQuery("")
    },
    [
      tagInputRef,
      currentTags,
      changeTagsQuery,
      item.id,
      item?.tags,
      updateAction,
      updateDraftTagsByType,
    ]
  )

  const onAddTag = useCallback(
    async (tag) => {
      const ownTags = item?.tags || currentTags
      const createTag = async (_tag) => {
        !item.id && (await createTagAction(_tag))
      }
      const append = async (tags) => {
        if (item.id) {
          await updateAction({ tags: item?.tags?.concat(tags), id: item.id })
          await allTagsAction()
        } else {
          updateDraftTagsByType(tags)
        }
      }
      addTagUtil(
        ownTags,
        tagsQuery,
        changeTagsQuery,
        allColors,
        tag,
        createTag,
        append
      )
    },
    [
      currentTags,
      tagsQuery,
      changeTagsQuery,
      allColors,
      createTagAction,
      item.id,
      item?.tags,
      allTagsAction,
      updateAction,
      updateDraftTagsByType,
    ]
  )
  const openColorsAutocomplete = useCallback(
    (selectedTag) => {
      selectTagAction(selectedTag)
      setAddTagStep(2)
    },
    [selectTagAction, setAddTagStep]
  )
  const onCloseColorsAutoComplete = useCallback(() => {
    selectTagAction({})
    setAddTagStep(1)
  }, [selectTagAction, setAddTagStep])
  const handleOutsideClick = useCallback(
    (event) => {
      if (
        event.target?.outerText === "Delete" ||
        event.target?.outerText === "Cancel"
      ) {
        event.stopPropagation()
        return
      }
      onOutsideClickOnTag(
        event,
        changeTagsQuery,
        setAddTagStep,
        props.addTagStep
      )
    },
    [changeTagsQuery, props.addTagStep, setAddTagStep]
  )
  useEffect(() => {
    if (isEmpty(allTags)) {
      allTagsAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    currentTags: item.tags || currentTags,
    allTags,
    onSelectTag,
    onRemoveTag,
    openColorsAutocomplete,
    onCloseColorsAutoComplete,
    onAddTag,
    selectedTag,
    changeTagsQuery,
    tagsQuery,
    handleOutsideClick,
    tagInputRef,
  }
}
