import { DELETE_JOB } from "../../action-types/jobs"
import { request } from "../../../api/axios_helper"
import { FeaturesTypes, JOB_API_URL } from "../../../common/constants"
import { removeSearchResult } from "../search/get-search"
import { removeDiaryActivity } from "../diaries/delete-diary"

const deleteJobAc = (id) => {
  return {
    type: DELETE_JOB,
    payload: id,
  }
}
export const deleteJobTH = (id) => async (dispatch) => {
  try {
    await request().delete(`${JOB_API_URL}${id}/`, dispatch, () => {
      dispatch(removeSearchResult(id, "job"))
      dispatch(deleteJobAc(id))
      dispatch(removeDiaryActivity({ id, activity_type: FeaturesTypes.LEAD }))
    })
  } catch (err) {}
}
