/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { authUser, setLoginBadRequestError, setAuthErrors } from "../store/actions/user"
import { URLS } from "../common/urls"
import { Alert } from "antd"
import {
  getAuthErrors,
  getEmailIsConfirmed,
  getLoginBadRequestError,
} from "../store/selectors/user/get-login-status"
import { EMAIL_CONFIRMED_MESSAGE } from "../common/constants"
import { useLocation, useHistory } from "react-router-dom"
import withMemo from "../store/HOC/with_memo"
import Register from "./register"

export const RegisterContainer = withMemo(() => {
  const dispatch = useDispatch()
  const errors = useSelector(getAuthErrors)
  const full_error = useSelector(getLoginBadRequestError)
  const location = useLocation()
  const history = useHistory()
  const email_is_confirmed = useSelector(getEmailIsConfirmed)
  const [message, setMessage] = useState(null)

  const clearErrors = useCallback(() => {
    dispatch(setAuthErrors([]))
    setMessage(null)
    full_error && dispatch(setLoginBadRequestError(null))
  }, [full_error, dispatch])

  const onSubmit = (credentials) => {
    clearErrors()
    dispatch(authUser(credentials, true)).then((res) => {
      if (res) {
        localStorage.setItem("logged_in", 1)
        history.push(URLS.HOME)
      }
    })
  }
  const showAlert = useCallback(() => {
    return email_is_confirmed !== null && email_is_confirmed
  }, [email_is_confirmed])

  useEffect(() => {
    clearErrors()
  }, [])

  useEffect(() => {
    const { message } = { ...(location.state || {}) }
    message && setMessage(message)
  }, [])
  return (
    <>
      {message && (
        <Alert
          message={message}
          type="info"
          style={{ position: "absolute", top: "-48px", width: "422px" }}
        />
      )}
      {showAlert() && (
        <Alert
          message={EMAIL_CONFIRMED_MESSAGE}
          type="success"
          style={{ position: "absolute", top: "-48px", width: "422px" }}
        />
      )}
      <Register full_error={full_error} errors={errors} onSubmit={onSubmit} />
    </>
  )
})
