import React from "react"
import withMemo from "../../store/HOC/with_memo"
import { Button, Col, Row } from "antd"
import { useNotes } from "../../hooks/useNots"
import { NotesHorizontalList } from "./notes-horizontal-list"
import { PlusOutlined } from "@ant-design/icons"

export const Notes = withMemo(({ notes = [], allColors, isDrawer = true }) => {
  const {
    handleAddNew,
    isLoading,
    openEditor,
    handleEditNote,
  } = useNotes()
  return (
    <Row className="docs-notes" id="notes">
      <Col span={24}>
        {isDrawer && (
          <div style={{ width: "100%", marginBottom: "12px" }}>
            <Button type="primary" size="small" onClick={handleAddNew}>
              <PlusOutlined />
              New
            </Button>
          </div>
        )}
        <NotesHorizontalList
          addNew={handleAddNew}
          allColors={allColors}
          notes={notes}
          openEditor={openEditor}
          is_pending={isLoading()}
          handleEditNote={handleEditNote}
          isDrawer={isDrawer}
        />
      </Col>
    </Row>
  )
})
