import React, { useCallback, useEffect } from "react"
import { Field, Form, reduxForm } from "redux-form"
import { Button, Typography, Row } from "antd"
import { SocialLogin } from "../components/social_login"
import { LinkItem } from "../components/notes/link_item"
import { Link, useHistory } from "react-router-dom"
import { URLS } from "../common/urls"
import { Alert } from "antd"
import { useAction } from "../hooks/useAction"
import { resetApplicationData } from "../store/actions/user"

const { Title, Text } = Typography

const Login = ({ handleSubmit, errors = {}, full_error }) => {
  const history = useHistory()
  const resetApp = useAction(resetApplicationData)
  const handleForgot = () => {
    history.push(URLS.FORGOT_PASS)
  }
  const renderError = useCallback(() => {
    if (!full_error) {
      return null
    }
    return (
      <Alert
        message={full_error}
        style={{ position: "absolute", top: "-48px", width: "422px" }}
        type="error"
      />
    )
  }, [full_error])
  const handlePressEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit(e)
      }
    },
    [handleSubmit]
  )
  useEffect(() => {
    resetApp()
    document.addEventListener("keydown", handlePressEnter)
    return () => {
      document.removeEventListener("keydown", handlePressEnter)
    }
    //eslint-disable-next-line
  }, [handlePressEnter])
  return (
    <Row style={{ display: "block", width: "422px" }}>
      {renderError()}
      <div
        style={{
          padding: "24px 32px",
          width: "422px",
          border: "1px solid #D9D9D9",
          borderRadius: "6px",
        }}
      >
        <Row>
          <div style={{ width: "100%" }}>
            <Title
              style={{ marginBottom: "32px", textAlign: "center" }}
              level={3}
            >
              Sign In
            </Title>
            <SocialLogin />
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                marginTop: "-10px",
              }}
            >
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(217, 217, 217, 0.65)",
                  marginRight: "14.5px",
                  width: "157.5px",
                  marginTop: "11px",
                }}
              ></div>
              <div>or</div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(217, 217, 217, 0.65)",
                  marginLeft: "14.5px",
                  width: "157.5px",
                  marginTop: "11px",
                }}
              ></div>
            </div>
            <div className="authForm">
              <Form onSubmit={handleSubmit}>
                <Text style={{ display: "block", marginBottom: "8px" }}>
                  Email
                </Text>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  id="email_form"
                  style={{
                    width: "100%",
                    height: "32px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "2px",
                  }}
                />
                {errors.email && (
                  <Text
                    style={{
                      display: "block",
                      marginTop: "4px",
                      color: "#F25041",
                    }}
                  >
                    {errors.email}
                  </Text>
                )}
                <Text
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    marginTop: "12px",
                  }}
                >
                  Password
                </Text>
                <Field
                  name="password"
                  component="input"
                  type="password"
                  id="form_password"
                  style={{
                    width: "100%",
                    height: "32px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "2px",
                  }}
                />
                {errors.password && (
                  <Text
                    style={{
                      display: "block",
                      marginTop: "4px",
                      color: "#F25041",
                    }}
                  >
                    {errors.password}
                  </Text>
                )}
                <Button
                  style={{
                    display: "block",
                    width: "100%",
                    marginBottom: "20px",
                    marginTop: "24px",
                    color: "#ffffff",
                    backgroundColor: "#009EEF",
                    borderRadius: "2px",
                    borderColor: "#009EEF",
                  }}
                  onClick={handleSubmit}
                >
                  Sign In
                </Button>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Link
                    style={{
                      fontWeight: "500",
                      color: "#009EEF",
                      textDecoration: "underline",
                    }}
                    to={URLS.REGISTER}
                  >
                    Create an account
                  </Link>
                  <LinkItem
                    style={{ display: "block" }}
                    file
                    link="Forgot password?"
                    callback={handleForgot}
                  />
                </div>
              </Form>
            </div>
          </div>
        </Row>
      </div>
    </Row>
  )
}

export default reduxForm({
  form: "login",
})(Login)
