import React, { useState } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"

export default function CheckoutForm({ clientSecret, return_url, isIntent, onCancel }) {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)

  const handleError = (error) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()


    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("Stripe.js hasn't yet loaded.")
      return
    }

    setLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    let res;
    if (isIntent) {
      res = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url
        },
      })
    } else {
      res = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url
        },
      })
    }




    if (res.error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(res.error)
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <button
          style={{
            backgroundColor: '#009eef',
            border: 'none',
            borderRadius: '6px',
            color: '#fff',
            cursor: 'pointer',
            padding: '6px 12px',
            marginTop: '20px',
          }}
          type="submit"
          disabled={!stripe || loading}>
          Submit Payment
        </button>
        <button
          style={{
            backgroundColor: '#009eef',
            border: 'none',
            borderRadius: '6px',
            color: '#fff',
            cursor: 'pointer',
            padding: '6px 12px',
            marginTop: '20px',
          }}
          onClick={onCancel}
          disabled={!stripe || loading}>
          Cancel
        </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}
