import { useState } from "react"
import { Popover } from "antd"
import { TODO_DONE_STATUS } from "../common/constants"

export const TodoItem = ({ todo, handleEdit, setEditableTodo, isThrough = true, isShow = false }) => {
  const [maxLength] = useState(isShow ? 80 : 26)
  const checked = () => todo.status_id === TODO_DONE_STATUS
  const content = <span>{todo.description}</span>
  const needToPopover = Boolean(todo?.description?.length > maxLength)
  return needToPopover ? (
    <Popover content={content} trigger="hover">
      <span
        className="todo-task"
        style={
          checked() && isThrough
            ? {
              textDecoration: "line-through",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }
            : {
              textDecoration: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }
        }
        onDoubleClick={() => {
          handleEdit(todo)
          setEditableTodo(todo.id)
        }}
      >
        {todo.description.slice(0, maxLength)}{todo.description.slice(0, maxLength - 1)[maxLength - 1] === " " ? "..." : " ..."}
      </span>
    </Popover>
  ) : (
    <span
      onDoubleClick={() => {
        if (todo) {
          handleEdit(todo)
          setEditableTodo(todo.id)
        }
      }}
      className="todo-task"
      style={
        checked() && isThrough
          ? {
            textDecoration: "line-through",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }
          : {
            textDecoration: "none",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }
      }
    >
      {todo.description}
    </span>
  )
}
