import { useMemo } from "react"
import withMemo from "../../store/HOC/with_memo"
import { useAction } from "../../hooks/useAction"
import { downloadFile } from "../../store/actions/file_manager/download-file"
import { ChipsList } from "../docs/ChipsList"
import { isEmpty } from "lodash"

export const AttachmentsLinksCard = withMemo(
  ({ links, atts, handleRemoveChip }) => {
    const isLinksOnly = useMemo(
      () => !isEmpty(links) && isEmpty(atts),
      [atts, links]
    )
    const isAttachmentsOnly = useMemo(
      () => isEmpty(links) && !isEmpty(atts),
      [atts, links]
    )
    const handleDownloadFile = useAction(downloadFile)
    return (
      <>
        {!isEmpty(links) && !isEmpty(atts) && (
          <div
            style={{
              display: "flex",
              padding: "0px 24px 0px 24px",
            }}
          >
            <div
              className="docs-chip"
              style={{
                paddingRight: "24px",
                paddingTop: "8px",
                paddingBottom: "2px",
                borderRight: "1px solid #f0f0f0",
              }}
            >
              <ChipsList
                chips={links}
                handleRemoveChip={handleRemoveChip}
                handleDownloadFile={handleDownloadFile}
              />
            </div>
            <div
              className="docs-chip"
              style={{
                paddingLeft: "24px",
                paddingTop: "8px",
                paddingBottom: "2px",
              }}
            >
              <ChipsList
                chips={atts}
                handleRemoveChip={handleRemoveChip}
                handleDownloadFile={handleDownloadFile}
              />
            </div>
          </div>
        )}
        {isLinksOnly && (
          <div
            style={{
              display: "flex",
              padding: "0px 24px 0px 24px",
            }}
          >
            <div
              className="docs-chip"
              style={{
                paddingRight: "24px",
                paddingTop: "8px",
                paddingBottom: "2px",
              }}
            >
              <ChipsList
                chips={links}
                handleRemoveChip={handleRemoveChip}
                handleDownloadFile={handleDownloadFile}
              />
            </div>
          </div>
        )}
        {isAttachmentsOnly && (
          <div
            style={{
              display: "flex",
              padding: "0px 24px 0px 24px",
            }}
          >
            <div
              className="docs-chip"
              style={{
                paddingTop: "8px",
                paddingBottom: "2px",
                paddingRight: "24px",
              }}
            >
              <ChipsList
                chips={atts}
                handleRemoveChip={handleRemoveChip}
                handleDownloadFile={handleDownloadFile}
              />
            </div>
          </div>
        )}
      </>
    )
  }
)
