import React from "react"
import withMemo from "./with_memo"
import { useAddTag } from "../../hooks/useAddTag"
import OutsideClickHandler from "react-outside-click-handler"

export const withAddTag = (Component) => {
  return withMemo((props) => {
    const {
      currentTags,
      allTags,
      onSelectTag,
      onRemoveTag,
      openColorsAutocomplete,
      onCloseColorsAutoComplete,
      onAddTag,
      selectedTag,
      changeTagsQuery,
      tagsQuery,
      handleOutsideClick,
      tagInputRef,
    } = useAddTag(props)
    const restProps = {
      currentTags,
      tags: allTags,
      onSelectTag,
      onRemoveTag,
      openColorsAutocomplete,
      onCloseColorsAutoComplete,
      onAddTag,
      selectedTag,
      changeTagsQuery,
      tagsQuery,
      tagInputRef,
    }
    return (
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <Component {...props} {...restProps} />
      </OutsideClickHandler>
    )
  })
}
