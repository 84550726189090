import { SET_TODOS_DATA } from "../../action-types/todos"
import { request } from "../../../api/axios_helper"
import { TODO_NOT_DONE_STATUS, TODOS_API_URL } from "../../../common/constants"
import { setTodoPendingStatus } from "./create_todo"
import { getTodoCurrentFilter } from "../../selectors"

export const setTodos = (payload) => {
  return {
    type: SET_TODOS_DATA,
    payload,
  }
}
export const getTodos = () => async (dispatch, getState) => {
  try {
    dispatch(setTodoPendingStatus(true))
    const filter = getTodoCurrentFilter(getState())
    const url =
      filter === TODO_NOT_DONE_STATUS
        ? `${TODOS_API_URL}?status_id=${TODO_NOT_DONE_STATUS}`
        : TODOS_API_URL
    const res = await request().get(url, dispatch)
    if (res.status === 200) {
      dispatch(setTodos(res.data))
    }
  } catch (err) {
  } finally {
    dispatch(setTodoPendingStatus(false))
  }
}
