export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS"
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS"
export const SELECT_TAG = "SELECT_TAG"
export const REMOVE_TAG_FROM_CONTACT = "REMOVE_TAG_FROM_CONTACT"
export const REMOVE_TAG_FROM_LEAD = "REMOVE_TAG_FROM_LEAD"
export const SET_NEW_TAG = "SET_NEW_TAG"
export const SET_TAGS_LIST = "SET_TAGS_LIST"
export const SET_TAGS_QUERY = "SET_TAGS_QUERY"
export const SET_SELECTED_TAG = "SET_SELECTED_TAG"
export const UPDATE_CURRENT_TAGS = "UPDATE_CURRENT_TAGS"
export const SET_CURRENT_TAGS = "SET_CURRENT_TAGS"
