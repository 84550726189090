export const FETCH_COMPANIES = "FETCH_COMPANIES"
export const GET_COMPANY = "GET_COMPANY"
export const HANDLE_COMPANY_SEARCH = "HANDLE_COMPANY_SEARCH"
export const ADD_COMPANY = "ADD_COMPANY"
export const REMOVE_COMPANY = "REMOVE_COMPANY"
export const SET_COMPANY_PENDING_STATUS = "SET_COMPANY_PENDING_STATUS"
export const SET_SPECIFIC_COMPANY = "SET_SPECIFIC_COMPANY"
export const SAVE_EDITED_COMPANY = "SAVE_EDITED_COMPANY"
export const SET_COMPANY_NOTES = "SET_COMPANY_NOTES"
export const EDIT_NOTE_IN_COMPANY = "EDIT_NOTE_IN_COMPANY"
export const SET_COMPANY_CONTACTS = "SET_COMPANY_CONTACTS"
export const SET_NEW_COMPANY_CONTACT = "SET_NEW_COMPANY_CONTACT"
export const SET_COMPANY_JOB_PENDING_STATUS = "SET_COMPANY_JOB_PENDING_STATUS"
export const SET_COMPANY_JOBS = "SET_COMPANY_JOBS"
export const REMOVE_COMPANY_FROM_ACTIVITY = "REMOVE_COMPANY_FROM_ACTIVITY"
export const REMOVE_CONTACT_FROM_COMPANY = "REMOVE_CONTACT_FROM_COMPANY"
export const SET_DRAFT_TAGS_COMPANY = "SET_DRAFT_TAGS_COMPANY"
export const RESET_COMPANIES = "RESET_COMPANIES"
