import { Redirect, Route, Switch } from "react-router-dom"
import {
  Company,
  Contacts,
  Docs,
  Home,
  Leads,
  SearchResults,
  VerifyAccount,
} from "../pages"
import { URLS } from "../common/urls"
import { LoginContainer } from "../pages/auth"
import { useSelector } from "react-redux"
import { getUserIsAuthenticated } from "../store/selectors/user/get-login-status"
import { getIsActionBill } from "../store/selectors/user/getUserData"
import { WaitPage } from "../pages/wait_page"
import { createBrowserHistory } from "history"
import { ForgotPassword } from "../pages/forgot_password"
import { ResetPasswordConfirm } from "../pages/ResetPasswordConfirm"
import { RegisterContainer } from "../pages/register-container"
import { HelpPage } from "../pages/Help"

export const history = createBrowserHistory()
export const PrivateRoute = ({ path, component }) => {
  const isActionForBill = useSelector(getIsActionBill)
  const isAuth = useSelector(getUserIsAuthenticated)
  if (isAuth === undefined) {
    return <WaitPage />
  }
  if (isActionForBill) {
    return <Redirect to={URLS.VERIFY} />
  }
  return isAuth ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to={URLS.LOGIN} />
  )
}

export const RenderRouter = () => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <Switch>
        <Route exact path={URLS.ROOT} component={WaitPage} />
        <Route
          exact
          path={URLS.FORGOT_PASS_CONFIRM}
          component={ResetPasswordConfirm}
        />
        <Route exact path={URLS.FORGOT_PASS} component={ForgotPassword} />
        <Route path={URLS.LOGIN} component={LoginContainer} />
        <Route path={URLS.REGISTER} component={RegisterContainer} />
        <Route path={URLS.VERIFY} component={VerifyAccount} />
        <Route exact path={URLS.HELP} component={HelpPage} />
        <PrivateRoute path={URLS.HOME} component={Home} />
        <PrivateRoute path={URLS.LEADS} component={Leads} />
        <PrivateRoute path={URLS.CONTACTS} component={Contacts} />
        <PrivateRoute path={URLS.COMPANY} component={Company} />
        <PrivateRoute path={`${URLS.LIBRARY}/:workspace_id`} component={Docs} />
        <PrivateRoute path={URLS.SEARCH} component={SearchResults} />
      </Switch>
    </div>
  )
}
