export const LOGIN_ACTION = "LOGIN_ACTION"
export const LOGOUT_ACTION = "LOGOUT_ACTION"
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS"
export const PASSWORD_RESET_SEND = "PASSWORD_RESET_SEND"
export const SET_EMAIL_LOGGER = "SET_EMAIL_LOGGER"
export const SET_EMAIL_CHANGE_PENGING_STATUS = "SET_EMAIL_CHANGE_PENGING_STATUS"
export const SET_AUTH_ERRORS = "SET_AUTH_ERRORS"
export const SET_USER_IS_AUTH = "SET_USER_IS_AUTH"
export const SET_REFRESHING_STATUS = "SET_REFRESHING_STATUS"
export const SET_TOKEN = "SET_TOKEN"
export const SET_EMAIL_CONFIRMED_STATUS = "SET_EMAIL_CONFIRMED_STATUS"
export const SET_LOGIN_BAD_REQUEST_ERROR = "SET_LOGIN_BAD_REQUEST_ERROR"
export const SET_PASSWORD_RESET_CONFIRM_STATUS =
  "SET_PASSWORD_RESET_CONFIRM_STATUS"
export const RESET_APPLICATION_DATA = "RESET_APPLICATION_DATA"

export const CREATE_STRIPE_CUSTOMER_ID = "CREATE_STRIPE_CUSTOMER_ID"
export const CREATE_STRIPE_SUBSCRIPTION = "CREATE_STRIPE_SUBSCRIPTION"
export const SET_STRIPE_STATUS = "SET_STRIPE_STATUS"
export const SET_DESKTOP_LAYOUT = "SET_DESKTOP_LAYOUT"
