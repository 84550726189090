import { createSelector } from "reselect"
import {
  CompanySelector,
  getSpecificCompany,
} from "../companies/get-companies-selector"
import { sortBy, uniqBy } from "lodash"
import { getContactsSelector } from "../index"

export const getCompanyContactsSelector = createSelector(
  [CompanySelector, getContactsSelector, getSpecificCompany],
  (state, allContacts, company) => {
    if (!company?.id) {
      return []
    }
    const companyContacts = [...(allContacts || [])].filter(
      (item) => item.company?.id === company?.id
    )
    return uniqBy(
      sortBy([...state.contacts, ...companyContacts], (item) => {
        return -new Date(item.create_dt).getTime()
      }),
      "id"
    )
  }
)
