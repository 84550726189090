import React, { useEffect, useRef, useState } from "react"
import { List, Empty } from "antd"
import { DraggableTodoItem } from "./DraggableTodoItem"
import { URLS } from "../common/urls"
import { useLocation } from "react-router-dom"
import { AddTodo } from "./add_todo"
import withMemo from "../store/HOC/with_memo"
import NoData from "../assets/images/todo_create_svy.svg"

export const TodoList = withMemo(
  ({
    todos,
    containerId,
    showTodoInput,
    setPickerIsOpenToParent,
    onCloseParentModal,
    handleSaveTodo,
    isModal = false,
    isContact = false,
    handleOpenModal = () => { },
    isShow = false
  }) => {
    const [pickerIsOpen, setPickerIsOpen] = useState(false)
    const [editableTodo, setEditableTodo] = useState(0)
    const [clickCount, setClickCount] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const inputRef = useRef()
    const location = useLocation()
    const showInput =
      location.pathname === URLS.LEADS ||
      location.pathname === URLS.CONTACTS ||
      showTodoInput
    useEffect(() => {
      if (isEdit) {
        inputRef.current?.focus()
      }
    }, [isEdit])
    return (
      <div className="contact-modal todo">
        {showInput && (
          <AddTodo
            showTodoInput
            setPickerIsOpen={setPickerIsOpen}
            pickerIsOpen={pickerIsOpen}
            setClickCount={setClickCount}
            clickCount={clickCount}
            handleSaveTodo={handleSaveTodo}
            isModal={isModal}
            isContact={isContact}
          />
        )}
        {!todos.length ? (
          <Empty
            className="no-data"
            image={<img src={NoData} alt="no-data" className="img-no-data" />}
            description={<span style={{ color: "rgb(181, 179, 179)" }}>No Tasks</span>}
          />
        ) : (
          <List
            id="todo_list"
            rowKey={(row) => row.id}
            size="large"
            header={null}
            footer={null}
            bordered

            dataSource={todos}
            renderItem={(todo, idx) => {
              return (
                <DraggableTodoItem
                  onCloseParentModal={onCloseParentModal}
                  setPickerIsOpenToParent={
                    setPickerIsOpenToParent
                  }
                  container={containerId}
                  inputRef={inputRef}
                  todo={todo}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  editableTodo={editableTodo}
                  setEditableTodo={setEditableTodo}
                  handleSaveTodo={handleSaveTodo}
                  handleOpenModal={handleOpenModal}
                  isShow={isShow}
                />
              )
            }}
          />
        )}
      </div>
    )
  }
)
