import { useAction } from "./useAction"
import { getAllColors } from "../store/actions/colors/get_all_colors"
import { useSelector } from "react-redux"
import { getAllColorsSelector } from "../store/selectors/colors/get_all_colors_selector"
import { isEmpty } from "lodash"
import { useEffect } from "react"

export const useColors = () => {
  const allColors = useSelector(getAllColorsSelector)
  const getAll = useAction(getAllColors)

  useEffect(() => {
    if (isEmpty(allColors)) {
      getAll()
    }
    //eslint-disable-next-line
  }, [getAll])
  return {
    allColors,
  }
}
