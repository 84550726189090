import { request } from "../../api/axios_helper"
import { JOB_API_URL } from "../../common/constants"

export class LeadService {
  static async getJobById(id) {
    try {
      const response = await request().get(`${JOB_API_URL}${id}/`)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}
