import {
  SET_JOB_PENDING_STATUS,
  SET_JOB_TODOS,
  SET_JOBS_DATA,
  SET_NEW_JOB_TODO,
  SET_JOB_FETCHING,
  SET_JOB_TO_LIST,
  CLEAR_JOB_FETCHING,
} from "../../action-types/jobs"
import { JOB_API_URL, TODOS_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"
import { LeadService } from "../../services/leadService"
import { setSpecificLead } from "./update-job"
import { getJobsSelector } from "../../selectors"
import { getContactOrJobById } from "../contacts/get-contacts-list"

export const setJobs = (payload) => {
  return {
    type: SET_JOBS_DATA,
    payload,
  }
}
export const setJobPendingStatus = (payload) => ({
  type: SET_JOB_PENDING_STATUS,
  payload,
})

const setJobFetching = (jobId) => ({
  type: SET_JOB_FETCHING,
  payload: jobId,
})

const setJobToList = (payload) => ({
  type: SET_JOB_TO_LIST,
  payload,
})

export const clearJobFetching = (jobId) => ({
  type: CLEAR_JOB_FETCHING,
  payload: jobId,
})
export const getJobs = (status_id) => async (dispatch) => {
  try {
    dispatch(setJobPendingStatus(true))
    const res = await request().get(
      `${JOB_API_URL}${status_id ? "?status_id=" + status_id : ""}`,
      dispatch
    )
    if (res?.status === 200) {
      await dispatch(setJobs(res?.data))
    }
    return res.data
  } catch (err) {
    console.log(err)
  } finally {
    dispatch(setJobPendingStatus(false))
  }
}

export const fetchJobByIdIfNeeded = (jobId) => async (dispatch, getState) => {
  const allJobs = getJobsSelector(getState())
  const { fetchingJobs } = getState().jobs

  const jobExists = allJobs.find((job) => job.id === jobId)

  if (jobExists) return jobExists

  if (typeof fetchingJobs.has === "function" && !fetchingJobs?.has(jobId)) {
    dispatch(setJobFetching(jobId))
    return await dispatch(getJobToList(jobId))
  }
}
export const setJobTodos = (payload) => {
  return {
    type: SET_JOB_TODOS,
    payload,
  }
}
export const setNewJobTodo = (payload) => {
  return {
    type: SET_NEW_JOB_TODO,
    payload,
  }
}
export const getJobTodos = (params) => async (dispatch) => {
  try {
    const response = await request().get(`${TODOS_API_URL}${params}`, dispatch)
    dispatch(setJobTodos(response.data))
  } catch (err) {}
}

export const getJobById = (id) => async (dispatch) => {
  LeadService.getJobById(id).then((res) => dispatch(setSpecificLead(res)))
}

export const getJobToList = (id) => async (dispatch) => {
  try {
    const response = await getContactOrJobById(id, "job")
    if (response.data) dispatch(setJobToList(response.data))
    return response.data
  } catch (err) {
    throw err
  }
}
