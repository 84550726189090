import React, { useState } from "react"
import Modal from "antd/es/modal/Modal"
import { Button, Popconfirm } from "antd"
import {
  CloseCircleFilled,
  PaperClipOutlined,
  LinkOutlined,
} from "@ant-design/icons"
import withMemo from "../store/HOC/with_memo"
import { LinkItem } from "./notes/link_item"
import { DATE_FORMAT } from "../common/config"
import moment from "moment"
import Text from "antd/es/typography/Text"
import DeleteFile from "../assets/images/delete_file.svg"
import DeleteLink from "../assets/images/delete_link.svg"

const Chip = ({ chip, handleRemoveChip, handleDownloadFile }) => {
  const [openModal, setOpenModal] = useState(false)
  // const handleOpenModal = (e) => {
  //   e.stopPropagation()
  //   setOpenModal(true)
  // }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleClick = () => {
    handleRemoveChip(chip)
  }
  const isFile = React.useMemo(() => {
    return !!chip.orig_name
  }, [chip])
  return (
    <div
      className="chips-chip"
      style={{
        display: "flex",
        padding: "0 0 6px",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      <div
        className="chip-icon"
        style={{
          marginRight: "12px",
          marginTop: "3px",
        }}
      >
        {chip.orig_name ? (
          <PaperClipOutlined
            style={{
              color: "#8C8C8C",
              fontSize: "16px",
            }}
          />
        ) : (
          <LinkOutlined
            style={{
              color: "#8C8C8C",
              fontSize: "16px",
            }}
          />
        )}
      </div>
      <div
        className="link-text"
        style={{
          marginRight: "12px",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          paddingRight: "6px",
        }}
      >
        <LinkItem
          caption={chip.title || chip.caption || chip.orig_name}
          link={chip.url}
          file={isFile ? chip : {}}
          callback={isFile ? handleDownloadFile : undefined}
        />
      </div>
      <div
        className="link-date"
        style={{
          marginRight: "12px",
          whiteSpace: "nowrap",
          marginBottom: "2px",
        }}
      >
        <Text
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {moment(chip.create_dt).format(DATE_FORMAT)}
        </Text>
      </div>
      {handleRemoveChip && (
        <div
          className="chip-icon-close"
          style={{
            marginTop: "3px",
          }}
        >
          <Popconfirm
            placement="topRight"
            className="delete_file"
            title={`Delete ${chip.orig_name ? "File" : "Link"}? There is no undo.`}
            onConfirm={handleClick}
            okText="Delete"
            cancelText="Cancel"
          >

            <CloseCircleFilled
              style={{
                color: "rgba(0, 0, 0, 0.3)",
                fontSize: "16px",
              }}
            />
          </Popconfirm>

          <Modal
            className="delete-workspace-modal"
            open={openModal}
            onCancel={handleCloseModal}
            footer={null}
            closable={false}
          >
            <div className="df fd_col">
              <div className="df al-it_c">
                {chip.orig_name ? (
                  <img
                    className="change-password-modal__image"
                    src={DeleteFile}
                    alt="delete-file"
                  />
                ) : (
                  <img
                    className="change-password-modal__image"
                    src={DeleteLink}
                    alt="delete-link"
                  />
                )}
                <div className="df fd_col ml_21">
                  <span className="new-modals-title">
                    Delete {chip.orig_name ? "File?" : "Link?"}
                  </span>
                  <span className="new-modals-subtitle">
                    Are you sure you want to delete?
                  </span>
                </div>
              </div>
              <div className="delete-modal__actions mt_16">
                <Button
                  className="btn-style btn-cancel"
                  type="primary"
                  ghost
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button type="primary" danger onClick={handleClick}>
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default withMemo(Chip)
