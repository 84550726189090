import { Typography, Flex } from "antd"

import { CompassOutlined, MailOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"
import { URLS } from "../../common/urls"

import SupportInput from "../Support"

const { Title, Text } = Typography


export default function HelpComponent({ setBreadCrumb, breadCrumbs }) {
    return (
        <div
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                maxWidth: "750px",
                marginBottom: "82px"
            }}>
            <Title
                style={{
                    marginTop: "82px",
                    marginBottom: "20px"
                }}
            >
                Help
            </Title>
            <Text
                style={{
                    fontWeight: 500,
                    color: "#000000"
                }}
            >
                Everything you need to know to get started and get to work in Savvyy
            </Text>

            <Flex
                justify="flex-start"
                align="center"
                style={{
                    marginTop: "30px",
                    columnGap: "14px"
                }}>

                <CompassOutlined style={{
                    color: "#009EEF",
                    fontSize: "19px",
                    marginBottom: "-3px"
                }} />

                <Title
                    level={3}
                    style={{
                        marginBottom: "unset"
                    }}
                >
                    Intro to Savvyy
                </Title>
            </Flex>

            <Flex
                justify="flex-start"
                align="flex-start"
                style={{
                    marginTop: "24px",
                    flexWrap: "wrap",
                    columnGap: "128px",
                    rowGap: "14px"
                }}>

                <Link
                    style={{
                        maxWidth: "311px",
                        width: "100%"
                    }}
                    to={URLS.HELP}
                >
                    <Flex

                        justify="flex-start"
                        align="center"
                        cursor="pointer"

                    >

                        <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginBottom: "-2px" }}></div>

                        <Text
                            onClick={() => setBreadCrumb(breadCrumbs[1])}
                            className="bread-crumb"
                            style={{
                                fontWeight: 500,
                                color: "#000000",
                            }}
                        >
                            What is Savvyy?
                        </Text>

                    </Flex>
                </Link>

                <Link
                    style={{
                        maxWidth: "311px",
                        width: "100%"
                    }}
                    to={URLS.HELP}
                >
                    <Flex
                        justify="flex-start"
                        align="center"
                    >

                        <div style={{ borderRadius: "100%", background: "#009EEF", minWidth: "6px", minHeight: "6px", marginLeft: "6px", marginRight: "14px", marginBottom: "-2px" }}></div>

                        <Text
                            onClick={() => setBreadCrumb(breadCrumbs[2])}
                            className="bread-crumb"
                            style={{
                                fontWeight: 500,
                                color: "#000000",
                            }}
                        >
                            Glossary
                        </Text>

                    </Flex>
                </Link>

            </Flex>

            <div style={{ height: "1px", background: "#DADADA", margin: "40px 0" }}></div>

            <Flex
                justify="flex-start"
                align="center"
                style={{
                    marginTop: "30px",
                    columnGap: "14px",
                    marginBottom: "20px",
                }}>

                <MailOutlined style={{
                    color: "#009EEF",
                    fontSize: "19px",
                    marginBottom: "-3px"
                }} />

                <Title
                    level={3}
                    style={{
                        marginBottom: "unset"
                    }}
                >
                    Support
                </Title>
            </Flex>

            <Flex
                vertical
                style={{
                    rowGap: "14px"
                }}>

                <Text
                    style={{
                        fontWeight: 500,
                        color: "#000000"
                    }}
                >
                    Please let us know if you have any questions or comments using the text area below.
                </Text>

                <Text
                    style={{
                        fontWeight: 500,
                        color: "#000000"
                    }}
                >
                    You can also email us at <a href="mailto:help@savvyy.com" style={{ color: "#009EEF" }} rel="noopener noreferrer">help@savvyy.com</a>
                </Text>

                <Text
                    style={{
                        fontWeight: 500,
                        color: "#000000"
                    }}
                >
                    We'd love to hear from you!
                </Text>

            </Flex>

            <SupportInput />
        </div>
    )
}