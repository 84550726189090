import { JOB_API_URL } from "../common/constants"
import { request } from "./axios_helper"

export const getJobsData = () => {
  return request().get(JOB_API_URL)
}

export const updateJob = (data, id) => {
  return request().edit(`${JOB_API_URL}${id}/`, data, undefined, "patch")
}
export const getJobsByOptions = (params) => {
  return request().get(`${JOB_API_URL}${params}`)
}

export const create = (data) => {
  return request().create(JOB_API_URL, data)
}
