import { SET_NEW_WORKSPACE } from "../../action-types/workspaces"
import { setWorkspacePendingStatus } from "./getWorkspaces"
import { WorkspaceService } from "../../services/workspaceService"

export const setNewWorkspace = (payload) => {
  return {
    type: SET_NEW_WORKSPACE,
    payload,
  }
}

export const createWorkspaceThunk = (data) => (dispatch) => {
  dispatch(setWorkspacePendingStatus(true))
  WorkspaceService.createWorkspace(data)
    .then((res) => dispatch(setNewWorkspace(res)))
    .finally(() => dispatch(setWorkspacePendingStatus(false)))
}
