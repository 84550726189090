import {
  EDIT_NOTE_IN_DIARY,
  REMOVE_ACTIVITY_BY_KEY,
  REMOVE_DIARY_SUCCESS,
  RESET_ACTIVITIES,
  SET_CURRENT_DIARY_DATE,
  SET_DIARIES_ACTIVITIES,
  SET_DIARIES_ACTIVITIES_CONFIG,
  SET_DIARIES_ACTIVITIES_DATE,
  SET_DIARIES_ACTIVITY_PENDING,
  SET_DIARIES_DATA,
  SET_DIARIES_LOADING_PROGRESS,
  SET_NEW_DIARY,
  SET_TODAY_DIARY_PENDING,
  SET_UPDATED_DIARY,
  UPDATE_DIARY_ACTIVITY,
} from "../../action-types/diaries"
import { EDIT_TAG_SUCCESS } from "../../action-types/tags"
import moment from "moment"
import { FeaturesTypes } from "../../../common/constants"
import { REMOVE_COMPANY_FROM_ACTIVITY } from "../../action-types/companies"
import { isEmpty } from "lodash"
import { UPDATE_TODO, REMOVE_TODO } from "../../action-types/todos"
import { REMOVE_LINK_FROM_SPECIFIC_NOTE } from "../../action-types/notes"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  diaries: {},
  isPending: true,
  activity_is_pending: false,
  activity: {},
  activity_date: null,
  activity_config: {},
  current_activity: "",
  currentDiaryDate: "",
  todayDiaryPending: false,
}

export const diariesReducer = (state = initState, action) => {
  switch (action.type) {
    case REMOVE_ACTIVITY_BY_KEY: {
      return {
        ...state,
        activity: {
          ...state.activity,
          [action.activity_type]: [
            ...(state.activity[action.activity_type] || []),
          ]?.filter((item) => {
            return item.id !== action.payload
          }),
        },
      }
    }
    case RESET_APPLICATION_DATA: {
      return {
        ...state,
        diaries: {},
        isPending: false,
        activity_is_pending: false,
        activity: {},
        current_activity: "",
        currentDiaryDate: "",
        todayDiaryPending: false,
      }
    }
    case SET_TODAY_DIARY_PENDING: {
      return {
        ...state,
        todayDiaryPending: action.payload,
      }
    }
    case REMOVE_LINK_FROM_SPECIFIC_NOTE: {
      return {
        ...state,
        activity: {
          ...state.activity,
          [FeaturesTypes.NOTE]: [
            ...(state.activity[FeaturesTypes.NOTE] || []),
          ].map((item) => {
            return item.id === action.note.id
              ? {
                  ...item,
                  [action.key]: item[action.key].filter(
                    (val) => val.id !== action.payload.id
                  ),
                }
              : item
          }),
        },
      }
    }
    case REMOVE_COMPANY_FROM_ACTIVITY: {
      return {
        ...state,
        activity: {
          ...state.activity,
          [FeaturesTypes.COMPANY]: [
            ...(state.activity[FeaturesTypes.COMPANY] || []),
          ]?.filter((item) => {
            return item.id !== action.payload
          }),
        },
      }
    }
    case EDIT_NOTE_IN_DIARY: {
      return {
        ...state,
        activity: {
          ...state.activity,
          [FeaturesTypes.NOTE]: [
            ...(state.activity[FeaturesTypes.NOTE] || []),
          ]?.map((item) => {
            return item.id === action.payload.id ? action.payload : item
          }),
        },
      }
    }
    case SET_CURRENT_DIARY_DATE: {
      return {
        ...state,
        currentDiaryDate: action.payload,
      }
    }
    case UPDATE_DIARY_ACTIVITY: {
      const { data, activity_key } = action.payload
      if (isEmpty(state.activity)) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        activity: {
          ...state.activity,
          [activity_key]: [...(state.activity[activity_key] || [])].map(
            (item) => (item.id === data.id ? data : item)
          ),
        },
      }
    }
    case SET_DIARIES_ACTIVITIES_CONFIG: {
      return {
        ...state,
        activity_config: action.payload,
      }
    }
    case SET_DIARIES_ACTIVITIES_DATE: {
      return {
        ...state,
        activity_date: action.payload,
      }
    }
    case RESET_ACTIVITIES: {
      return {
        ...state,
        activity_date: null,
        activity_config: {},
        activity: {},
      }
    }
    case SET_UPDATED_DIARY: {
      const { create_dt } = action.payload
      const key = moment(create_dt.substring(0, create_dt.indexOf("T")))
        .startOf("day")
        .format("YYYY-MM-DD")
      const diary = state.diaries[key]
      return {
        ...state,
        diaries: {
          ...state.diaries,
          [key]: {
            ...diary,
            diary: action.payload,
          },
        },
      }
    }
    case REMOVE_DIARY_SUCCESS: {
      const diaries = { ...state.diaries }
      for (const key in diaries) {
        if (diaries?.[key]?.diary?.id === action.payload) {
          const hasActivities =
            Object.keys(diaries[key]).filter(
              (key) => key.trim() !== FeaturesTypes.DIARY
            ).length > 0
          if (hasActivities) {
            delete diaries[key].diary
          } else {
            delete diaries[key]
          }
        }
      }

      return {
        ...state,
        diaries: Object.assign({}, diaries),
      }
    }
    case SET_NEW_DIARY: {
      return {
        ...state,
        diaries: Object.assign(
          {},
          {
            ...state.diaries,
            ...{
              [state.currentDiaryDate]: {
                ...state.diaries[state.currentDiaryDate],
                diary: action.payload,
              },
            },
          }
        ),
      }
    }
    case SET_DIARIES_ACTIVITY_PENDING: {
      const activity = action.current_activity
        ? {
            ...state.activity,
            [action.current_activity]: [],
          }
        : {
            ...state.activity,
          }
      return {
        ...state,
        activity,
        activity_is_pending: action.payload,
      }
    }
    case SET_DIARIES_ACTIVITIES: {
      return {
        ...state,
        activity_is_pending: false,
        current_activity: action.activity_type,
        activity: {
          ...state.activity,
          [action.activity_type]: action.payload,
        },
      }
    }
    case SET_DIARIES_LOADING_PROGRESS: {
      return {
        ...state,
        pending: action.payload,
        isPending: action.payload,
      }
    }
    case SET_DIARIES_DATA: {
      return {
        ...state,
        isPending: false,
        diaries: action.payload,
      }
    }
    case UPDATE_TODO: {
      const { payload, status } = action
      if (!status) {
        return {
          ...state,
          activity: {
            ...state.activity,
            [FeaturesTypes.TODO]: [
              ...(state.activity[FeaturesTypes.TODO] || []),
            ].map((item) => (item.id === payload.id ? payload : item)),

            [FeaturesTypes.TODO_DONE]: [
              ...(state.activity[FeaturesTypes.TODO_DONE] || []),
            ].map((item) => (item.id === payload.id ? payload : item)),
          },
        }
      }
      return {
        ...state,
        activity: {
          ...state.activity,
          [status]: [...(state.activity[status] || [])].map((item) =>
            item.id === payload.id ? payload : item
          ),
        },
      }
    }
    case REMOVE_TODO: {
      if (!action.status) {
        return {
          ...state,
          activity: {
            ...state.activity,
            [FeaturesTypes.TODO]: [
              ...(state.activity[FeaturesTypes.TODO] || []),
            ].filter((item) => item.id !== action.payload),

            [FeaturesTypes.TODO_DONE]: [
              ...(state.activity[FeaturesTypes.TODO_DONE] || []),
            ].filter((item) => item.id !== action.payload),
          },
        }
      }
      return {
        ...state,
        activity: {
          ...state.activity,
          [action.status]: [...(state.activity[action.status] || [])]?.filter(
            (item) => {
              return item.id !== action.payload
            }
          ),
        },
      }
    }
    case EDIT_TAG_SUCCESS: {
      const newActivity = {}
      if (state.activity?.job?.length > 0) {
        newActivity.job = state.activity.job.map((item) => {
          if (item?.tags?.length > 0) {
            return {
              ...item,
              tags: item?.tags?.map((tag) => {
                if (tag.tag === action?.prevTag?.tag) {
                  return action.payload
                }
                return tag
              }),
            }
          } else {
            return item
          }
        })
      }
      if (state.activity?.communication?.length > 0) {
        newActivity.communication = state.activity.communication.map((item) => {
          if (item?.tags?.length > 0) {
            return {
              ...item,
              tags: item?.tags?.map((tag) => {
                if (tag.tag === action?.prevTag?.tag) {
                  return action.payload
                }
                return tag
              }),
            }
          } else {
            return item
          }
        })
      }

      if (state.activity?.contact?.length > 0) {
        newActivity.contact = state.activity.contact.map((item) => {
          if (item?.tags?.length > 0) {
            return {
              ...item,
              tags: item?.tags?.map((tag) => {
                if (tag.tag === action?.prevTag?.tag) {
                  return action.payload
                }
                return tag
              }),
            }
          } else {
            return item
          }
        })
      }

      if (state.activity?.note?.length > 0) {
        newActivity.note = state.activity.note.map((item) => {
          if (item?.tags?.length > 0) {
            return {
              ...item,
              tags: item?.tags?.map((tag) => {
                if (tag.tag === action?.prevTag?.tag) {
                  return action.payload
                }
                return tag
              }),
            }
          } else {
            return item
          }
        })
      }
      if (state.activity?.company?.length > 0) {
        newActivity.company = state.activity.company.map((item) => {
          if (item?.tags?.length > 0) {
            return {
              ...item,
              tags: item?.tags?.map((tag) => {
                if (tag.tag === action?.prevTag?.tag) {
                  return action.payload
                }
                return tag
              }),
            }
          } else {
            return item
          }
        })
      }
      return {
        ...state,
        activity: {
          ...state.activity,
          ...newActivity,
        },
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
