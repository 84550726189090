import {
  CREATE_LINK,
  SET_LINKS_PENDING_STATUS,
  SET_LINKS,
  SET_SPECIFIC_LINK,
  EDIT_LINK_SUCCESS,
  UPDATE_LINK,
} from "../../action-types/links"
import { REMOVE_LINK } from "../../action-types/notes"
import { REMOVE_TAG_SUCCESS } from "../../action-types/tags"
import { removeTagFromTables } from "../../../common/helpers"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  isPending: false,
  items: [],
  specificLink: {},
}

export const linksReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        isPending: false,
        items: [],
        specificLink: {},
      }
    }
    case REMOVE_TAG_SUCCESS: {
      return {
        ...state,
        items: state.items.map((link) => {
          return {
            ...link,
            tags: removeTagFromTables(link.tags, action.removedTag.tag),
          }
        }),
      }
    }
    case EDIT_LINK_SUCCESS: {
      return {
        ...state,
        items: state.items.map((item) => {
          return item.id === action.payload.id ? action.payload : item
        }),
      }
    }
    case SET_SPECIFIC_LINK: {
      return {
        ...state,
        specificLink: action.payload,
      }
    }
    case REMOVE_LINK: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      }
    }
    case CREATE_LINK: {
      return {
        ...state,
        items: state.items.concat(action.payload),
      }
    }
    case UPDATE_LINK: {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.linkId
      )
      if (itemIndex === -1) {
        return state
      }

      const newItems = [...state.items]
      newItems[itemIndex] = action.payload
      return {
        ...state,
        items: newItems,
      }
    }
    case SET_LINKS_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }
    case SET_LINKS: {
      return {
        ...state,
        items: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
