import { REVERT_JOB } from "../../action-types/jobs"
import { updateJobTH } from "./update-job"
import { setErrorMessage, setErrorState } from "../errors/set_errors"

const revertSuccess = () => {
  return {
    type: REVERT_JOB,
  }
}

export const revertJob = (data) => async (dispatch) => {
  try {
    dispatch(updateJobTH({ status_id: data.status_id, id: data.id }))
    dispatch(revertSuccess())
  } catch (err) {
    dispatch(setErrorMessage(err.message))
    dispatch(setErrorState(true))
  }
}
