import React from "react"
import { List, ConfigProvider, Empty, Spin } from "antd"
import { HorizontalNoteItem } from "./horizontal-note-item"
import withMemo from "../../store/HOC/with_memo"
import { FeaturesTypes } from "../../common/constants"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import NoData from "../../assets/images/Notes.svg"

export const NotesHorizontalList = withMemo(
  ({
    notes,
    is_pending = false,
    openEditor,
    allColors,
    disableDrag,
    cantDelete,
    isDrawer,
    addNew,
    isModal = false,
    isCompanyModal = false,
  }) => {

    return (
      <DndProvider backend={HTML5Backend}>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              className="app-empty"
              style={{ opacity: is_pending ? 0.5 : 1 }}
              image={<img src={NoData} alt="notes-icon" width={280} />}
              description={<span style={{ fontSize: 16 }}>“The discipline of writing something down is the first step toward making it happen.” ~ Lee Iacocca</span>}
            />
          )}
        >
          <div style={{ position: "relative" }}>
            <Spin spinning={is_pending} style={{
              position: "absolute", zIndex: 1, left: "49%", top: "50%"
            }} />
          </div>

          <List

            className="docs-notes"
            style={{
              overflowY: "scroll",
              border: "unset",
              height: "100%",
            }}
            rowKey={(row) => row.id}
            size="large"
            header={null}
            footer={null}
            bordered
            dataSource={notes}
            renderItem={(note) => {
              return (
                <List.Item
                  className="docs-first-list-item"
                  style={{ justifyContent: "normal", maxWidth: "100%" }}
                >
                  <HorizontalNoteItem
                    disableDrag={disableDrag}
                    allColors={allColors}
                    note={note}
                    openEditor={openEditor}
                    data={note}
                    cantDelete={cantDelete}
                    type={FeaturesTypes.NOTE}
                    isDrawer={isDrawer}
                    isModal={isModal}
                    isCompanyModal={isCompanyModal}
                  />
                </List.Item>
              )
            }}
          />
        </ConfigProvider>
      </DndProvider>
    )
  }
)
