import { saveAs } from "file-saver"
import { api } from "../../../common/config"
import { request } from "../../../api/axios_helper"
import { SET_EXACT_FILE_PENDING_STATUS } from "../../action-types/file_manager"
export const setExactFilePendingStatus = (payload) => ({
  type: SET_EXACT_FILE_PENDING_STATUS,
  payload,
})
export const downloadFile = (file) => (dispatch) => {
  dispatch(
    setExactFilePendingStatus({
      id: file.id,
      pending: true,
    })
  )
  request({ responseType: "blob" })
    .get(`${api.host}${api.urls.file}${file.id}/`)
    .then((res) => {
      const _file = new File([res.data], file.orig_name)
      saveAs(_file, file.orig_name)
    })
    .finally(() => dispatch(setExactFilePendingStatus({})))
}
