import { request } from "../../../api/axios_helper"
import { PAGE_TITLE_API_URL } from "../../../common/constants"

export const getPageTitle = async (url) => {
  try {
    const response = await request().create(PAGE_TITLE_API_URL, { url })
    return response.data.title
  } catch (err) {
    console.error(err)
  }
}
