import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getAllTagsSelector } from "../store/selectors/tags/get-tag-pending-status"
import { useAction } from "./useAction"
import { getAllTags } from "../store/actions/search/get-tags"
import { isEmpty } from "lodash"

export const useTags = () => {
  const allTags = useSelector(getAllTagsSelector)
  const getAll = useAction(getAllTags)
  useEffect(() => {
    if (isEmpty(allTags)) {
      getAll()
    }
    //eslint-disable-next-line
  }, [])

  return {
    allTags,
  }
}
