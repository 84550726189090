import React, { useEffect, useMemo, useRef } from "react"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"
import { Button, Input, Popover, Upload, Typography } from "antd"
import { LinkOutlined, PaperClipOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import { getSpecificNote } from "../../store/selectors/notes/get_notes_selector"
import { useUploadFile } from "../../hooks/useUploadFile"
import { setSpecificNote } from "../../store/actions/notes/create_note"
import { useDispatch } from "react-redux"

const { Title } = Typography

export const AttsBookmarksTab = withShouldUpdate(
  ({
    urlTitle,
    handleChangeUrl,
    handlePressEnter,
    url,
    popoverIsOpen,
    setPopoverIsOpen,
    link_is_pending,
    togglePopover,
    file_is_pending,
    createNoteBefore,
    created,
    setCreated,
    handFocusQuill
  }) => {
    const { onUpload } = useUploadFile()
    const urlInputRef = useRef()
    const note = useSelector(getSpecificNote)
    const dispatch = useDispatch()
    const uploadProps = useMemo(
      () => ({
        showUploadList: false,
        customRequest: async ({ file }) => {
          let _note = note
          if (!created && !note.id) {
            _note = await createNoteBefore()
            setCreated(true)
            dispatch(
              setSpecificNote({ ..._note, links: note.links, atts: note.atts })
            )
          }
          await onUpload(file, null, { key: "note_id", value: _note.id })
          handFocusQuill()
        },
        name: "file",
      }),
      [createNoteBefore, created, dispatch, note, onUpload, setCreated, handFocusQuill]
    )

    useEffect(() => {
      if (urlInputRef.current && popoverIsOpen) {
        setTimeout(() => {
          urlInputRef.current.focus()
        }, 100)
      }
    }, [urlInputRef, popoverIsOpen])

    const content = useMemo(() => {
      return (
        <div>
          {urlTitle && (
            <Title className="url-title" level={5}>
              {urlTitle}
            </Title>
          )}
          <Input
            ref={urlInputRef}
            value={url}
            onChange={handleChangeUrl}
            placeholder="url"
            onPressEnter={handlePressEnter}
          />
        </div>
      )
    }, [urlTitle, url, handleChangeUrl, handlePressEnter])
    return (
      <div className="d-flex">
        <Popover
          // placement="top"
          content={content}
          // trigger="click"
          // open={popoverIsOpen}
          onOpenChange={(visible) => {
            setPopoverIsOpen(visible)
          }}
        >
          <Button
            loading={link_is_pending}
            type={"primary"}
            icon={<LinkOutlined />}
            style={{ marginRight: "12px" }}
            onClick={togglePopover}
          >
            +Link
          </Button>
        </Popover>
        <Upload {...uploadProps} maxCount={1}>
          <Button
            loading={file_is_pending}
            type="primary"
            icon={<PaperClipOutlined />}
            style={{
              marginLeft: "auto",
            }}
          >
            +File
          </Button>
        </Upload>
      </div>
    )
  }
)
