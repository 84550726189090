import React, { useEffect, useState } from "react"
import { Input, Tag, Typography } from "antd"
import { TagsList } from "./TagsList"
import { SelectTagColor } from "./select_tag_color"
import { PlusOutlined } from "@ant-design/icons"
import { withAddTag } from "../store/HOC/withAddTag"
import { useColors } from "../hooks/useColors"
import { focusOnLastModal } from "../common/helpers"

const { Title } = Typography

const InputTag = ({
  linkInputEl,
  popOverVisible,
  addTagStep,
  handleKeyDown,
  tagsQuery,
  handleChange,
  hide,
}) => {
  return (
    <>
      <Input
        tabIndex="-1"
        ref={linkInputEl}
        visible={popOverVisible.toString()}
        style={addTagStep > 0 ? { opacity: "1" } : { opacity: "0", width: "0" }}
        className="contact-modal input-tag"
        onKeyDown={handleKeyDown}
        value={tagsQuery}
        onChange={handleChange}
      />
    </>
  )
}

const AddTag = ({
  currentTags,
  onRemoveTag,
  addTagStep,
  onAddTag,
  tagsQuery,
  changeTagsQuery,
  onSelectTag,
  openColorsAutocomplete,
  setAddTagStep = () => { },
  selectedTag,
  onCloseColorsAutoComplete,
  tags,
  tagInputRef
}) => {
  const { allColors } = useColors()
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  })
  const [popOverVisible, handleVisibleChange] = useState(false)
  const openMenu = (item, position) => {
    openColorsAutocomplete(item)
    setPosition({
      top: position.top - 36,
      left: position.left + position.width + 8 + 6,
    })
  }

  const unhide = () => {
    handleVisibleChange(true)
    setAddTagStep(1)
  }
  const hide = () => {
    handleVisibleChange(false)
    setAddTagStep(0)
  }
  const handleChange = (e) => {
    changeTagsQuery(e.target.value)
  }
  const outsideClick = (e) => {

    (e?.target?.className === "taggs"
      || e?.target?.className === "modal-tags")
      && popOverVisible && hide()
  }
  const handleKeyDown = (e) => {
    const {
      keyCode,
      currentTarget: { value: tagName },
    } = e
    if (keyCode === 27) {
      e.stopPropagation()
      hide()
      focusOnLastModal()
      return
    }

    const notExist = currentTags.every((item) => item.tag.trim() !== tagName)
    const canCall = (keyCode === 13 || keyCode === 32) && notExist
    const tag = [...(tags || [])].find((item) => item.tag === tagName.trim())
    if (canCall) {
      changeTagsQuery("")
      onAddTag(tag)
    }
  }
  useEffect(() => {
    if (addTagStep === 1) {
      tagInputRef.current?.focus()
    }
  }, [addTagStep, tagInputRef])

  return (
    <div onClick={outsideClick} className="contact-modal tag">
      <div className="taggs" style={{ marginBottom: "24px" }}>
        <div
          className="modal-tags"
          style={{
            display: "flex",
            height: "22px",
            width: "407px",
            marginTop: "12px",
            marginBottom: "6px",
          }}
        >
          <Title
            className="contact-modal title tags"
            level={5}
            style={{ display: "none" }}
          >
            Tags:
          </Title>
          {addTagStep < 1 && (
            <Tag
              className="contact-modal tag-plus"
              onClick={() => {
                unhide()
                tagInputRef.current?.focus()
              }}
            >
              <PlusOutlined /> New Tag
            </Tag>
          )}
          <div
            style={
              addTagStep > 0
                ? {
                  position: "relative",
                  marginBottom: "53px",
                  marginLeft: "auto",
                }
                : { width: "0px" }
            }
          >
            {addTagStep > 0 && (
              <InputTag
                linkInputEl={tagInputRef}
                popOverVisible={popOverVisible}
                addTagStep={addTagStep}
                handleKeyDown={handleKeyDown}
                tagsQuery={tagsQuery}
                handleChange={handleChange}
                hide={hide}
              />
            )}

            <div style={{ position: "absolute", bottom: "0", right: "0" }}>
              {addTagStep === 2 && (
                <SelectTagColor
                  setTagsStep={setAddTagStep}
                  changeTagsQuery={changeTagsQuery}
                  selectedTag={selectedTag}
                  onClose={onCloseColorsAutoComplete}
                  position={position}
                />
              )}
              {addTagStep > 0 && (
                <TagsList
                  allColors={allColors}
                  onSelectTag={onSelectTag}
                  allTags={tags}
                  onTagSelect={openMenu}
                />
              )}
            </div>
          </div>
        </div>
        {[...(currentTags || [])].map((item) => {
          return (
            <Tag
              onClick={unhide}
              closable
              onClose={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onRemoveTag(item)
              }}
              style={{
                borderRadius: "50px",
                marginRight: "5px",
                color: "#000000",
                marginBottom: "5px",
              }}
              color={allColors?.[String(item.color).toLowerCase()]?.code}
              key={`${item.tag}`}
            >
              {item.tag}
            </Tag>
          )
        })}
      </div>
    </div>
  )
}

export default withAddTag(AddTag)
