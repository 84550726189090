import {
  SET_DIARIES_ACTIVITIES,
  SET_DIARIES_ACTIVITY_PENDING,
  RESET_ACTIVITIES,
  SET_DIARIES_ACTIVITIES_CONFIG,
  SET_DIARIES_ACTIVITIES_DATE,
} from "../../action-types/diaries"
import { request } from "../../../api/axios_helper"
import { DIARIES_ACTIVITY_TYPE, FeaturesTypes } from "../../../common/constants"
import {
  getDiariesActivitiesConfigSelector,
  getDiariesActivitiesDateSelector,
} from "../../selectors/diaries/get_diaries_activities"
import { WorkspaceSelector } from "../../selectors/workspaces/get-workspace-list"

export const setDiariesActivities = (payload, activity_type) => {
  return {
    type: SET_DIARIES_ACTIVITIES,
    payload,
    activity_type,
  }
}
export const setDiariesActivitiesConfig = (payload) => {
  return {
    type: SET_DIARIES_ACTIVITIES_CONFIG,
    payload,
  }
}
export const setDiariesActivitiesDate = (payload) => {
  return {
    type: SET_DIARIES_ACTIVITIES_DATE,
    payload,
  }
}
const initActivity = { activity_type: "", date: "" }
export const setDiaryActivityPendingStatus = (payload, current_activity) => {
  return {
    type: SET_DIARIES_ACTIVITY_PENDING,
    payload,
    current_activity,
  }
}
export const getDiariesActivities =
  ({ activity_type, date } = initActivity) =>
  async (dispatch) => {
    dispatch(setDiaryActivityPendingStatus(true, activity_type))
    request()
      .get(`${DIARIES_ACTIVITY_TYPE}${activity_type}/?date=${date}`)
      .then((res) => {
        dispatch(setDiariesActivities(res.data, activity_type))
      })
      .finally(() => dispatch(setDiaryActivityPendingStatus(false, "")))
  }

export const thunkGetActivies = (config, date) => async (dispatch) => {
  dispatch(setDiariesActivitiesDate(date))
  dispatch(setDiariesActivitiesConfig(config))
  dispatch(loadDiariesActivies())
}

export const loadDiariesActivies = () => async (dispatch, getState) => {
  const state = getState()
  const _date = getDiariesActivitiesDateSelector(state)
  const config = getDiariesActivitiesConfigSelector(state)
  if (!_date) return
  const date = _date
    .split("-")
    .filter((item) => !!item)
    .join("")
  const { workspaces } = WorkspaceSelector(state)
  dispatch(
    setDiariesActivities(
      workspaces.filter(
        (e) => e?.last_edit_dt?.slice(0, 10).split("-").join("") === date
      ),
      FeaturesTypes.workspace
    )
  )
  Object.keys(config)
    .filter((key) => key !== "diary")
    .forEach((activity_type) => {
      dispatch(getDiariesActivities({ activity_type, date }))
    })
}

export const resetActivities = () => ({
  type: RESET_ACTIVITIES,
})
