import React, { useCallback, useEffect, useState } from "react"
import "../styles/verify.css"
import { PasswordReset, VerifyEmailAlert, VerifyPageForm } from "../components"
import { useDispatch, useSelector } from "react-redux"
import { getUserData, getActionFromRedirectProfile, getStripeSecret } from "../store/selectors/user/getUserData"
import { notification, Typography, Tabs } from "antd"
import {
  editProfile,
  email_verify,
  getMe,
  resetPassword,
} from "../store/actions/user"
import { isEmpty } from "lodash"
import { URLS } from "../common/urls"
import { useHistory, useLocation } from "react-router-dom"
import { getLoginStatus } from "../store/selectors/user/get-login-status"
import BillingComponent from "../components/billing/billing"
import { getPaymentInfo, updateStripeSubscription } from "../api/billing"

const { Title } = Typography

export const VerifyAccount = () => {
  const profile = useSelector(getUserData)
  const action = useSelector(getActionFromRedirectProfile)
  const clientSecret = useSelector(getStripeSecret)
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [isRedirected, setIsRedirected] = useState(false)
  const dispatch = useDispatch()
  const [emailIsChanged, setEmailIsChanged] = useState(false)
  const [defaultKey, setDefaultKey] = useState("1")
  const loginIsChousen = useSelector(getLoginStatus)
  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false)
  const location = useLocation()
  const history = useHistory()


  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const redirectStatus = params.get("redirect_status")
    const setup_intent_id = params.get("setup_intent")


    if (redirectStatus === "succeeded") {
      setIsRedirected(true)
      setDefaultKey("2")
      if (setup_intent_id) {
        updateStripeSubscription({ setup_intent_id }).then((res) => {
          getPaymentInfo()
            .then((r) => {
              setPaymentInfo(r.data)
            })
        })
      }
      history.replace({ search: "" })
    } else if (profile?.profile?.redirect_profile?.next === "subscription") {
      setDefaultKey("2")
    } else if (profile?.profile?.redirect_profile?.next === "profile") {
      if (isRedirected) setDefaultKey("2")
      else setDefaultKey("1")
    }

  }, [history, isRedirected, location.search, profile?.profile?.redirect_profile?.next])
  useEffect(() => {
    if (clientSecret) {
      setDefaultKey("2")
    }
  }, [clientSecret])

  const openNotification = () => {
    notification.open({
      message: `Thanks ${profile?.email}`,
      description:
        "The verification link has been will be sent to your account",
      onClick: () => { },
    })
  }
  const onVerifyAcc = () => {
    openNotification()
    dispatch(email_verify(profile.email))
  }
  const canContinue = useCallback(() => {
    return Boolean(loginIsChousen !== undefined && !loginIsChousen)
  }, [loginIsChousen])
  const handleDoneTyping = useCallback(
    (username) => {
      dispatch(editProfile({ username }))
    },
    [dispatch]
  )
  const handleSubmitEditProfile = (data, canChangeEmail) => {
    if (!isEmpty(data)) {
      dispatch(editProfile(data, emailIsChanged && canChangeEmail)).then(
        (res) => {
          !loginIsChousen && res && history.push(URLS.HOME)
        }
      )
    }
    setEmailIsChanged(false)
    if (data["tz"] && canContinue()) {
      dispatch(getMe())
    }
  }
  const handlePasswordReset = () => {
    dispatch(resetPassword(profile.email))
    setOpenPasswordResetModal(true)
  }
  useEffect(() => {
    document.title = "Savvyy | Settings"
  }, [])

  const tabs = [
    {
      label: `Profile`,
      key: "1",
      children: (
        <>
          <Title
            style={{
              marginBottom: "32px",
              marginTop: "44px",
            }}
          >
            Settings
          </Title>
          {!isEmpty(profile) && !profile?.has_verified_email && (
            <VerifyEmailAlert onVerifyAcc={onVerifyAcc} />
          )}
          {!isEmpty(profile) && (
            <VerifyPageForm
              handleDoneTyping={handleDoneTyping}
              handlePasswordReset={handlePasswordReset}
              loginIsChousen={loginIsChousen}
              profile={profile}
              onSubmit={handleSubmitEditProfile}
              changeEmailStatus={setEmailIsChanged}
            />
          )}
          {openPasswordResetModal && (
            <PasswordReset
              visible={openPasswordResetModal}
              handleClose={() => setOpenPasswordResetModal(false)}
              username={profile.username}
            />
          )}

        </>
      ),
    },
    {
      label: `Subscription`,
      key: "2",
      children: (
        <BillingComponent
          clientSecret={clientSecret}
          profile={profile}
          action={action}
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
        />
      ),
    }
  ]


  return (

    <div className="verify_container">
      <Tabs activeKey={defaultKey} onChange={setDefaultKey} items={tabs} type="card" />
    </div>

  )
}

