import { useDispatch } from "react-redux"
import { useCallback } from "react"

export const useAction = (actionCreator) => {
  const dispatch = useDispatch()
  return useCallback(
    async (...args) => {
      return dispatch(actionCreator(...args))
    },
    [actionCreator, dispatch]
  )
}
