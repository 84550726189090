import React from "react"
import { AppleFilled } from "@ant-design/icons";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google"
import AppleSignin from 'react-apple-signin-auth';
import { useHistory } from "react-router-dom"
import { Button } from "antd";
import { ReactComponent as GoogleIcon } from "../assets/images/google_icon.svg";
import { config } from "../common/config"
import { useDispatch } from "react-redux";
import { authWithGoogle, authWithApple } from '../store/actions/user'
import { URLS } from "../common/urls";

const buttonStyle = {
  width: "100%",
  borderRadius: "2px",
  boxShadow: "0px 2px 0px 0px #0000000B",
  height: "32px",
  color: "rgba(0, 0, 0, 0.65)",
  background: "#ffffff",
  borderColor: "",
  marginBottom: "8px"
}


const MyButtonGoogle = ({ register, dispatch, history }) => {


  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      localStorage.setItem('logged_in', 1)
      dispatch(authWithGoogle(tokenResponse?.access_token)).then(() => history.push(URLS.HOME))
    },
    onError: e => console.log(e)
  });
  return <Button
    style={buttonStyle}
    type="primary"
    onClick={login}
  >
    <GoogleIcon style={{ fontSize: "14px", marginRight: "8px", marginBottom: "-2px" }} />
    Sign {register ? 'up' : 'in'} with Google
  </Button>
}

const MyAppleSigninButton = ({ register, dispatch, history }) => {
  const onSuccess = ({ authorization: { code, id_token } }) => {
    localStorage.setItem('logged_in', 1)
    dispatch(authWithApple({ code, id_token })).then(() => history.push(URLS.HOME))
  }
  const onError = (error) => {
    console.log(error)
  }
  return (
    <AppleSignin
      authOptions={{
        clientId: config.apple_client_Id,
        scope: 'email name',
        redirectURI: config.apple_redirect_uri,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-btn"
      noDefaultStyle={false}
      buttonExtraChildren="Continue with Apple"
      onSuccess={onSuccess}
      onError={onError}
      skipScript={false}
      render={(props) => (
        <Button {...props} style={{ ...buttonStyle, marginBottom: 0, paddingLeft: 4.5 }}>
          <AppleFilled style={{ fontSize: 17 }} />
          Sign {register ? 'up' : 'in'} with Apple</Button>
      )}
    />
  );
}

export const SocialLogin = ({ register = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <div style={{ marginBottom: "24px" }}>
      <GoogleOAuthProvider clientId={config.google_client_id}>
        <MyButtonGoogle register={register} dispatch={dispatch} history={history} />
      </GoogleOAuthProvider>
      <MyAppleSigninButton register={register} dispatch={dispatch} history={history} />

    </div>
  )
}
