
import { useCallback } from 'react';
import { Modal } from 'antd'
import { useAction } from '../../hooks/useAction';
import { FeaturesTypes } from '../../common/constants';
import { getMe } from '../../store/actions/user';


const WhatTodoModal = ({ open, onClose, handleClick, startGuide }) => {
    const getMeAction = useAction(getMe)

    const handleOpenNewModal = useCallback(async (type) => {
        await onClose()
        const props = { isAdd: true, status: "add", isEdit: false, callBackFun: getMeAction }
        if (type.toLowerCase().includes(FeaturesTypes.CONTACT)) {
            handleClick({ id: "newContact" }, FeaturesTypes.CONTACT, {}, props)
        }
        if (type.toLowerCase().includes(FeaturesTypes.LEAD)) {
            handleClick({ id: "newJob" }, FeaturesTypes.LEAD, {}, props)
        }
        if (type.toLowerCase().includes("compan")) {
            handleClick({ id: "newCompany" }, FeaturesTypes.COMPANY, {}, props)
        }
        if (type.toLowerCase().includes("task")) {
            document.querySelector('[placeholder="What needs doing?"]')?.focus()
        }
    }, [handleClick, onClose, getMeAction])

    return (
        <Modal
            title="What to do ..."
            centered
            open={open}
            closable
            onCancel={onClose}
            width={1000}
            footer={null}
        >
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", padding: "50px" }}>
                {Object.values(startGuide).map((key) => {
                    return (
                        <div
                            key={key}
                            className="custom-tab"
                            style={{
                                display: "block",
                                width: "350px",
                                height: "200px",
                                fontSize: "30px",
                                margin: 20,
                                paddingTop: "70px",
                                textAlign: "center"
                            }} onClick={() => handleOpenNewModal(key)}
                        >
                            {key}
                        </div>
                    )

                })}
            </div>

        </Modal>
    );
};

export default WhatTodoModal;