import {
  CREATE_COMMUNICATION,
  RESET_DRAFT_COMMUNICATIONS,
  SET_SPECIFIC_COMMUNICATION,
} from "../../action-types/communication"
import { setCommunications } from "./get-communications"
import { request } from "../../../api/axios_helper"
import { COMMUNICATION_API_URL } from "../../../common/constants"

export const created = (payload) => {
  return {
    type: CREATE_COMMUNICATION,
    payload,
  }
}
export const setSpecificCommunication = (payload) => ({
  type: SET_SPECIFIC_COMMUNICATION,
  payload,
})
export const createCommunicationTH = (data, isEdit) => async (dispatch) => {
  try {
    const res = await request().create(COMMUNICATION_API_URL, data, dispatch)
    if (res?.status === 201) {
      if (isEdit) {
        dispatch(setCommunications(res.data))
      } else {
        dispatch(created(res.data))
      }
    }
    return res.data
  } catch (err) {}
}

export const resetDrafts = () => ({ type: RESET_DRAFT_COMMUNICATIONS })
