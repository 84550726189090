import React, { useCallback } from "react"
import { Button, Modal } from "antd"
import ResetBanner from "../../assets/images/change_password_svy.svg"

export const PasswordReset = ({ visible, handleClose, username }) => {
  const renderContent = useCallback(() => {
    return "We have emailed you a password reset link. Please check your inbox and follow the instructions. Thank you."
  }, [])
  return (
    <Modal
      className="change-password-modal"
      header={null}
      footer={null}
      open={visible}
      onCancel={handleClose}
      closable={false}
    >
      <div className="df fd_col">
        <div className="df al-it_c">
          <img
            className="change-password-modal__image"
            src={ResetBanner}
            alt="reset password"
          />
          <span className="delete-modal__subtitle ml_21">
            {renderContent()}
          </span>
        </div>
        <Button
          className="btn-style-wh mt_16 ml_a"
          type="primary"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
}
