import {
  CREATE_NOTE,
  EDIT_NOTE,
  EDIT_SPECIFIC_NOTE_WITH_LINKS,
  FETCH_NOTES,
  REMOVE_LINK_FROM_SPECIFIC_NOTE,
  REMOVE_NOTE,
  SET_NOTE_PENDING_STATUS,
  SET_SPECIFIC_NOTE,
  SET_DRAFT_TAGS_NOTE,
} from "../../action-types/notes"
import { EDIT_TAG_SUCCESS, REMOVE_TAG_SUCCESS } from "../../action-types/tags"
import { isEmpty } from "lodash"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  items: [],
  specificNote: {},
  isPending: null,
  draftTags: [],
}

export const notesReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        items: [],
        specificNote: {},
        isPending: null,
        draftTags: [],
      }
    }

    case REMOVE_TAG_SUCCESS: {
      const draftTags = state.draftTags?.filter(
        (item) => item?.tag !== action.removedTag.tag
      )
      return {
        ...state,
        draftTags,
        items: state.items?.map((note) => {
          return {
            ...note,
            tags: note?.tags?.filter(
              (tag) => tag?.tag !== action.removedTag.tag
            ),
          }
        }),
        specificNote: {
          ...state.specificNote,
          tags: state.specificNote?.tags?.filter(
            (tag) => tag.tag !== action.removedTag.tag
          ),
        },
      }
    }
    case EDIT_TAG_SUCCESS: {
      const updatedSpecificNote = isEmpty(state.specificNote?.tags)
        ? state.specificNote
        : {
            ...state.specificNote,
            tags: state.specificNote?.tags?.map((tag) => {
              if (tag.tag === action.prevTag.tag) {
                return action.payload
              } else {
                return tag
              }
            }),
          }
      const newItems = !isEmpty(state.items)
        ? state.items?.map((note) => {
            if (note?.tags.some((tag) => tag?.tag === action?.prevTag?.tag)) {
              return {
                ...note,
                tags: note?.tags?.map((tag) => {
                  if (tag?.tag === action?.prevTag?.tag) {
                    return action?.payload
                  } else {
                    return tag
                  }
                }),
              }
            } else {
              return note
            }
          })
        : state.items

      const draftItems = state?.draftTags?.map((tag) => {
        if (tag?.tag === action?.prevTag?.tag) {
          return action?.payload
        } else {
          return tag
        }
      })
      return {
        ...state,
        items: newItems,
        specificNote: updatedSpecificNote,
        draftTags: draftItems,
      }
    }
    case REMOVE_LINK_FROM_SPECIFIC_NOTE: {
      return {
        ...state,
        items: state.items.map((item) => {
          return item.id === action.note.id
            ? {
                ...item,
                [action.key]: item[action.key].filter(
                  (val) => val.id !== action.payload.id
                ),
              }
            : item
        }),
        specificNote: {
          ...state.specificNote,
          [action.key]: state.specificNote[action.key].filter(
            (item) => item.id !== action.payload.id
          ),
        },
      }
    }
    case EDIT_SPECIFIC_NOTE_WITH_LINKS: {
      const specificNoteIndex = state.items.findIndex(
        (item) => item.id === state.specificNote.id
      )
      let items = [...state.items]
      if (specificNoteIndex === -1) {
        items = [state.specificNote].concat(state.items)
        // return state
      }

      const specificNote = { ...state.specificNote }
      const modifiedList = [...(state.specificNote[action.key] || [])]
      const modifiedItemIndex = modifiedList.findIndex(
        (item) => item.id === action.payload.id
      )
      if (modifiedItemIndex === -1) {
        modifiedList.push(action.payload)
      } else {
        modifiedList[modifiedItemIndex] = action.payload
      }

      specificNote[action.key] = modifiedList
      items[specificNoteIndex] = specificNote
      return {
        ...state,
        items,
        specificNote,
      }
    }
    case REMOVE_NOTE: {
      return {
        ...state,
        items: state.items.filter((item) => {
          return item.id !== action.payload
        }),
      }
    }
    case FETCH_NOTES: {
      return {
        ...state,
        items: action.payload,
      }
    }
    case CREATE_NOTE: {
      return {
        ...state,
        items: [action.payload].concat(state.items),
      }
    }
    case EDIT_NOTE: {
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        specificNote: action.payload,
      }
    }
    case SET_SPECIFIC_NOTE: {
      return {
        ...state,
        specificNote: action.payload,
      }
    }
    case SET_NOTE_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }
    case SET_DRAFT_TAGS_NOTE: {
      return {
        ...state,
        draftTags: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
