import { SET_LINKS_PENDING_STATUS, SET_LINKS } from "../../action-types/links"
import { LINKS_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"

export const setLinks = (payload) => ({ type: SET_LINKS, payload })
export const setLinksPendingStatus = (payload) => ({
  type: SET_LINKS_PENDING_STATUS,
  payload,
})

export const getLinksByParams = (params) => async (dispatch) => {
  dispatch(setLinksPendingStatus(true))
  await request().get(`${LINKS_API_URL}${params}`, dispatch, (res) => {
    dispatch(setLinks(res.data))
  })
  dispatch(setLinksPendingStatus(false))
}
