import { REMOVE_NOTE, FILTER_COMPANY_NOTES } from "../../action-types/notes"
import { request } from "../../../api/axios_helper"
import { NOTE_API_URL } from "../../../common/constants"
import { setNotesPendingStatus } from "./get_notes"
import { removeSearchResult } from "../search/get-search"
import { removeDiaryActivity } from "../diaries/delete-diary"
import { resetFilters } from "../filters"
import { FeaturesTypes } from "../../../common/constants"

export const removeNote = (payload) => {
  return {
    type: REMOVE_NOTE,
    payload,
  }
}
export const deleteNote = (id) => async (dispatch) => {
  await request().delete(`${NOTE_API_URL}${id}/`, dispatch, () => {
    dispatch(removeNote(id))
    dispatch(removeSearchResult(id, FeaturesTypes.NOTE))
    dispatch(removeDiaryActivity({ id, activity_type: FeaturesTypes.NOTE }))
    dispatch(resetFilters())
  })
}
export const filterCompanyNotes = (payload) => ({
  type: FILTER_COMPANY_NOTES,
  payload,
})

export const removeNoteInCompany = (note) => (dispatch) => {
  dispatch(setNotesPendingStatus(true))
  request()
    .delete(`${NOTE_API_URL}${note.id}/`, dispatch)
    .then(() => dispatch(filterCompanyNotes(note)))
    .finally(() => dispatch(setNotesPendingStatus(false)))
}
