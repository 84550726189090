export const URLS = {
  ROOT: "/",
  LOGIN: "/login",
  HOME: "/home",
  HELP: "/help",
  LEADS: "/leads",
  CONTACTS: "/contacts",
  COMPANY: "/company",
  LIBRARY: "/library",
  VERIFY: "/verify",
  SEARCH: "/search",
  FORGOT_PASS: "/forgot_password",
  FORGOT_PASS_CONFIRM: "/forgot-password/confirm",
  REGISTER: "/register",
}

export const ContactPageFilterTabs = {
  most_recently_contacted: "/contacts/most_recently_contacted",
  most_contacted: "/contacts/most_contacted",
  oldest: "/contacts/oldest",
}

export const CompanyPageFilter = {
  by_name: "#byname",
  by_industry: "#byindustry",
  by_type: "#bytype",
}
