import React from "react"
import { Notes } from "../components/notes/Notes"
import { useDocs } from "../hooks/useDocs"
import { FilterByTag } from "../components/docs/filter_by_tag"
import { Col, Row } from "antd"
import withShouldUpdate from "../store/HOC/withShouldUpdate"
import { WorkspacesTabs } from "../components/workspaces/workspaces-tabs"
import { HTML5Backend } from "react-dnd-html5-backend"

import "../styles/notes.css"
import { DndProvider } from "react-dnd"
import { useTags } from "../hooks/useTags"

const Docs = () => {
  useTags()
  const {
    tags,
    allColors,
    filterByTagStyles,
    filteredNotes,
    tagListStyles,
    // workspaces_is_pending,
    workspaces,
    handleOpenWorkspaceModal,
    handleEditWorkspace,
    editWorkspace,
    setEditWorkspace,
    current_workspace,
    handleRemoveWorkspace,
    resetFiltersAction,
    handleCloseWorkspaceModal,
    showFilterByTag,
    isNotePending
  } = useDocs()
  return (
    <DndProvider backend={HTML5Backend}>
      <Row className="library-container" style={{ pointerEvents: isNotePending ? "none" : "auto" }}>
        <Col span={24}>
          <WorkspacesTabs
            handleCloseWorkspaceModal={handleCloseWorkspaceModal}
            // workspaces_is_pending={workspaces_is_pending}
            workspaces={workspaces}
            handleOpenWorkspaceModal={handleOpenWorkspaceModal}
            handleEditWorkspace={handleEditWorkspace}
            handleRemoveWorkspace={handleRemoveWorkspace}
            isEdit={editWorkspace}
            setEditWorkspace={setEditWorkspace}
            current_workspace={current_workspace}
          />
          <div
            style={{
              marginTop: "16px",
            }}
          >
            {showFilterByTag && (
              <FilterByTag
                allColors={allColors}
                closable
                tags={tags}
                style={filterByTagStyles}
                tagListStyles={tagListStyles}
                resetFilters={resetFiltersAction}
              />
            )}
            <Notes notes={filteredNotes} allColors={allColors} />
          </div>
        </Col>
      </Row>
    </DndProvider>
  )
}

export default withShouldUpdate(Docs)
