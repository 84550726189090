import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { Radio, ConfigProvider } from "antd"
import { JOB_APPLIED, JOB_DISCARDED, JOB_NOT_APPLIED } from "../../common/constants"
import { URLS } from "../../common/urls"
import { applyJob } from "../../store/actions/jobs/update-job"
import { createTodoTH } from "../../store/actions/todos/create_todo"
import { deleteTodo } from "../../store/actions/todos/delete"
import { getTodos } from "../../store/actions"
import withMemo from "../../store/HOC/with_memo"
import moment from "moment"
import { getUserSubscriptionTypeSelector } from "../../store/selectors"
import { useAction } from "../../hooks/useAction"

const JobApplySwitcher = ({ job }) => {
    const location = useLocation()
    const subType = useSelector(getUserSubscriptionTypeSelector)
    const applyJobAction = useAction(applyJob)
    const createTodoAction = useAction(createTodoTH)
    const getTodosAction = useAction(getTodos)
    const deleteTodoAction = useAction(deleteTodo)

    const getTodosIfNeed = useCallback(async () => {
        if (location.pathname === URLS.HOME) {
            await getTodosAction()
        }
    }, [getTodosAction, location.pathname])
    const toggleApplied = useCallback(async (e) => {

        const status_id = e.target.value
        const original_status_id = job.status_id
        if (status_id === original_status_id) {
            return
        }
        if (original_status_id === JOB_NOT_APPLIED) {
            const due_dt = moment().add(1, "week").toISOString()
            const d = {
                job: job.id,
                description: "follow up",
                status_id: 1,
                due_dt,
                lead_follow_up: true,
            }
            await applyJobAction(job.id, {
                status_id,
                apply_dt: moment().toISOString(),
            })
            await createTodoAction(d, null, true)
            await getTodosIfNeed()

        } else if (original_status_id === JOB_APPLIED) {
            await applyJobAction(job.id, {
                status_id,
                apply_dt: null,
            })
            const onDeleteTodo = job?.todos.find((todo) => todo.status_id === 1)
            if (onDeleteTodo) {
                await deleteTodoAction(onDeleteTodo.id, job.id)
            }

        } else if (original_status_id === JOB_DISCARDED) {
            await applyJobAction(job.id, {
                status_id,
                apply_dt: null,
            })

            await getTodosIfNeed()

        }

    }, [applyJobAction, createTodoAction, deleteTodoAction, getTodosIfNeed, job.id, job.status_id, job?.todos])
    return (
        <div onClick={(e) => {
            e.stopPropagation()
        }}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "white",
                    },
                    components: {
                        Radio: {
                            fontSize: "12px",
                        }
                    }
                }}
            >
                <Radio.Group size="small" onChange={toggleApplied} className="lead-switcher" value={job.status_id}>
                    <Radio.Button className="radio-new" value={1}>New</Radio.Button>
                    <Radio.Button className="radio-active" value={2}>{subType === "SEARCH" ? "Applied" : "Active"} </Radio.Button>
                </Radio.Group>
            </ConfigProvider>
        </div>
    )
}

export default withMemo(JobApplySwitcher)
