export const SET_ERROR_STATE = "SET_ERROR_STATE"
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE"

export const setErrorState = (state) => {
  return {
    type: SET_ERROR_STATE,
    payload: state,
  }
}

export const setErrorMessage = (message) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: message,
  }
}
