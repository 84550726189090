import {
  UPDATE_FILTERS,
  RESET_FILTERS,
  FILTER_BY_TAGS,
  REMOVE_TAG_FROM_FILTERS,
} from "../../action-types/filters"
import { UPDATE_JOB_FILTERS } from "../../action-types/jobs"

export const updateFilters = (payload) => {
  return {
    type: UPDATE_FILTERS,
    payload,
  }
}
export const resetFilters = () => {
  return {
    type: RESET_FILTERS,
  }
}

export const updateJobFilters = (payload) => {
  return {
    type: UPDATE_JOB_FILTERS,
    payload,
  }
}
export const setTagsFilter = (payload) => {
  return {
    type: FILTER_BY_TAGS,
    payload,
  }
}

export const removeTagFromFilters = (payload) => {
  return {
    type: REMOVE_TAG_FROM_FILTERS,
    payload,
  }
}
