import React from "react"
import { NoteItem } from "./note_item"
import { useDispatch } from "react-redux"
import withMemo from "../../store/HOC/with_memo"

export const NotesList = withMemo(({ openEditor, notes }) => {
  const dispatch = useDispatch()
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {notes.map((note) => {
        return (
          <div key={note.id}>
            <NoteItem
              note={note}
              handleOpenEditor={openEditor}
              dispatch={dispatch}
            />
          </div>
        )
      })}
    </div>
  )
})
