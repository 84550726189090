import {
  REMOVE_ACTIVITY_BY_KEY,
  REMOVE_DIARY_SUCCESS,
} from "../../action-types/diaries"
import { request } from "../../../api/axios_helper"
import { DIARIES_API_URL } from "../../../common/constants"
import { setDiariesLoadingProgress } from "./get-diaries"

export const removeDiary = (payload) => ({
  type: REMOVE_DIARY_SUCCESS,
  payload,
})
export const removeDiaryActivity = (info) => ({
  type: REMOVE_ACTIVITY_BY_KEY,
  payload: info.id,
  activity_type: info.activity_type,
})

export const removeDiaryTH = (diary_id) => (dispatch) => {
  dispatch(setDiariesLoadingProgress(true))
  request()
    .delete(`${DIARIES_API_URL}${diary_id}/`)
    .then(() => dispatch(removeDiary(diary_id)))
    .finally(() => dispatch(setDiariesLoadingProgress(false)))
}
