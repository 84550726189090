import {
  WORKSPACE_API_URL,
  WORKSPACE_NOTES_API_URL,
} from "../../common/constants"
import { request } from "../../api/axios_helper"

export class WorkspaceService {
  static async getWorkspaces() {
    try {
      const response = await request().get(WORKSPACE_API_URL)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async getWorkspaceNotes(workspace_id) {
    try {
      const response = await request().get(
        `${WORKSPACE_NOTES_API_URL}${workspace_id}/`
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async createWorkspace(data) {
    try {
      const response = await request().create(WORKSPACE_API_URL, data)
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async editWorkspace(data) {
    try {
      const response = await request().edit(
        `${WORKSPACE_API_URL}${data.id}/`,
        data,
        undefined,
        "put"
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async removeWorkspace(workspace_id) {
    try {
      const response = await request().delete(
        `${WORKSPACE_API_URL}${workspace_id}/`
      )
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}
