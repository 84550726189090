import { useState, useCallback } from "react"
import { Modal, Button, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"


export const DeleteAccount = ({ onDelete }) => {
    const [isVisible, setIsVisible] = useState(false)
    const onClose = () => setIsVisible(false)
    const onOpen = () => setIsVisible(true)

    const onDeleteAccount = useCallback(() => {
        onClose()
        onDelete()
    }, [onDelete])

    return (
        <>
            <Button style={{ marginLeft: 30, fontWeight:"bold" }}  danger onClick={onOpen}>DELETE ACCOUNT</Button>
            <Modal
                className="delete-lead"
                open={isVisible}
                footer={null}
                onCancel={onClose}
                closable={false}

            >
                <div className="df fd_col">
                    <div className="df al-it_c">
                        <Avatar
                            size={40}
                            icon={<UserOutlined />}
                            style={{
                                backgroundColor: "#009EEF",
                                width: '100px'
                            }}
                            gap={4}
                        />
                        <div className="df fd_col ml_21">
                            <span className="new-modals-title">Delete account?</span>
                            <span className="new-modals-subtitle">
                                Please confirm that you want to delete your account and all underlying data. This can NOT be undone.
                            </span>
                        </div>
                    </div>
                    <div className="delete-modal__actions mt_16">
                        <Button
                            className="btn-style btn-cancel"
                            type="primary"
                            ghost
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" danger onClick={onDeleteAccount}>
                            DELETE
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
