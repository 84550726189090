import {
  REMOVE_COMPANY,
  REMOVE_COMPANY_FROM_ACTIVITY,
  SET_COMPANY_PENDING_STATUS,
} from "../../action-types/companies"
import { request } from "../../../api/axios_helper"
import { COMPANY_API_URL, FeaturesTypes } from "../../../common/constants"
import { getSpecificNote } from "../../selectors/notes/get_notes_selector"
import { REMOVE_LINK_FROM_SPECIFIC_NOTE } from "../../action-types/notes"
import { removeFile } from "../file_manager/upload_file"
import { removeLink } from "../notes/remove_link"
import { CompanyNotesSelector } from "../../selectors"
import { isEmpty } from "lodash"
import { deleteNote } from "../notes/remove"
import { setCompanyNotes } from "./get-companies"
import { removeSearchResult } from "../search/get-search"
import { removeDiaryActivity } from "../diaries/delete-diary"

export const filterCompanies = (id) => ({ type: REMOVE_COMPANY, payload: id })
export const setPendingStatus = (status) => {
  return {
    type: SET_COMPANY_PENDING_STATUS,
    payload: status,
  }
}
export const removeCompany = (id) => async (dispatch) => {
  try {
    dispatch(setPendingStatus(true))
    await request().delete(`${COMPANY_API_URL}${id}/`, dispatch, () => {
      dispatch(filterCompanies(id))
      dispatch(removeSearchResult(id, FeaturesTypes.COMPANY))
      dispatch(
        removeDiaryActivity({ id, activity_type: FeaturesTypes.COMPANY })
      )
    })
    dispatch(setPendingStatus(false))
  } catch (err) {}
}
export const removeChipFromSpecificNote = (payload, key, note) => ({
  type: REMOVE_LINK_FROM_SPECIFIC_NOTE,
  payload,
  key,
  note,
})
export const removeChipFromCompanyNote =
  (chip, key) => (dispatch, getState) => {
    const note = getSpecificNote(getState())
    if (key === "links") {
      dispatch(removeLink(chip.id))
    } else {
      dispatch(removeFile(chip.id))
    }
    dispatch(removeChipFromSpecificNote(chip, key, note))
  }

export const removeNotesIfCompanyNotCreated = () => (dispatch, getState) => {
  const notes = CompanyNotesSelector(getState())
  if (!Array.isArray(notes) || isEmpty(notes)) {
    return
  }
  const exist_without_company = notes.some((item) => !item.company_id)
  if (exist_without_company) {
    notes
      .filter((item) => !item.company_id)
      .forEach((item) => {
        dispatch(deleteNote(item.id))
      })
    dispatch(setCompanyNotes([]))
  }
}

export const removeCompanyFromActivity = (id) => {
  return {
    type: REMOVE_COMPANY_FROM_ACTIVITY,
    payload: id,
  }
}
