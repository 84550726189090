import { SET_COMMUNICATIONS } from "../../action-types/communication"
import { setErrorMessage, setErrorState } from "../errors/set_errors"
import { request } from "../../../api/axios_helper"
import { COMMUNICATION_API_URL } from "../../../common/constants"

export const setCommunications = (payload) => {
  return {
    type: SET_COMMUNICATIONS,
    payload,
  }
}

export const getCommunicationById = (id) => async (dispatch) => {
  try {
    const response = await request().get(
      `${COMMUNICATION_API_URL}${id}/`,
      dispatch
    )
    dispatch(setCommunications(response.data))
  } catch (err) {
    dispatch(setErrorMessage(err.message))
    dispatch(setErrorState(true))
  }
}

export const getCommunications = (params) => async (dispatch) => {
  try {
    const response = await request().get(
      COMMUNICATION_API_URL + params,
      dispatch
    )
    dispatch(setCommunications(response.data))
  } catch (err) {
    dispatch(setErrorMessage(err.message))
    dispatch(setErrorState(true))
  }
}
