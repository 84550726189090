import {
  UPDATE_PUBLISHED_COMMUNICATIONS,
  UPDATE_DRAFT_COMMUNICATIONS,
  UPDATE_COMMUNICATION_PENDING_STATUS,
  SET_EDIT_STATUS,
} from "../../action-types/communication"
import { editSearchPage } from "../search/get-search"
import { setErrorMessage, setErrorState } from "../errors/set_errors"
import { request } from "../../../api/axios_helper"
import { updateDiaryActivity } from "../diaries/edit-diary"
import { COMMUNICATION_API_URL, FeaturesTypes } from "../../../common/constants"
import { getCommunicationPendingStatus } from "../../selectors/communications/get-communications-list"

export const setCommunicationEditStatus = (payload) => {
  return {
    type: SET_EDIT_STATUS,
    payload,
  }
}

export const updatePublishedCommunication = (payload) => {
  return {
    type: UPDATE_PUBLISHED_COMMUNICATIONS,
    payload,
  }
}
export const updateDraftCommunication = (payload) => {
  return {
    type: UPDATE_DRAFT_COMMUNICATIONS,
    payload,
  }
}
export const setCommunicationPendingStatus = (payload) => {
  return {
    type: UPDATE_COMMUNICATION_PENDING_STATUS,
    payload,
  }
}
export const updateCommunication =
  (data, isAddLead, isDraft) => async (dispatch, getState) => {
    const isLoading = getCommunicationPendingStatus(getState())
    if (isLoading) return
    dispatch(setCommunicationPendingStatus(true))
    try {
      if (isAddLead || isDraft) {
        dispatch(updateDraftCommunication(data))
      } else {
        const response = await request().edit(
          `${COMMUNICATION_API_URL}${data.id}/`,
          data,
          dispatch,
          "patch"
        )
        dispatch(updatePublishedCommunication(response.data))
        dispatch(editSearchPage(response.data, FeaturesTypes.COMMUNICATION))
        dispatch(
          updateDiaryActivity({
            data: response.data,
            activity_key: FeaturesTypes.COMMUNICATION,
          })
        )
      }
    } catch (err) {
      dispatch(setErrorMessage(err.message))
      dispatch(setErrorState(true))
    } finally {
      dispatch(setCommunicationPendingStatus(false))
    }
  }
