import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  getJobsSelector,
  getSpecificJob,
  getContactsSelector,
} from "../store/selectors"
import { getSpecificContact } from "../store/selectors/contacts/get-contacts-selector"
import { getJobToList } from "../store/actions/jobs/get-jobs"
import { getContact } from "../store/actions/contacts/get-contacts-list"
import { useAction } from "./useAction"

export const useCommunicationSpecData = (communication) => {
  const [data, setData] = useState(null)
  const allContacts = useSelector(getContactsSelector)
  const allJobs = useSelector(getJobsSelector)
  const contact = useSelector(getSpecificContact)
  const job = useSelector(getSpecificJob)
  const getContactToListById = useAction(getContact)
  const getJobToListById = useAction(getJobToList)
  const communicationDataId =
    communication?.contact || communication?.job || null

  const entity = async (id, mode) => {
    try {
      if (mode === "job") {
        return await getJobToListById(id)
      } else if (mode === "contact") {
        return await getContactToListById(id)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (communicationDataId) {
      if (communication.contact && !contact?.id) {
        const contactFromRedux = allContacts.find(
          (contact) => contact.id === communication.contact
        )
        if (contactFromRedux) {
          setData(contactFromRedux)
        } else {
          entity(communicationDataId, "contact").then((res) => setData(res))
        }
      } else if (communication.job && !job?.id) {
        const jobFromRedux = allJobs.find((job) => job.id === communication.job)
        if (jobFromRedux) {
          setData(jobFromRedux)
        } else {
          entity(communicationDataId, "job").then((res) => setData(res))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const myContact = contact.id
    ? contact
    : allContacts.find((contact) => contact.id === communication.contact) ||
      data

  const myJob = job.id
    ? job
    : allJobs.find((job) => job.id === communication.job) || data
  return {
    communicationDataId,
    contact: myContact,
    job: myJob,
  }
}
