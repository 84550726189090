export const CREATE_COMMUNICATION = "CREATE_COMMUNICATION"
export const RESET_DRAFT_COMMUNICATIONS = "RESET_DRAFT_COMMUNICATIONS"
export const SET_COMMUNICATIONS = "SET_COMMUNICATIONS"
export const UPDATE_PUBLISHED_COMMUNICATIONS = "UPDATE_PUBLISHED_COMMUNICATIONS"
export const UPDATE_DRAFT_COMMUNICATIONS = "UPDATE_DRAFT_COMMUNICATIONS"
export const UPDATE_COMMUNICATION_PENDING_STATUS =
  "UPDATE_COMMUNICATION_PENDING_STATUS"
export const REMOVE_DRAFT_COMMUNICATION = "REMOVE_DRAFT_COMMUNICATION"
export const REMOVE_PUBLISHED_COMMUNICATION = "REMOVE_PUBLISHED_COMMUNICATION"
export const SET_SPECIFIC_COMMUNICATION = "SET_SPECIFIC_COMMUNICATION"
export const SET_EDIT_STATUS = "SET_EDIT_STATUS"
