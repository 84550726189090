import { useCallback, useState, useEffect } from 'react';
import { FileTextOutlined, DeleteOutlined } from '@ant-design/icons';
import { Upload, Modal, Progress, Button, notification, Popconfirm } from 'antd';
import ModalCloseIcon from "./modal-close-icon"
import { request } from '../../api/axios_helper';
import { IMPORT_API_URL } from '../../common/constants';
import { getContacts } from '../../store/actions/contacts/get-contacts-list';
import { useAction } from '../../hooks/useAction'
import { ReactComponent as UploadIcon } from '../../assets/images/upload_design_icon.svg'


const { Dragger } = Upload;



const AddFile = ({ onClose }) => {

    const getContactsAction = useAction(getContacts)
    const [fileList, setFileList] = useState([]);
    const [infoList, setInfoList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [MAX_FILE_SIZE] = useState(10 * 1024 * 1024); // 10MB
    const [api, contextHolder] = notification.useNotification();
    const props = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        fileList,
        beforeUpload: (file) => {
            if (file.size > MAX_FILE_SIZE) {
                api.error({ message: "error", description: `${file.name} file upload failed (exceeds 10MB)` });
                return false;
            }
            setFileList(prev => [...prev, file]);
            setInfoList(prev => [...prev, { uid: file.uid }]);
            return false; // Prevent auto upload
        },

    };
    const onRemove = (file) => {
        setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
        setInfoList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    }

    const uploadFile = useCallback(async (f) => {
        const formData = new FormData();
        formData.append('usr_file', f);
        formData.append('file_type', 'C');
        const onUploadProgress = (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setInfoList(p => p.map((item) => {
                if (item.uid === f.uid) {
                    return { ...item, percent: percentCompleted };
                }
                return item;
            })
            );
        }
        const response = await request().create(IMPORT_API_URL, formData, null, onUploadProgress);
        if (response?.data?.imported_status === "OK") {
            setInfoList(p => p.map((item) => {
                if (item.uid === f.uid) {
                    return { ...item, contactsCount: response?.data?.imported_count, percent: 100 };
                }
                return item;
            })
            )

        } else {
            setInfoList(
                p => p.map((item) => {
                    if (item.uid === f.uid) {
                        return { ...item, error: response?.data?.imported_status, percent: 100 };
                    }
                    return item;
                })
            )

        }
        return response;

    }, [setInfoList]);

    const handleUpload = useCallback(async () => {
        if (isUploaded) return onClose();
        setUploading(true);
        let res = null;
        try {
            for (const file of fileList) {
                if (infoList.find(e => e.uid === file.uid).contactsCount || infoList.find(e => e.uid === file.uid).error) continue;
                res = await uploadFile(file);
            }

            res && getContactsAction()
        } catch (error) {
            console.log(error)
        }
        finally {
            setUploading(false);
        }

    }, [isUploaded, onClose, getContactsAction, fileList, infoList, uploadFile]);

    useEffect(() => {
        setIsUploaded(infoList.every(e => e.contactsCount || e.error))
    }, [infoList])
    return (
        <Modal
            open
            footer={null}
            onCancel={onClose}
            closeIcon={<ModalCloseIcon />}
            className='add-file-modal'
            width={700}
        >
            <div style={{ position: "absolute", display: "flex", alignItems: "baseline", justifyContent: "space-between", width: "90%", left: "5%", top: "10%" }}>
                <h2>Upload Contacts</h2>
                <a style={{ color: "#009eef" }} href={IMPORT_API_URL} target="_blank" rel="noopener noreferrer" >Download Template</a>
            </div>
            {contextHolder}
            <Dragger  {...props}
                accept='.csv, .zip, .xlsx, .xls'
                style={{ borderRadius: "0px", border: "none", padding: 0, marginBottom: "20px" }}
            >
                <div className='custom-border' style={{ padding: "20px 50px" }}>
                    <p className="ant-upload-drag-icon" style={{ display: "flex", justifyContent: "center" }}>
                        <span style={{ border: "2px solid lightgrey", padding: "12px 16px 6px 16px", borderRadius: "10px", display: "block" }}>
                            <UploadIcon
                                style={
                                    {
                                        fill: "lightgrey",
                                    }
                                }
                            />
                        </span>
                    </p>
                    <p className="ant-upload-text" style={{ marginBottom: "20px" }}>Drag and drop your file here <br /> or <span style={{ color: "#009eef" }}>click to upload</span></p>
                    <p className="ant-upload-hint" style={{ color: "#b5b5b5", fontWeight: "400" }}>
                        <i>
                            Maximum file size  10MB
                            <br />
                            Supported formats: CSV, XLS, XLSX, ZIP
                        </i>
                    </p>
                </div>
            </Dragger>

            {
                fileList?.map((file) => {
                    const info = infoList.find(e => e.uid === file.uid)
                    const { error, contactsCount } = info;
                    return (
                        <div key={file.uid}>
                            <div style={{ position: "relative" }}>
                                <FileTextOutlined style={{ fontSize: 30, color: "grey" }} />
                                <span style={{ position: "absolute", left: "10%" }}>{file.name} </span>
                                <span style={{ position: "absolute", top: "50%", left: "10%" }}> {file.size / 1000} KB</span>
                                {!contactsCount &&
                                    <Popconfirm
                                        placement="topRight"
                                        className="delete_file"
                                        title="Delete file ? There is no undo."
                                        onConfirm={() => onRemove(file)}
                                        okText="Delete"
                                        cancelText="Cancel"
                                    ><DeleteOutlined

                                            style={{ position: "absolute", right: "-1%", top: "50%", color: "#f25041", cursor: "pointer" }}
                                        /></Popconfirm>}
                                {error && <span style={{ color: "red", position: "absolute", right: "0%", top: "50%" }}>{error}</span>}
                                {contactsCount && <span style={{ color: "green", position: "absolute", right: "0%", top: "50%" }}>{contactsCount} contacts imported</span>}
                            </div>
                            <Progress style={{ width: "104%" }} percent={info.percent} size="small" showInfo={true} />
                        </div>
                    )
                })
            }
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 56, marginTop: 32 }}>
                {infoList.length > 0 &&
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={uploading}
                        loading={uploading}
                    >
                        {!isUploaded ? "Import" : "Done"}
                    </Button>
                }

            </div>


        </Modal >
    );
}
export default AddFile;