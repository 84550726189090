import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { Form, Input, Button, notification } from "antd"

import { getUserIsAuthenticated } from "../store/selectors/user/get-login-status"
import { sendSupportMessage } from "../api/support"
import { URLS } from "../common/urls"

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const SupportInput = () => {
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(false)
    const isAuth = useSelector(getUserIsAuthenticated)
    const history = useHistory()
    const handleClick = async () => {
        setLoading(true)
        try {
            const res = await sendSupportMessage({ body: input })
            if (res.error) return notification.error({ message: "Error sending message" })
            notification.success({ message: res.data.message }, 5)
            history.push(URLS.HOME)
            setInput("")

        } catch (e) {
            notification.error({ message: e?.message })
        } finally {
            setLoading(false)
        }

    }
    return (
        isAuth
            ?
            <Form
                {...layout}
                labelCol={{ flex: '90px' }}
                labelAlign="left"
                labelWrap
                style={{ marginTop: "40px" }}
            >
                <Form.Item label="Comments" rules={[{ required: true }]}>
                    <Input.TextArea style={{ minHeight: "64px" }} value={input} onChange={(e) => setInput(e.target.value)} />
                </Form.Item>
                <Form.Item>
                    <Button style={{ marginLeft: '90px' }} loading={loading} disabled={!input} type="primary" onClick={handleClick}>
                        Send
                    </Button>
                </Form.Item>
            </Form>
            :
            null
    )
}

export default SupportInput