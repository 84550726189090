import React from "react"
import { Tag } from "antd"
import withShouldUpdate from "../store/HOC/withShouldUpdate"
import { useColors } from "../hooks/useColors"

const TagItem = ({ item, onMenuOpen }) => {
  const { allColors } = useColors()
  const threeDotsClick = (e) => {
    const position = e.currentTarget.getBoundingClientRect()
    e.stopPropagation()
    e.preventDefault()
    onMenuOpen(item, position)
  }
  return (
    <>
      <div style={{ width: "136px", overflow: "hidden", textAlign: "left" }}>
        <Tag
          style={{ borderRadius: "50px", marginRight: "10px" }}
          color={allColors?.[String(item.color).toLowerCase()]?.code}
        >
          {item.tag}
        </Tag>
      </div>
      <span
        onClick={threeDotsClick}
        style={{
          fontSize: "26px",
          lineHeight: "8px",
          fontWeight: 500,
          color: "gray",
          position: "relative",
        }}
      >
        ...
      </span>
    </>
  )
}

export default withShouldUpdate(TagItem)
