export const SET_SEARCH_PENDING_STATUS = "SET_SEARCH_PENDING_STATUS"
export const SET_TAGS = "SET_TAGS"
export const SET_MENTIONS = "SET_MENTIONS"
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS"
export const SET_ALL_TAGS = "SET_ALL_TAGS"
export const SET_GLOBAL_SEARCH_PENDING_STATUS =
  "SET_GLOBAL_SEARCH_PENDING_STATUS"
export const RESET_SEARCH_PAGE = "RESET_SEARCH_PAGE"
export const EDIT_SEARCH_PAGE = "EDIT_SEARCH_PAGE"
export const REMOVE_SEARCH_RESULT = "REMOVE_SEARCH_RESULT"
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
