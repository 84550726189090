
import BannerDelete from "../../assets/images/dalete_company_svy.svg"
import { Button, Modal } from "antd"

import withMemo from "../../store/HOC/with_memo"


const CancelSubscription = ({ handleClose, visible, handleClickCancel }) => {

    return (
        <Modal
            className="delete-modal company"
            open={visible}
            footer={null}
            onCancel={handleClose}
            closable={false}
        >
            <div className="delete-modal">
                <div className="delete-modal__content">
                    <div className="delete-modal__img">
                        <img src={BannerDelete} alt="delete" className="img-fluid" />
                    </div>
                    <div className="delete-modal__text">
                        <span className="delete-modal__title">Cancel Subscription ?</span>
                        <span className="delete-modal__subtitle">
                            Are you sure you want to cancel your subscription ?
                        </span>
                    </div>
                </div>
                <div className="delete-modal__actions">
                    <Button
                        className="btn-style btn-cancel"
                        type="primary"
                        ghost
                        onClick={handleClose}
                    >
                        Undo
                    </Button>
                    <Button type="primary" danger onClick={handleClickCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default withMemo(CancelSubscription)