import {
  RESET_FILTERS,
  UPDATE_FILTERS,
  FILTER_BY_TAGS,
  REMOVE_TAG_FROM_FILTERS,
} from "../../action-types/filters"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  commSort: null,
  tags: [],
}

export const filtersReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        commSort: null,
        tags: [],
      }
    }
    case REMOVE_TAG_FROM_FILTERS: {
      return {
        ...state,
        tags: state.tags.filter((item) => {
          return item !== action.payload
        }),
      }
    }
    case FILTER_BY_TAGS: {
      const isExist = state.tags.some((item) => item.tag === action.payload)
      if (isExist) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        tags: [...state.tags, action.payload],
      }
    }
    case UPDATE_FILTERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET_FILTERS: {
      return {
        ...state,
        commSort: null,
        tags: [],
      }
    }
    default:
      return {
        ...state,
      }
  }
}
