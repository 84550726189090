import React, { useEffect, useState } from "react"
import { Button, Modal, Select, Upload, Row, Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getFilesList } from "../../store/actions/file_manager/get_files_list"
import { getFilesSelector } from "../../store/selectors"
import { updateJobTH } from "../../store/actions/jobs/update-job"
import { UploadOutlined } from "@ant-design/icons"

export const ResumeManager = ({ onClose, data, isEdit, setData, onUpload, handleAutoCreateWithFileLead }) => {
  const files = useSelector(getFilesSelector)
  const dispatch = useDispatch()
  const uploadProps = {
    onChange({ file }) {
      setLoad(true)
      setTimeout(() => {
        setLoad(false)
        onClose()
      }, 3000)
    },
    customRequest: ({ file }) => {

      onUpload(file)
    },
    name: "file",
    onRemove() {
    },
  }
  const [uploadLoading, setLoad] = useState(false)

  const saveFileChanges = (resume_id) => {
    if (isEdit) {
      dispatch(updateJobTH({ resume_id, id: data.id }))
    } else {
      const file = files?.find((item) => {
        return item.id === resume_id
      })
      handleAutoCreateWithFileLead(file)
    }
    onClose()
  }

  useEffect(() => {
    dispatch(getFilesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      onCancel={onClose}
      open
      footer={null}
      title={<div style={{ padding: "20px" }}>Resume</div>}
      className="modal-resume"
    >
      <Row className="resume-modal" style={{ height: "100%" }}>
        <Col
          span={24}
          style={{
            display: "flex",
          }}
        >
          <Select
            defaultValue={data?.resume?.title}
            size="lg"
            style={{
              width: "198px",
              marginRight: "24px",
            }}
            onChange={saveFileChanges}
            placeholder="Select Resume"
          >
            {files?.map((file) => {
              return (
                <Select.Option key={file.id} value={file.id}>
                  {file.title}
                </Select.Option>
              )
            })}
          </Select>

          <Upload {...uploadProps} maxCount={1}>
            <Button
              type="primary"
              icon={<UploadOutlined className="upload-icon" />}
              style={{
                marginLeft: "auto",
              }}
              loading={uploadLoading}
            >
              Upload New
            </Button>
          </Upload>
        </Col>
      </Row>
    </Modal>
  )
}
