import {
  APPLY_JOB,
  CLEAR_SAVE_STATUS,
  SET_SPECIFIC_LEAD,
  UPDATE_JOB_DATA_SUCCESS,
} from "../../action-types/jobs"
import { updateSpecificLead } from "../todos/create_todo"
import { request } from "../../../api/axios_helper"
import { FeaturesTypes, JOB_API_URL } from "../../../common/constants"
import { editSearchPage } from "../search/get-search"
import { setJobPendingStatus } from "./get-jobs"
import { updateDiaryActivity } from "../diaries/edit-diary"
import { updateContainerTitle } from "../todos/update-todo"

export const requestStart = (payload) => {
  return {
    type: APPLY_JOB,
    payload,
  }
}
export const setSpecificLead = (payload) => {
  return {
    type: SET_SPECIFIC_LEAD,
    payload,
  }
}
export const updateSuccesfully = (payload) => {
  return {
    type: UPDATE_JOB_DATA_SUCCESS,
    payload,
  }
}

export const clearSaveStatus = () => {
  return {
    type: CLEAR_SAVE_STATUS,
  }
}
export const applyJob = (id, data) => async (dispatch) => {
  dispatch(setJobPendingStatus(true))
  request()
    .edit(`${JOB_API_URL}${id}/`, data, dispatch, "patch")
    .then((res) => {
      dispatch(updateSuccesfully(res.data))
    })
    .finally(() => dispatch(setJobPendingStatus(false)))
}
export const updateJobTH =
  (data, isSetUpdates = false) =>
  async (dispatch) => {
    try {
      const response = await request().edit(
        `${JOB_API_URL}${data.id}/`,
        data,
        dispatch,
        "patch"
      )
      if (response.status === 200) {
        dispatch(updateSuccesfully(response.data))
        dispatch(updateContainerTitle(response.data))
        !isSetUpdates && dispatch(updateSpecificLead(response.data))
        dispatch(editSearchPage(response.data, FeaturesTypes.LEAD))
        dispatch(
          updateDiaryActivity({
            data: response.data,
            activity_key: FeaturesTypes.LEAD,
          })
        )
        return response.data
      }
    } catch (err) {}
  }
