import {
  CLEAR_ALL_ACTIVE_TODOS,
  DRAG_TODO_IN_SPECIFIC_CONTAINER,
  REMOVE_TODO,
  SET_NEW_TODO,
  SET_TODO_FILTER,
  SET_TODO_PENDING_STATUS,
  SET_TODOS_DATA,
  UPDATE_CONTAINER_TITLE,
  UPDATE_TODO,
  RESET_DRAFT_TODO_LIST,
  UPDATE_DRAFT_TODO_LIST,
  REMOVE_DRAFT_TODO,
  UPDATE_DRAFT_TODO,
} from "../../action-types/todos"
import {
  CREATE_EDITABLE_ROW,
  DISABLE_EDITABLE_ROW,
  SET_NEW_JOB_TODO,
} from "../../action-types/jobs"
import {
  TODO_DONE_STATUS,
  TODO_NOT_DONE_STATUS,
} from "../../../common/constants"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  todos: [],
  pending: true,
  isPending: false,
  todosGroups: [],
  filter: TODO_NOT_DONE_STATUS,
  draftItems: [],
}

const getContactName = (contact_id, allContacts, _contact) => {
  let contact = _contact
  if (allContacts) {
    contact = allContacts.find((item) => item.id === Number(contact_id))
  }
  return `${contact?.company?.name || ""}${
    contact?.company?.name && (contact?.first_name || contact?.title) && " - "
  }${contact?.first_name || contact?.title}`
}

export const todosReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ALL_ACTIVE_TODOS: {
      return {
        ...state,
        todosGroups: state.todosGroups.filter(
          (todo) => todo.status_id !== TODO_DONE_STATUS
        ),
      }
    }
    case SET_TODO_FILTER: {
      return {
        ...state,
        filter: action.payload,
      }
    }
    case SET_TODO_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }
    case SET_NEW_JOB_TODO: {
      return {
        ...state,
        todosGroups: state.todosGroups.map((item) => {
          return Number(item.containerId) === action.payload.job
            ? {
                ...item,
                todos: [action.payload].concat(item.todos),
              }
            : item
        }),
      }
    }
    case DRAG_TODO_IN_SPECIFIC_CONTAINER: {
      const { container, payload } = action
      const todosGroups = state.todosGroups.map((item) => {
        if (Number(item.containerId) === Number(container)) {
          return {
            ...item,
            todos: payload,
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        todosGroups,
      }
    }
    case DISABLE_EDITABLE_ROW: {
      const value = state.todos.splice(1)
      return {
        ...state,
        todos: value,
      }
    }
    case CREATE_EDITABLE_ROW: {
      return {
        ...state,
        todos: [
          {
            create_dt: new Date(),
            description: "",
            done_dt: "",
            due_dt: "",
            id: new Date().getTime(),
            job: null,
            status_id: 1,
            tags: [],
            user: new Date().getTime(),
            editable: true,
          },
        ].concat(state.todos),
      }
    }
    case RESET_APPLICATION_DATA: {
      return {
        todos: [],
        pending: true,
        isPending: false,
        todosGroups: [],
        filter: TODO_NOT_DONE_STATUS,
        draftItems: [],
      }
    }
    case SET_TODOS_DATA: {
      return {
        ...state,
        todosGroups: action.payload,
        pending: false,
        isPending: false,
      }
    }
    case UPDATE_TODO: {
      return {
        ...state,
        todosGroups: state.todosGroups.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          } else {
            return item
          }
        }),
        todos: state.todos.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          } else {
            return item
          }
        }),
      }
    }
    case UPDATE_CONTAINER_TITLE: {
      return {
        ...state,
        todosGroups: state.todosGroups.map((item) => {
          if (Number(item.containerId) === action.payload.id) {
            return {
              ...item,
              title: getContactName(null, null, action.payload),
            }
          }
          return item
        }),
      }
    }

    case SET_NEW_TODO: {
      return {
        ...state,
        todosGroups: [action.payload].concat(state.todosGroups),
        todos: [action.payload].concat(state.todos),
      }
    }
    case REMOVE_TODO: {
      return {
        ...state,
        todosGroups: state.todosGroups.filter(
          (item) => item.id !== action.payload
        ),
        todos: state.todos?.filter((item) => item.id !== action.payload),
      }
    }

    case REMOVE_DRAFT_TODO: {
      return {
        ...state,
        draftItems: state?.draftItems?.filter(
          (item) => item?.description !== action?.payload?.description
        ),
      }
    }

    case RESET_DRAFT_TODO_LIST: {
      return {
        ...state,
        draftItems: [],
      }
    }

    case UPDATE_DRAFT_TODO_LIST: {
      return {
        ...state,
        draftItems: [action.payload, ...state?.draftItems],
      }
    }
    case UPDATE_DRAFT_TODO: {
      return {
        ...state,
        draftItems: state?.draftItems?.map((item) => {
          if (item?.description === action?.payload?.description) {
            return action.payload
          } else {
            return item
          }
        }),
      }
    }
    default: {
      return {
        ...state,
        pending: false,
      }
    }
  }
}
