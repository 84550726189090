import { useEffect } from "react"
import { sortContactKeys } from "../common/constants"
import { useSelector } from "react-redux"
import { useAction } from "./useAction"
import { getSortKey } from "../store/selectors"
import {
  getContacts,
  resetContacts,
  setSortKey,
} from "../store/actions/contacts/get-contacts-list"

export function useContactSorting() {
  const getContactsAction = useAction(getContacts)
  const resetContactsAction = useAction(resetContacts)
  const setSortKeyAction = useAction(setSortKey)
  const contactsSortKey = useSelector(getSortKey)

  const handleTableChange = async (_, __, sorter) => {
    if (sorter) {
      const { columnKey, order } = sorter
      if (sortContactKeys.includes(columnKey) && order) {
        await setSortKeyAction({
          columnKey,
          order: order === "descend" ? "-" : "",
        })
        await resetContactsAction()
        await getContactsAction()
      } else if (!order) {
        await setSortKeyAction(null)
        await resetContactsAction()
        await getContactsAction()
      }
    }
  }

  useEffect(() => {
    return () => {
      setSortKeyAction(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleTableChange,
    resetContactsAction,
    contactsSortKey,
    setSortKeyAction,
    getContactsAction,
  }
}
