import React from "react"
// import { Spin } from "antd"
import { useWorkspaceTabs } from "../../hooks/useWorkspaceTabs"
import { DraggableCustomWorkspaceTabs } from "../custom-tabs"

export const WorkspacesTabs = ({
  workspaces_is_pending,
  workspaces,
  handleEditWorkspace,
  handleRemoveWorkspace,
  isEdit,
  current_workspace,
  handleCloseWorkspaceModal,
}) => {
  const { handleChangeTab, getClassName, dragWorkspaces } = useWorkspaceTabs()
  return (
    // <Spin spinning={workspaces_is_pending}>
    <DraggableCustomWorkspaceTabs
      handleDragWorkspace={dragWorkspaces}
      workspaces={workspaces}
      handleCloseWorkspaceModal={handleCloseWorkspaceModal}
      isEdit={isEdit}
      current_workspace={current_workspace}
      handleChangeTab={handleChangeTab}
      getClassName={getClassName}
      handleEditWorkspace={handleEditWorkspace}
      handleRemoveWorkspace={handleRemoveWorkspace}
      isEditable
    />
    // </Spin>
  )
}
