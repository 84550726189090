import { REMOVE_TODO_FROM_LEAD } from "../../action-types/jobs"
import { REMOVE_TODO, CLEAR_ALL_ACTIVE_TODOS } from "../../action-types/todos"
import { setTodoPendingStatus } from "./create_todo"
import { request } from "../../../api/axios_helper"
import {
  TODO_CLEAR_ALL_API_URL,
  TODOS_API_URL,
} from "../../../common/constants"

export const removeTodoFromLead = (lead) => {
  return {
    type: REMOVE_TODO_FROM_LEAD,
    payload: lead,
  }
}
export const removeTodoFromList = (id, containerId, status) => {
  return {
    type: REMOVE_TODO,
    payload: id,
    containerId,
    status,
  }
}

export const deleteTodo = (todoId, containerId, status) => async (dispatch) => {
  try {
    dispatch(setTodoPendingStatus(true))
    await request().delete(`${TODOS_API_URL}${todoId}/`, dispatch, () => {
      dispatch(setTodoPendingStatus(false))
      dispatch(removeTodoFromList(todoId, containerId, status))
      dispatch(removeTodoFromLead(todoId))
    })
  } catch (err) {
    dispatch(setTodoPendingStatus(false))
  }
}

export const clearAllActiveTodos = () => ({ type: CLEAR_ALL_ACTIVE_TODOS })
export const clearAllActive = () => (dispatch) => {
  dispatch(setTodoPendingStatus(true))
  request()
    .create(TODO_CLEAR_ALL_API_URL, { status_id: 2 }, dispatch)
    .then(() => dispatch(clearAllActiveTodos()))
    .finally(() => dispatch(setTodoPendingStatus(false)))
}
