import { useDispatch, useSelector } from "react-redux"
import {
  getJobFilters,
  getJobPendingStatus,
  getJobAppliedPendingStatus,
  getJobsCountByStatus,
  getSortedJobs,
  getUserSubscriptionTypeSelector,
  getJobsFilterBySelector,
  getJobsSelector,
} from "../store/selectors"
import React, { useCallback, useEffect, useState } from "react"
import { message } from "antd"
import { Link, useLocation } from "react-router-dom"
import { discardJob } from "../store/actions/jobs/discard-job"
import { updateJob } from "../api/jobs"
import { revertJob } from "../store/actions/jobs/revert-job"
import { deleteJobTH } from "../store/actions/jobs/delete-job"
import { updateJobFilters } from "../store/actions/filters"
import { getJobs } from "../store/actions"
import { URLS } from "../common/urls"
import { useActivityItem } from "./useActivityItem"
import { FeaturesTypes } from "../common/constants"

export const useLeadsTable = () => {
  const location = useLocation()
  const jobFilter = useSelector(getJobFilters)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [messsageApi, contextHolder] = message.useMessage()
  const jobsData = useSelector(getSortedJobs)
  const allJobs = useSelector(getJobsSelector)
  const { _new, applied } = useSelector(getJobsCountByStatus)
  const status_id = useSelector(getJobsFilterBySelector)
  const isPending = useSelector(getJobPendingStatus)
  const appliedPending = useSelector(getJobAppliedPendingStatus)
  const dispatch = useDispatch()
  const [status, setStatus] = useState({
    id: null,
    status_id: undefined,
  })
  const [statuses, setStatuses] = useState([])
  const { handleClick } = useActivityItem()
  const warning = useCallback(
    (data) => {
      return messsageApi.open({
        type: "info",
        content: (
          <div>
            Job has been discarded.{" "}
            <Link to="#" onClick={() => onRevertLead(data)}>
              Undo
            </Link>
          </div>
        ),
        duration: 5,
        key: status.id,
      })
    },
    //eslint-disable-next-line
    [messsageApi, status?.id]
  )
  const onDiscard = useCallback(
    async (data) => {
      dispatch(discardJob(data))
      const res = await updateJob({ status_id: 3 }, data.id)
      if (res?.status === 200) {
        warning({ id: data.id, status_id: data.status_id })
      }
    },
    [warning, dispatch]
  )

  const closeDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const onRevertLead = (data) => {
    dispatch(revertJob({ id: data.id, status_id: data.status_id }))
    message.destroy(status.id)
  }
  const onDeleteLead = (id) => {
    dispatch(deleteJobTH(id))
    setOpenDeleteModal(false)
  }
  const openAddLeadModal = () => {
    handleClick(
      { id: "newLead" },
      FeaturesTypes.LEAD,
      {},
      {
        isEdit: false,
        status: "add",
        isAdd: true,
        is_communication_edit: false,
      }
    )
  }
  const handleUpdateFilters = (e) => {
    dispatch(updateJobFilters(e.target.value))
  }
  useEffect(() => {
    if (location.pathname === URLS.LEADS) {
      if (!statuses.includes(status_id)) {
        dispatch(getJobs(status_id))
        setStatuses([...statuses, status_id])
      }
    }
    //eslint-disable-next-line
  }, [dispatch, location.pathname, status_id])
  return {
    jobFilter,
    openDeleteModal,
    setOpenDeleteModal,
    contextHolder,
    jobsData,
    _new,
    applied,
    isPending,
    appliedPending,
    dispatch,
    status,
    setStatus,
    onDiscard,
    closeDeleteModal,
    onDeleteLead,
    openAddLeadModal,
    handleUpdateFilters,
    handleClick,
    subType,
    status_id,
    allJobs,
  }
}
