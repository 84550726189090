import React from "react"
import { isEmpty } from "lodash"
import Chip from "../chip"

export const ChipsList = ({ chips, handleDownloadFile, handleRemoveChip }) => {
  return (
    <>
      {!isEmpty(chips) && (
        <div
          className="chips"
          style={{
            margin: "12px 0 0 0",
            padding: "9px 50% 3px 24px",
            width: "100%",
            border: "1px solid #f0f0f0",
          }}
        >
          {chips.map((chip) => {
            return (
              <Chip
                chip={chip}
                key={chip.id}
                handleRemoveChip={handleRemoveChip}
                handleDownloadFile={handleDownloadFile}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
