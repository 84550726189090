import React, { useCallback, useState } from "react"
import Modal from "antd/es/modal/Modal"
import withMemo from "./with_memo"
import { Button } from "antd"
import { firstLetterToUpperCase } from "../../common/helpers"
import DeleteWorkspace from "../../assets/images/delete_workspace.svg"
import DeleteNote from "../../assets/images/delete_note.svg"

const withConfirmModal = (Component) =>
  withMemo((props) => {
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (e) => {
      e.stopPropagation()
      setOpenModal(true)
    }
    const handleCloseModal = (e) => {
      if (e) {
        e.stopPropagation()
      }
      setOpenModal(false)
    }

    const handleRemove = (e) => {
      handleCloseModal()
      if (props.handleRemoveWorkspace) {
        props.handleRemoveWorkspace(e, props.data.id)
      }
      if (props.handleRemove) {
        props.handleRemove(e)
      }
    }
    const getType = useCallback(() => {
      return firstLetterToUpperCase(props.type)
    }, [props.type])
    return (
      <>
        <Modal
          className="delete-workspace-modal"
          open={openModal}
          onCancel={handleCloseModal}
          footer={null}
          closable={false}
        >
          <div className="df fd_col">
            <div className="df al-it_c">
              {getType() === "Workspace" ? (
                <img
                  className="change-password-modal__image"
                  src={DeleteWorkspace}
                  alt="delete-workspace"
                />
              ) : (
                <img
                  className="change-password-modal__image"
                  src={DeleteNote}
                  alt="delete-note"
                />
              )}
              <div className="df fd_col ml_21">
                <span className="new-modals-title">Delete {getType()}?</span>
                <span className="new-modals-subtitle">
                  Are you sure you want to delete{" "}
                  {`${props.data?.title ? "" : "this"}`}{" "}
                  <span className="fw_500">{` ${props.data?.title}`}</span>{" "}
                  {getType()}?
                </span>
              </div>
            </div>
            <div className="delete-modal__actions mt_16">
              <Button
                className="btn-style btn-cancel"
                type="primary"
                ghost
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button type="primary" danger onClick={handleRemove}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
        <Component
          {...props}
          handleRemoveWorkspace={handleOpenModal}
          handleDelete={handleOpenModal}
        />
      </>
    )
  })

export default withConfirmModal
