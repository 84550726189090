export const config = {
  api_host: process.env.REACT_APP_API_HOST,
  auth_api_host: process.env.REACT_APP_AUTH_API_HOST,
  self_host: process.env.REACT_APP_SELF_HOST,
  stripe_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  verify_alert_message: process.env.REACT_APP_VERIFY_EMAIL_MESSAGE,
  verify_alert_content: process.env.REACT_APP_VERIFY_EMAIL_CONTENT,
  google_client_id: process.env.REACT_APP_GOOGLE_CLINET_ID,
  linkedin_client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  linkedin_redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
  default_workspace_id: 0,
  company_empty_message: process.env.REACT_APP_LEAD_MODAL_MISSING_COMPANY_ERROR,
  expiry_token_message:
    process.env.REACT_APP_PASSWORD_RESET_INVALID_TOKEN_MESSAGE,
  generic_error_message: process.env.REACT_APP_GENERIC_AUTH_ERROR_MESSAGE,
  apple_client_Id: process.env.REACT_APP_APPLE_CLIENT_ID,
  apple_redirect_uri: process.env.REACT_APP_APPLE_REDIRECT_URI,
}

export const api = {
  host: config.api_host,
  auth_host: config.auth_api_host,
  urls: {
    diary_today: "diary_today/",
    job: "job/",
    diaries: "diary/",
    todos: "todo/",
    company: "company/",
    contact: "contact/",
    communication: "communication/",
    company_search: "company_search",
    me: "user/",
    profile: "profile/",
    update_profile_email: "change_email_address/",
    reset_password: "password/reset/",
    reset_password_confirm: "password/reset/confirm/",
    google_login: "google/",
    apple_login: "appleweb/",
    linkedin_login: "linkedin/",
    tags: "tags/",
    mentions: "names/",
    search: "search/",
    drag_todos: "todo/update_dnd_index/",
    tag: "tag/",
    user_login: "user/login/",
    user_logout: "logout/",
    colors: "colors/",
    notes: "note/",
    files_list: "files/",
    file: "file/",
    import: "import/",
    verify_profile: "send-email-verification/",
    confirm_email: "registration/verify-email/",
    refresh_token: "token/refresh/",
    link: "link/",
    diary_activity: "diary/activity/",
    todo_clear_active: "todo/delete_done/",
    company_details: "company_update/",
    workspaces: "workspace/",
    workspace_note: "workspace_note/",
    page_title_by_url: "url/",
    stripe: "stripe/",
    subscription: "subscription/",
    support: "support/",
    export: "export/",
  },
  file_max_size: Number(process.env.REACT_APP_FILE_UPLOAD_MAX_SIZE || 10),
}

export const DATE_FORMAT = "MMM DD, YYYY"
