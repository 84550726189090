import {
  CREATE_CONTACT,
  SET_CONTACTS_LIST,
  SET_CONTACT_TO_LIST,
  EDIT_CONTACT_SUCCESS,
  DELETE_CONTACT,
  SET_SPECIFIC_CONTACT,
  SET_NEW_CONTACT_TODO,
  SET_CONTACT_TODOS,
  SET_CONTACTS_PENDING_STATUS,
  SET_CONTACTS_PAGINATION,
  SET_CONTACT_FETCHING,
  CLEAR_CONTACT_FETCHING,
  SET_DRAFT_TAGS_CONTACT,
  SET_COUNTERS,
  RESET_CONTACTS,
  SET_SORT_KEY_CONTACTS,
} from "../../action-types/contacts"
import { SET_COMPANY_CONTACTS } from "../../action-types/companies"
import { isEmpty, concat, filter } from "lodash"
import {
  EDIT_TAG_SUCCESS,
  REMOVE_TAG_FROM_CONTACT,
  REMOVE_TAG_SUCCESS,
} from "../../action-types/tags"
import {
  DRAG_TODO_IN_SPECIFIC_CONTAINER,
  REMOVE_TODO,
  UPDATE_TODO,
} from "../../action-types/todos"
import {
  removeTagFromTables,
  getArrayUniqueValue,
} from "../../../common/helpers"
import { RESET_APPLICATION_DATA } from "../../action-types/user"
import { SAVE_EDITED_COMPANY } from "../../action-types/companies"

const initState = {
  items: [],
  specificContact: {},
  todos: [],
  isPending: false,
  pagination: {},
  draftTags: [],
  total_count: 0,
  not_contacted: 0,
  fetchingContacts: new Set(),
  sortKey: null,
}

export const contactsReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        ...state,
        items: [],
        specificContact: {},
        todos: [],
        isPending: false,
        pagination: {},
        total_count: 0,
        not_contacted: 0,
        draftTags: [],
      }
    }
    case SET_SORT_KEY_CONTACTS: {
      return {
        ...state,
        sortKey: action.payload,
      }
    }
    case SAVE_EDITED_COMPANY: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item?.company?.id === action.payload.id) {
            return {
              ...item,
              company: action.payload,
            }
          } else {
            return item
          }
        }),
      }
    }
    case DRAG_TODO_IN_SPECIFIC_CONTAINER: {
      return {
        ...state,
        todos: [...action.payload, ...state.todos],
      }
    }
    case SET_CONTACTS_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      }
    }
    case SET_CONTACTS_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }

    case SET_DRAFT_TAGS_CONTACT: {
      return {
        ...state,
        draftTags: action.payload,
      }
    }
    case UPDATE_TODO: {
      const todos = isEmpty(state.todos)
        ? []
        : state.todos.map((item) => {
            return item.id === action.payload.id ? action.payload : item
          })
      return {
        ...state,
        todos,
      }
    }
    case REMOVE_TODO: {
      const todos = isEmpty(state.todos)
        ? []
        : state.todos.filter((item) => {
            return item.id !== action.payload
          })
      return {
        ...state,
        todos,
      }
    }
    case SET_NEW_CONTACT_TODO: {
      return {
        ...state,
        todos: [action.payload].concat(state.todos),
      }
    }
    case SET_CONTACT_TODOS: {
      return {
        ...state,
        todos: action.payload,
      }
    }
    case REMOVE_TAG_FROM_CONTACT: {
      return {
        ...state,
        specificContact: {
          ...state.specificContact,
          tags: state.specificContact?.tags?.filter((item) => {
            return item.tag !== action.payload.tag
          }),
        },
        items: state.items.map((item) => {
          if (item?.id === state.specificContact?.id) {
            return {
              ...item,
              tags: item.tags.filter((item) => {
                return item.tag !== action.payload.tag
              }),
            }
          } else {
            return item
          }
        }),
      }
    }
    case REMOVE_TAG_SUCCESS: {
      let newState = {
        ...state,
        draftTags: state.draftTags?.filter(
          (item) => item?.tag !== action.removedTag.tag
        ),
        items: state.items.map((contact) => {
          return {
            ...contact,
            tags: removeTagFromTables(contact.tags, action.removedTag.tag),
          }
        }),
      }
      if (!isEmpty(state.specificContact)) {
        newState.specificContact = {
          ...state.specificContact,
          tags: state.specificContact?.tags?.filter((item) => {
            return item.tag !== action.removedTag.tag
          }),
        }
      }
      return newState
    }
    case EDIT_TAG_SUCCESS: {
      const items = state.items.map((item) => {
        return {
          ...item,
          tags: item?.tags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        }
      })
      if (isEmpty(state.specificContact)) {
        return {
          ...state,
          items,
          draftTags: state.draftTags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        }
      } else {
        return {
          ...state,
          items,
          specificContact: {
            ...state.specificContact,
            tags: state.specificContact?.tags?.map((item) => {
              return item.tag?.trim() === action.prevTag.tag?.trim()
                ? action.payload
                : item
            }),
          },
        }
      }
    }
    case SET_CONTACTS_LIST: {
      const updatedItems = getArrayUniqueValue(
        state.items,
        action.payload.results
      )
      return {
        ...state,
        items: updatedItems,
        total_count: action.payload.count,
        not_contacted: action.payload.not_contacted_count,
      }
    }
    case RESET_CONTACTS: {
      return {
        ...state,
        items: [],
        pagination: {},
      }
    }
    case SET_COMPANY_CONTACTS: {
      const updatedItems = getArrayUniqueValue(state.items, action.payload)
      return {
        ...state,
        items: updatedItems,
      }
    }

    case SET_CONTACT_TO_LIST: {
      if (state.items.some((item) => item.id === action.payload.id)) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        items: [...state.items, action.payload],
      }
    }
    case SET_CONTACT_FETCHING:
      return {
        ...state,
        fetchingContacts: new Set(state.fetchingContacts).add(action.payload),
      }
    case CLEAR_CONTACT_FETCHING:
      return {
        ...state,
        fetchingContacts: new Set(),
      }
    case CREATE_CONTACT: {
      return {
        ...state,
        items: concat(state.items, action.payload),
      }
    }
    case SET_COUNTERS: {
      return {
        ...state,
        total_count: action.payload?.count,
        not_contacted: action.payload?.not_contacted_count,
      }
    }
    case EDIT_CONTACT_SUCCESS: {
      const contacts = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
      return {
        ...state,
        items: contacts,
        specificContact: action.payload,
      }
    }
    case DELETE_CONTACT: {
      return {
        ...state,
        items: filter(state.items, (item) => item.id !== action.payload),
      }
    }
    case SET_SPECIFIC_CONTACT: {
      return {
        ...state,
        specificContact: action.payload,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
