import React from "react"
import withMemo from "../../store/HOC/with_memo"
import { Button, Input, Modal } from "antd"
import { useAddBookmark } from "../../hooks/useAddBookmark"
import AddTag from "../add_tag"
import { editLinkTH } from "../../store/actions/links/edit-link"

export const BookmarkModal = withMemo(({ onClose, style }) => {
  const {
    url,
    caption,
    handleChangeUrl,
    handleChangeCaption,
    addTagStep,
    setAddTagStep,
    inputRef,
    handleSubmit,
    specificLink,
  } = useAddBookmark({ onClose })
  return (
    <Modal
      onCancel={onClose}
      open
      title="Add Bookmark"
      footer={null}
      style={style}
    >
      <Input
        placeholder="Please enter a title"
        value={caption}
        onChange={handleChangeCaption}
      />
      <Input
        placeholder="Please enter a url"
        value={url}
        onChange={handleChangeUrl}
      />
      <AddTag
        item={specificLink}
        addTagStep={addTagStep}
        inputRef={inputRef}
        setAddTagStep={setAddTagStep}
        update={editLinkTH}
      />
      <Button type="primary" className="w-100" onClick={handleSubmit}>
        Save
      </Button>
    </Modal>
  )
})
