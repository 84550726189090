import React from "react"
import { Spin } from "antd"
import { useSelector } from "react-redux"
import { getContactOrJobById } from "../store/actions/contacts/get-contacts-list"
import { getGroupedTodosSelector } from "../store/selectors/todos/get_todos_selector"
import { FeaturesTypes } from "../common/constants"
import { useActivityItem } from "../hooks/useActivityItem"
import { getContactsSelector } from "../store/selectors"
import { getJobsSelector } from "../store/selectors"
import EmptyTodoIcon from '../assets/images/Tasks.svg'
import { TodoList } from "./TodoList"

export const TodoContainer = () => {
  const todoIsPending = useSelector((state) => state.todos.isPending)
  const getGrouped = useSelector(getGroupedTodosSelector)
  const contacts = useSelector(getContactsSelector)
  const jobs = useSelector(getJobsSelector)

  const { handleClick } = useActivityItem()
  const handleOpenModal = (todo) => {

    if (todo.contact) {
      const contact = contacts.find((item) => item.id === todo.contact)
      if (contact) {
        handleClick(contact, FeaturesTypes.CONTACT, contact)
      } else {
        getContactOrJobById(todo.contact, FeaturesTypes.CONTACT).then((res) => {
          handleClick(res.data, FeaturesTypes.CONTACT, res.data)
        }
        ).catch(e => console.log(e))
      }

    } else if (todo.job) {
      const job = jobs.find((item) => item.id === todo.job)
      if (job) {
        handleClick(job, FeaturesTypes.LEAD, job)
      } else {
        getContactOrJobById(todo.job, FeaturesTypes.LEAD).then((res) => {
          handleClick(res.data, FeaturesTypes.LEAD, res.data)
        }
        ).catch(e => console.log(e))
      }
    }
  }
  return (
    <div style={{ position: "relative" }}>
      {todoIsPending &&
        <Spin
          style={{
            position: "absolute",
            top: 50,
            right: 430,
            zIndex: 300
          }}
        />
      }
      {getGrouped.length === 0 ? (
        <div style={{
          margin: "50px 0px 0px 45px",
          display: "flex",
          alignItems: "center",
          opacity: todoIsPending ? 0.3 : 1
        }}>
          <div className="home-empty-image-container">
            <img src={EmptyTodoIcon} alt="no todos" className="home-empty-image" />
          </div>
          <span className="home-empty-lead-text" style={{

            lineHeight: "25px",
          }}>
            Bias for action! Write down your todo-s, prioritize and get it done.
            “Put one foot in front of the other” because
            “The journey of a thousand miles begins with a single step."
          </span>
        </div>
      ) : <div style={{ height: '36vh', overflowX: 'hidden', overflowY: 'auto' }}><TodoList containerId={null} isShow={true} todos={getGrouped} handleOpenModal={handleOpenModal} /></div>}
    </div>
  )
}
