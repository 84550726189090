import React, { useEffect, useRef, useState } from "react"
import { Button, Space, Typography, Empty } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"
import { NotesHorizontalList } from "../notes/notes-horizontal-list"
import { useColors } from "../../hooks/useColors"
import { useActivityItem } from "../../hooks/useActivityItem"
import { FeaturesTypes } from "../../common/constants"
import { generateId } from "../../common/helpers"
import NoData from "../../assets/images/conversation_create_svy.svg"

const { Title } = Typography

export const CommunicationList = ({
  toggleCommuniCationModal,
  communications,
}) => {
  const { allColors } = useColors()
  const textAreaRef = useRef()
  const [isEdit, setIsEdit] = useState(false)
  const { handleClick } = useActivityItem()

  const handleOpenEditor = (data, _) => {
    handleClick(
      { id: data.id || generateId() },
      FeaturesTypes.COMMUNICATION,
      data
    )
  }
  useEffect(() => {
    return () => {
      setIsEdit(false)
    }
  }, [])
  useEffect(() => {
    if (isEdit) {
      textAreaRef?.current.focus()
    }
  }, [isEdit])
  return (
    <>
      <Space
        style={{
          display: "flex",
          marginBottom: "13px",
          marginTop: "24px",
          justifyContent: "space-between",
        }}
      >
        <Title className="contact-modal communications-title" level={3}>
          Conversations
        </Title>
        <Button
          type="primary"
          size={"small"}
          onClick={toggleCommuniCationModal}
          style={{ marginTop: "4px" }}
        >
          <PlusOutlined /> New
        </Button>
      </Space>
      <div className="contact-modal communications list">
        {isEmpty(communications) ? (
          <Empty
            className="no-data"
            image={<img src={NoData} alt="no-data" className="img-no-data" />}
            description={<span style={{ color: "rgb(181, 179, 179)" }}>No Conversations</span>}
          />
        ) : (
          <NotesHorizontalList
            notes={communications.map((item) => ({
              ...item,
              title: item.title?.trim() ? item.title : item.body,
            }))}
            isModal={true}
            openEditor={handleOpenEditor}
            allColors={allColors}
            disableDrag
            cantDelete
          />
        )}
      </div>
    </>
  )
}
