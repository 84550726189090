export const SET_TODOS_DATA = "SET_TODOS_DATA"
export const UPDATE_CONTAINER_TITLE = "UPDATE_CONTAINER_TITLE"
export const CREATE_TODO = "CREATE_TODO"
export const UPDATE_TODO = "UPDATE_TODO"
export const SET_NEW_TODO = "SET_NEW_TODO"
export const REMOVE_TODO = "REMOVE_TODO"
export const SET_TODO_PENDING_STATUS = "SET_TODO_PENDING_STATUS"
export const DRAG_TODO_IN_SPECIFIC_CONTAINER = "DRAG_TODO_IN_SPECIFIC_CONTAINER"
export const SET_TODO_FILTER = "SET_TODO_FILTER"
export const CLEAR_ALL_ACTIVE_TODOS = "CLEAR_ALL_ACTIVE_TODOS"
export const RESET_DRAFT_TODO_LIST = "RESET_DRAFT_TODO_LIST"
export const UPDATE_DRAFT_TODO_LIST = "UPDATE_DRAFT_TODO_LIST"
export const REMOVE_DRAFT_TODO = "REMOVE_DRAFT_TODO"
export const UPDATE_DRAFT_TODO = "UPDATE_DRAFT_TODO"
