import { useState } from "react"
import HelpComponent from "../components/help/HelpComponent"
import WhatIsSavvy from "../components/help/what_is_savvyy"
import SavvyyGlossary from "../components/help/savvyy_glossary"

const breadCrumbs = ['Help', 'What is Savvyy?', 'Savvyy Glossary']
export const HelpPage = () => {
    const [breadCrumb, setBreadCrumb] = useState(breadCrumbs[0])

    return (
        <div
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "65%",
                maxWidth: "750px",
                marginBottom: "82px"
            }}>
            {breadCrumb === breadCrumbs[0] && <HelpComponent setBreadCrumb={setBreadCrumb} breadCrumbs={breadCrumbs} />}
            {breadCrumb === breadCrumbs[1] && <WhatIsSavvy setBreadCrumb={setBreadCrumb} breadCrumbs={breadCrumbs} />}
            {breadCrumb === breadCrumbs[2] && <SavvyyGlossary setBreadCrumb={setBreadCrumb} breadCrumbs={breadCrumbs} />}

        </div>
    )
}


