import { FETCH_NOTES, SET_NOTE_PENDING_STATUS } from "../../action-types/notes"
import { request } from "../../../api/axios_helper"
import { COMPANY_API_URL, NOTE_API_URL } from "../../../common/constants"
import { setCompanyNotes } from "../companies/get-companies"

export const setNotes = (payload) => {
  return {
    type: FETCH_NOTES,
    payload,
  }
}
export const setNotesPendingStatus = (payload) => {
  return {
    type: SET_NOTE_PENDING_STATUS,
    payload,
  }
}

export const getALLNotes = () => async (dispatch) => {
  try {
    dispatch(setNotesPendingStatus(true))
    const response = await request().get(NOTE_API_URL, dispatch)
    dispatch(setNotes(response.data))
  } catch (err) {
  } finally {
    dispatch(setNotesPendingStatus(false))
  }
}

export const getNotesByParams = (company_id) => async (dispatch) => {
  dispatch(setNotesPendingStatus(true))
  await request().get(
    `${COMPANY_API_URL}${company_id}/find_notes/`,
    dispatch,
    (res) => {
      dispatch(setCompanyNotes(res.data))
    }
  )
  dispatch(setNotesPendingStatus(false))
}
