import { UPDATE_SPECIFIC_JOB } from "../../action-types/jobs"
import { SET_NEW_TODO, SET_TODO_PENDING_STATUS } from "../../action-types/todos"
// import { getTodosSelector } from "../../selectors"
// import { dragTodoTH } from "./drag-todos"
import { setNewContactTodo } from "../contacts/get-contacts-list"
import { setNewJobTodo } from "../jobs/get-jobs"
import { request } from "../../../api/axios_helper"
import { TODOS_API_URL } from "../../../common/constants"

export const setTodoPendingStatus = (status) => {
  return {
    type: SET_TODO_PENDING_STATUS,
    payload: status,
  }
}
export const updateSpecificLead = (payload) => {
  return {
    type: UPDATE_SPECIFIC_JOB,
    payload,
  }
}
export const setNewTodo = (payload) => {
  return {
    type: SET_NEW_TODO,
    payload,
  }
}

export const createTodoTH =
  (data, isContact, isLead) => async (dispatch, getState) => {
    try {
      // const todos = getTodosSelector(getState())
      dispatch(setTodoPendingStatus(true))
      const response = await request().create(
        `${TODOS_API_URL}`,
        data,
        dispatch
      )
      if (response?.status === 201) {
        if (!isContact && !isLead) {
          dispatch(setNewTodo(response.data))
        }
        if (isContact) {
          dispatch(setNewContactTodo(response.data))
        }
        if (isLead) {
          dispatch(setNewJobTodo(response.data))
        }
        // dispatch(dragTodoTH([response.data].concat(todos)))
        dispatch(setTodoPendingStatus(false))
        return true
      }
    } catch (err) {
      dispatch(setTodoPendingStatus(false))
    }
  }
