import { createSelector } from "reselect"
const getErrorState = (state) => state.errors
export const getErrorsSelector = createSelector(
  getErrorState,
  ({ hasError, errorMessage }) => {
    return {
      hasError,
      errorMessage,
    }
  }
)
