import React, { useCallback } from "react"
import { Tag } from "antd"
import { useSelector } from "react-redux"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"
import { CloseOutlined } from "@ant-design/icons"
import { getFilterByTags } from "../../store/selectors/filers/get_filters_selector"
import { useAction } from "../../hooks/useAction"
import { removeTagFromFilters } from "../../store/actions/filters"

const ColorTagItem = ({
  item,
  handleClickOnTag = () => {},
  closable,
  allColors,
}) => {
  const removeTagAction = useAction(removeTagFromFilters)
  function handleClick() {
    if (handleClickOnTag) {
      handleClickOnTag(item.tag)
    }
  }
  const selectedTagsForFilter = useSelector(getFilterByTags)
  const theTagIsSelected = useCallback(() => {
    return selectedTagsForFilter.some((t) => t === item.tag)
  }, [item.tag, selectedTagsForFilter])
  const handleRemoveTag = (e) => {
    e.stopPropagation()
    removeTagAction(item.tag)
  }
  return (
    <Tag
      onClick={handleClick}
      style={{ borderRadius: "50px", marginRight: "10px", color: "#000000" }}
      color={allColors?.[item.color?.toLowerCase()]?.code}
      key={`${item.tag}-${new Date().getTime()}`}
    >
      {item.tag}
      {theTagIsSelected() && closable && (
        <CloseOutlined
          onClick={handleRemoveTag}
          style={{ marginLeft: "1rem" }}
        />
      )}
    </Tag>
  )
}

export default withShouldUpdate(ColorTagItem)
