import React, { useCallback, useMemo, useState } from "react"
import moment from "moment"
import { DATE_FORMAT } from "../common/config"
import DiaryBadgeItem from "../components/diaries/diary_badge_item"
import { Typography } from "antd"

export const useDiaryItem = ({
  getActivities,
  activites,
  activity_is_pending,
  handleOpenDrawer,
  activity_type,
  items,
}) => {
  const [isLess, setSeeMore] = useState(false)
  const linkStyle = useMemo(
    () => ({
      fontWeight: "500",
      color: "#009EEF",
      cursor: "pointer",
    }),
    []
  )
  const toggleSeeMore = useCallback(() => {
    setSeeMore((prev) => !prev)
  }, [])
  const renderLabel = useCallback(
    (key, items) => {
      return (
        <>
          <div
            className="timeline-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#8C8C8C",
              cursor: "pointer",
            }}
            data-diary-html={items?.diary?.body_html}
            data-content={items?.diary?.body_txt}
            data-config={JSON.stringify(items)}
            data-date={key}
            data-diary={JSON.stringify(items?.diary || {})}
            onClick={handleOpenDrawer}
          >
            <Typography.Text>{moment(key).format(DATE_FORMAT)}</Typography.Text>
            <div
              style={{
                display: "flex",
              }}
            >
              {Object.keys(items)
                .filter((item) => item !== "diary")
                .map((item) => {
                  const count = items[item]
                  const title = item.charAt(0).toUpperCase() + item.slice(1)
                  return (
                    <DiaryBadgeItem
                      key={item}
                      count={count}
                      title={title}
                      activity_type={item}
                      date={key}
                      getActivities={getActivities}
                      activites={activites}
                      activity_is_pending={activity_is_pending}
                    />
                  )
                })}
            </div>
          </div>
        </>
      )
    },
    [handleOpenDrawer, getActivities, activites, activity_is_pending]
  )
  const renderContent = useCallback(() => {
    const content = items?.[activity_type]?.diary?.body_txt
    const hasContent = !!items?.[activity_type]?.diary?.body_txt
    const contentIsLarge = Boolean(content && content.length > 300)
    const _content =
      !isLess && hasContent && contentIsLarge
        ? `${content?.substring(0, 300)}...`
        : content
    return (
      <>
        {hasContent && (
          <div style={{ marginTop: "6px", marginRight: "20px" }}>
            {_content}
          </div>
        )}

        {contentIsLarge && (
          <span onClick={toggleSeeMore} style={linkStyle}>
            {isLess ? "Show Less" : "See More"}
          </span>
        )}
      </>
    )
  }, [activity_type, isLess, items, linkStyle, toggleSeeMore])
  return {
    renderLabel,
    renderContent,
  }
}
