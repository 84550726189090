import {
  SET_CURRENT_TAGS,
  SET_NEW_TAG,
  SET_SELECTED_TAG,
  SET_TAGS_QUERY,
} from "../../action-types/tags"
import { TAG_API_URL } from "../../../common/constants"
import { request } from "../../../api/axios_helper"

export const setNewTag = (payload) => ({ type: SET_NEW_TAG, payload })

export const setTagsQuery = (payload) => ({ type: SET_TAGS_QUERY, payload })

export const setSelectedTag = (payload) => ({
  type: SET_SELECTED_TAG,
  payload,
})

export const setCurrentTags = (payload) => {
  return {
    type: SET_CURRENT_TAGS,
    payload,
  }
}
export const createNewTag = (payload) => (dispatch) => {
  request()
    .create(TAG_API_URL, payload, dispatch)
    .then((res) => {
      dispatch(setNewTag(res.data))
    })
    .catch((error) => {
      throw error
    })
}
