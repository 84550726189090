import React, { useCallback } from "react"
import withMemo from "../../store/HOC/with_memo"
import { cutStringIfLarge } from "../../common/helpers"

export const LinkItem = withMemo(
  ({ link, caption, callback, file, style, hideHttp }) => {
    const handleOpenLink = useCallback(
      (e) => {
        if (!link && !file) {
          return
        }
        e.stopPropagation()
        if (callback && file) {
          callback(file)
          return
        }
        let url = link
        if (!String(url).startsWith("http")) {
          url = `https://${link}`
        }
        window.open(url)
      },
      [link, callback, file]
    )
    const renderLink = useCallback(() => {
      if (hideHttp && (link.startsWith("http") || link.startsWith("https"))) {
        return link.substring(link.indexOf(":") + 3)
      }
      return link
    }, [link, hideHttp])
    const renderContent = () => {
      const content = caption?.trim() ? caption : renderLink()
      return cutStringIfLarge(content, 30, true)
    }
    return (
      <span
        onClick={handleOpenLink}
        style={{
          fontWeight: "500",
          color: "#009EEF",
          cursor: "pointer",
          ...style,
        }}
      >
        {renderContent()}
      </span>
    )
  }
)
