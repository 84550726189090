import {
  CREATE_CONTACT,
  SET_DRAFT_TAGS_CONTACT,
} from "../../action-types/contacts"
import { SET_NEW_COMPANY_CONTACT } from "../../action-types/companies"
import { getCommunicationsSelector, getDraftTodoList } from "../../selectors"
import { isEmpty } from "lodash"
import { createCommunicationTH } from "../communication/create-communication"
import { createTodoTH } from "../todos/create_todo"
import { request } from "../../../api/axios_helper"
import { CONTACT_API_URL } from "../../../common/constants"
import { setContactsPendingStatus } from "./get-contacts-list"

const setNewContact = (payload) => {
  return {
    type: CREATE_CONTACT,
    payload,
  }
}
const setNewCompanyContact = (payload) => {
  return {
    type: SET_NEW_COMPANY_CONTACT,
    payload,
  }
}

export const setDraftTagsContact = (payload) => {
  return {
    type: SET_DRAFT_TAGS_CONTACT,
    payload,
  }
}
export const createContact =
  (data, in_company) => async (dispatch, getState) => {
    try {
      dispatch(setContactsPendingStatus(true))
      const { drafts } = getCommunicationsSelector(getState())
      const draftTodoList = getDraftTodoList(getState())
      const res = await request().create(CONTACT_API_URL, data, dispatch)
      if (res?.status === 201) {
        dispatch(setNewContact(res.data))
        if (in_company) {
          await dispatch(setNewCompanyContact(res.data))
        }
        if (!isEmpty(drafts)) {
          for (let communication of drafts) {
            communication["contact"] = res.data.id
            await dispatch(createCommunicationTH(communication, true))
          }
        }
        if (!isEmpty(draftTodoList)) {
          for (let todo of draftTodoList) {
            todo["contact"] = res.data.id
            await dispatch(createTodoTH(todo, true))
          }
        }
        return res.data
      }
    } catch (err) {
    } finally {
      dispatch(setContactsPendingStatus(false))
    }
  }
