import {
  ADD_COMPANY,
  SET_DRAFT_TAGS_COMPANY,
} from "../../action-types/companies"
import { request } from "../../../api/axios_helper"
import { COMPANY_API_URL } from "../../../common/constants"
import { editNote } from "../notes/edit_note"
import { isEmpty } from "lodash"
import { setCompanyNotes } from "./get-companies"
import { CompanyNotesSelector } from "../../selectors"
export const setNewCompany = (payload) => {
  return {
    type: ADD_COMPANY,
    payload,
  }
}

export const setDraftTagsCompany = (payload) => {
  return {
    type: SET_DRAFT_TAGS_COMPANY,
    payload,
  }
}
const updateCNotesAfterCreationCompany =
  (notes = [], company_id) =>
  async (dispatch) => {
    if (!Array.isArray(notes) || isEmpty(notes)) {
      return
    }
    const companyIdNotExist = notes.every((item) => !item.company_id)
    if (companyIdNotExist) {
      for (const note of notes) {
        await dispatch(editNote({ ...note, company_id, note_type: "C" }))
      }
    }
    dispatch(setCompanyNotes([]))
  }
export const addCompany = (data) => async (dispatch, getState) => {
  try {
    const notes = CompanyNotesSelector(getState())
    const response = await request().create(COMPANY_API_URL, data, dispatch)
    await dispatch(setNewCompany(response.data))
    await dispatch(updateCNotesAfterCreationCompany(notes, response.data.id))
    return response.data
  } catch (err) {}
}
