const globalStyles = {
  components: {
    Tabs: {
      itemSelectedColor: "#4096ff",
    },
    Timeline: {
      dotBorderWidth: "2px",
    },
  },
  token: {
    colorPrimary: "#1890ff",
  },
}

export default globalStyles
