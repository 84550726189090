import { DELETE_WORKSPACE } from "../../action-types/workspaces"
import { setWorkspacePendingStatus } from "./getWorkspaces"
import { WorkspaceService } from "../../services/workspaceService"

export const removeWorkspace = (payload) => {
  return {
    type: DELETE_WORKSPACE,
    payload,
  }
}

export const removeWorkspaceThunk = (workspace_id) => (dispatch) => {
  dispatch(setWorkspacePendingStatus(true))
  WorkspaceService.removeWorkspace(workspace_id)
    .then(() => dispatch(removeWorkspace(workspace_id)))
    .finally(() => dispatch(setWorkspacePendingStatus(false)))
}
