import React, { useCallback, useEffect, useState } from "react"
import {
  Badge,
  Button,
  Col,
  ConfigProvider,
  Empty,
  Row,
  Space,
  Table,
  Typography,
  Spin,
  Tooltip
} from "antd"
import "../styles/contacts.css"
import { DeleteLead } from "../components"
import {
  getContactsPendingStatus,
  getContactsSelector,
} from "../store/selectors"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  LinkedinFilled,
  UploadOutlined
} from "@ant-design/icons"
import { deleteContactTH } from "../store/actions/contacts/delete-contact"
import { DATE_FORMAT } from "../common/config"
import { TagsList } from "../components/tags/TagsList"
import withMemo from "../store/HOC/with_memo"
import { calculateLastPosition, getContentLastContact } from "../common/helpers"
import {
  getContactsTotalCount,
  getNotContactedCount,
} from "../store/selectors/contacts/get-contacts-selector"
import { useColors } from "../hooks/useColors"
import { useTags } from "../hooks/useTags"
import ContactsIcon from "../assets/images/Contacts.svg"
import { useActivityItem } from "../hooks/useActivityItem"
import { FeaturesTypes } from "../common/constants"
import { getContactCounters, getContacts } from "../store/actions/contacts/get-contacts-list"
import { useAction } from "../hooks/useAction"
import { useContactSorting } from "../hooks/useContactSorting"

const { Title, Text } = Typography

const Contacts = () => {
  const dispatch = useDispatch()
  const { allColors } = useColors()
  useTags()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [submitetContact, setSubmitetContact] = useState(null)
  const is_pending = useSelector(getContactsPendingStatus)

  const contactsData = useSelector(getContactsSelector)
  const total_count = useSelector(getContactsTotalCount)
  const notContactedData = useSelector(getNotContactedCount)
  const deleteContactAction = useAction(deleteContactTH)
  const getContactCountersAction = useAction(getContactCounters)
  const getContactsAction = useAction(getContacts)

  const settings = {
    bottom: "bottomRight",
    hasData: true,
    pagination: false,
    showHeader: true,
    size: "small",
    tableLayout: "fixed",
    title: undefined,
    top: "none",
  }

  const { handleTableChange, contactsSortKey, resetContactsAction } = useContactSorting()

  const { handleClick } = useActivityItem()

  const handleDeleteContact = (id) => {
    setSubmitetContact(id)
    setOpenDeleteModal(true)
  }
  const onDeleteContact = async () => {
    await deleteContactAction(submitetContact)
    await getContactCountersAction()
    setOpenDeleteModal(false)
  }




  const dataSourceColumns = [
    {
      title: "Name",
      key: "first_name",
      width: "16.6%",
      className: "scroll-hidden-column",
      render: ({ first_name, lin_url }) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span>{first_name?.slice(0, 30)}</span>
          {lin_url &&
            <a href={lin_url?.includes("http") ? lin_url : 'https://' + lin_url} target="_blank" rel="noopener noreferrer">
              <LinkedinFilled style={{ color: "#009EEF" }} />
            </a>
          }
        </div>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company_name",
      width: "13%",
      className: "scroll-hidden-column",
      render: (company) => <span>{company?.name}</span>,
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      key: "phone",
      width: "10%",
      className: "scroll-hidden-column",
      render: ({ phone, personal_mobile }) => {
        const myPhone = phone || personal_mobile
        return <span>
          {myPhone &&
            myPhone}
        </span>
      }
    },
    {
      title: "Email",
      key: "email",
      width: "10%",
      className: "scroll-hidden-column",
      render: ({ email, personal_email }) => {
        const myEmail = email || personal_email
        return <span>
          {myEmail &&
            myEmail
          }
        </span>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Location",
      key: "city",
      dataIndex: "city",
      maxWidth: "24%",
      render: (city) => {
        return <span>{city}</span>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Date Added",
      key: "create_dt",
      dataIndex: "create_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.create_dt) - new Date(a.create_dt)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Last Contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "10%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7);
        const days = totalDays % 7;
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => <TagsList tags={tags} allColors={allColors} />,
    },
    {
      title: "Actions",
      width: "7%",
      render: (_, c) => {
        return (
          <>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation()
                handleEditContactTools(c)
              }}
              style={{ marginRight: "1rem" }}
            />
            <DeleteOutlined onClick={(e) => {
              e.stopPropagation()
              handleDeleteContact(c.id)
            }} />
          </>
        )
      },
    },
  ]
  const onOpenContactModal = () => {
    handleClick({
      id: 'newContact'
    },
      FeaturesTypes.CONTACT,
      {},
      {
        callBackFun: getContactCountersAction,
        status: "add", isEdit: false, isAdd: true, is_communication_edit: false, defaultActiveKey: "1",
      })
  }

  const handleEditContactTools = (contact) => {
    handleClick(contact,
      FeaturesTypes.CONTACT,
      contact,
      { callBackFun: getContactCountersAction, defaultActiveKey: "2", })
  }

  useEffect(() => {
    getContactsAction()
    return () => {
      resetContactsAction()
    }
    //eslint-disable-next-line
  }, [])

  const handleLoadMore = useCallback(() => {
    dispatch(getContacts())
  }, [dispatch])
  const handleScroll = useCallback(
    (e) => {
      if (contactsData.length < total_count)
        return calculateLastPosition(e, handleLoadMore)
    },
    [contactsData.length, handleLoadMore, total_count]
  )
  useEffect(() => {
    const tableContent = document.querySelector(".ant-table-body")
    if (tableContent) {
      tableContent.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener("scroll", handleScroll)
      }
    }

  }, [handleScroll])
  return (
    <Row className="contact-container ">
      <Col
        span={24}
        style={{ display: "flex", padding: "0px 8px", marginBottom: "16px" }}
      >
        <Title style={{ display: "flex" }} className="contact-title" level={4}>
          Contacts
          <Tooltip title="Bulk Upload">
            <UploadOutlined onClick={() => handleClick({ id: FeaturesTypes.FILE_UPLOAD }, FeaturesTypes.FILE_UPLOAD, {})} style={{ marginLeft: "20px", cursor: "pointer" }} />
          </Tooltip>
        </Title>
        <Space>
          <Text className="contact-subtitle">Total</Text>
          <Badge
            style={{ marginRight: "16px", backgroundColor: "#3CCC7A" }}
            showZero
            count={total_count}
            overflowCount={1000}
          />
        </Space>
        <Space>
          <Text className="contact-subtitle">Not Contacted</Text>
          <Badge
            style={{ marginRight: "16px", backgroundColor: "F25041" }}
            showZero
            count={notContactedData}
            overflowCount={1000}
          />
        </Space>
        <Button
          type="primary"
          size={"default"}
          onClick={onOpenContactModal}
        >
          <PlusOutlined />
          New
        </Button>
      </Col>
      <Col style={{ position: "relative" }} span={24}>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              className="app-empty"
              style={{ opacity: is_pending ? 0.5 : 1, padding: "20px" }}
              image={<img src={ContactsIcon} alt="contacts-icon" className="home-empty-image" />}
              description={
                <span style={{ fontSize: 16 }}>
                  Because “it’s who you know”. Track your contacts, record your interactions, get periodic reminders to reach out, build your network.
                </span>
              }
            />
          )}
        >
          <Spin spinning={is_pending} style={{
            position: "absolute", zIndex: 1, left: "49%", top: "50%"
          }} />
          <Table
            {...settings}
            className={contactsData.length > 0 ? "" : "table-empty"}
            showHeader={contactsData.length > 0 || contactsSortKey !== null}
            scroll={{ y: "510px", scrollToFirstRowOnChange: false }}
            pagination={false}
            rowKey={(row) => row.id}
            columns={dataSourceColumns}
            dataSource={contactsData}
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  handleEditContactTools(record)
                },
              }
            }}
          />
        </ConfigProvider>
      </Col>

      {openDeleteModal && (
        <DeleteLead
          visible={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          status={submitetContact}
          onDelete={onDeleteContact}
        />
      )}
    </Row>
  )
}

export default withMemo(Contacts)
