import axios from "axios"
import {
  setErrorMessage,
  setErrorState,
} from "../store/actions/errors/set_errors"
import {
  CONFIRM_USER_EMAIL_API_URL,
  CONNECTION_ISSUE_MESSAGE,
  CUSTOM_ERROR_MESSAGE,
  REFRESH_TOKEN_API_URL,
  VERIFY_TOKEN_API_URL,
} from "../common/constants"
import {
  getCookie,
  setRefreshingStatus,
  setToken,
  setUserIsAuth,
} from "../store/actions/user"
import store from "../store/"
import { jwtParse } from "../common/helpers"
import { getRefreshToken } from "../store/selectors"

export const buildAuthHeaders = async () => {
  const token = await getToken()
  let defaultHeader = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  if (getCookie("csrftoken")) {
    defaultHeader = {
      headers: {
        ...defaultHeader.headers,
        "X-CSRFToken": getCookie("csrftoken"),
      },
    }
  }
  return defaultHeader
}
let _refresh_token = null
const tokenCreator = () => {
  return axios.post(REFRESH_TOKEN_API_URL, {})
}
export const refresh_token = () => {
  if (!_refresh_token) {
    _refresh_token = tokenCreator()
  }
  return _refresh_token
}
const getToken = async (refresh = false) => {
  let token = getRefreshToken(store.getState())
  if (refresh || tokenIsExpired(token)) {
    token = null
    store.dispatch(setToken(null))
  }
  if (!token) {
    try {
      const response = await refresh_token()
      token = response?.data?.access
      localStorage.setItem("logged_in", 1)
      store.dispatch(setToken(token))
    } catch (err) {
      store.dispatch(setUserIsAuth(false))
    }
  }
  return token
}
const tokenIsExpired = (token) => {
  if (!token) {
    return true
  }
  try {
    return jwtParse(token).exp - Date.now() / 1000 < 10
  } catch (e) {
    return true
  }
}
axios.interceptors.response.use(
  function (response) {
    store.dispatch(setErrorMessage(""))
    store.dispatch(setErrorState(false))
    store.dispatch(setRefreshingStatus(false))
    if (response.config.url.includes(REFRESH_TOKEN_API_URL)) {
      localStorage.setItem("logged_in", 1)
    }

    _refresh_token = null
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (originalRequest?.url?.includes(CONFIRM_USER_EMAIL_API_URL)) {
      return
    }
    if (error.message === "Network Error") {
      await dispatchIfHasError(store.dispatch, {
        response: true,
        message: CONNECTION_ISSUE_MESSAGE,
      })
    }
    if (error?.response?.status === 500) {
      await dispatchIfHasError(store.dispatch, {
        response: true,
        message: CUSTOM_ERROR_MESSAGE,
      })
    }
    if (
      error.response?.status === 401 &&
      originalRequest.url.includes(REFRESH_TOKEN_API_URL)
    ) {
      localStorage.setItem("logged_in", 0)
      store.dispatch(setUserIsAuth(false))
      return
    } else if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      const token = await getToken(true)
      originalRequest.headers.Authorization = `Bearer ${token}`
      return axios(originalRequest)
    }
    return Promise.reject(error)
  }
)
export const dispatchIfHasError = async (dispatch, err) => {
  if (!err.response || err.response?.status === 401) {
    return
  }
  dispatch(setErrorMessage(err.message))
  dispatch(setErrorState(true))
}

export const request = (restConfigs = {}) => {
  return {
    get: async (url, dispatch = store.dispatch, cb) => {
      const def_config = await buildAuthHeaders()
      const config = {
        ...def_config,
        ...restConfigs,
      }
      return new Promise((resolve, reject) => {
        axios
          .get(url, config)
          .then((res) => {
            if (cb) {
              cb(res)
            }
            resolve(res)
            return res
          })
          .catch((err) => {
            if (dispatch) {
              dispatchIfHasError(dispatch, err)
            }
            reject(err)
          })
      })
    },
    create: async (
      url,
      data,
      dispatch = store.dispatch,
      onUploadProgress,
      cb
    ) => {
      const default_config = await buildAuthHeaders()
      const config = onUploadProgress
        ? {
            ...default_config,
            onUploadProgress,
          }
        : {
            ...default_config,
          }
      return new Promise((resolve, reject) => {
        axios
          .post(url, data, config)
          .then((res) => {
            if (cb) {
              cb(res)
            }
            resolve(res)
            return res
          })
          .catch((err) => {
            if (dispatch) {
              dispatchIfHasError(dispatch, err)
            }
            reject(err)
            return err
          })
      })
    },
    edit: async (url, data, dispatch = store.dispatch, method, cb) => {
      const default_config = await buildAuthHeaders()
      return new Promise((resolve, reject) => {
        axios[method](url, data, default_config)
          .then((res) => {
            if (cb) {
              cb()
            }
            resolve(res)
            return res
          })
          .catch((err) => {
            if (cb) {
              cb(err.response.data)
            }
            if (dispatch && err?.response?.status !== 400) {
              dispatchIfHasError(dispatch, err)
            }
            reject(err)
          })
      })
    },
    delete: async (url, dispatch = store.dispatch, cb) => {
      const config = await buildAuthHeaders()
      return new Promise((resolve, reject) => {
        axios
          .delete(url, config)
          .then((res) => {
            if (cb && res?.status === 204) {
              cb()
            }
            resolve(res)
            return res
          })
          .catch((err) => {
            if (dispatch) {
              dispatchIfHasError(dispatch, err)
            }
            reject(err)
            throw err
          })
      })
    },
    checkToken: async (onSuccess, onFailure, dispatch = store.dispatch) => {
      try {
        const token = await getToken()
        if (!token) {
          onFailure()
          return
        }
        const verify = await axios.post(VERIFY_TOKEN_API_URL, { token })
        if (verify?.status === 200) {
          await onSuccess()
        }
      } catch (e) {
        console.log(e)
        onFailure()
      }
    },
  }
}
