import {
  SET_NEW_TAG,
  SET_TAGS_LIST,
  EDIT_TAG_SUCCESS,
  REMOVE_TAG_SUCCESS,
  SET_TAGS_QUERY,
  SET_SELECTED_TAG,
  SET_CURRENT_TAGS,
  UPDATE_CURRENT_TAGS,
} from "../../action-types/tags"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  items: [],
  isPending: false,
  query: "",
  selectedTag: {},
  currentTags: [],
}

export const tagsReducer = (
  state = initState,
  { type, payload, tagPrevName }
) => {
  switch (type) {
    case RESET_APPLICATION_DATA: {
      return {
        items: [],
        isPending: false,
        query: "",
        selectedTag: {},
        currentTags: [],
      }
    }
    case UPDATE_CURRENT_TAGS: {
      return {
        ...state,
        currentTags: state?.currentTags?.map((item) => {
          return item.tag === (tagPrevName || payload.tag) ? payload : item
        }),
      }
    }
    case SET_CURRENT_TAGS: {
      return {
        ...state,
        currentTags: payload || [],
      }
    }
    case SET_SELECTED_TAG: {
      return {
        ...state,
        selectedTag: payload,
      }
    }
    case SET_TAGS_QUERY: {
      return {
        ...state,
        query: payload,
      }
    }
    case REMOVE_TAG_SUCCESS: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload),
      }
    }
    case EDIT_TAG_SUCCESS: {
      return {
        ...state,
        items: state?.items?.map((item) => {
          return item.id === payload.id ? payload : item
        }),
      }
    }
    case SET_TAGS_LIST: {
      return {
        ...state,
        items: payload,
      }
    }
    case SET_NEW_TAG: {
      return {
        ...state,
        items: [...state.items].concat(payload),
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
