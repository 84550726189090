import {
  GET_ALL_FILES,
  SET_FILES_PENDING_STATUS,
  ON_CHANGE_UPLOAD_PROGRESS,
  ON_UPLOAD_ERROR,
  SET_NEW_FILE,
  REMOVE_FILE,
  UPDATE_FILE_SUCCESS,
  SET_FILE_TYPE,
  SET_FILTERED_ATTACHMENTS,
  SET_EXACT_FILE_PENDING_STATUS,
} from "../../action-types/file_manager"
import { RESET_APPLICATION_DATA } from "../../action-types/user"

const initState = {
  files_list: [],
  isPending: false,
  upload_progress: 0,
  file_type: "R",
  filtered_attachments: [],
  currentPendingFile: {},
}

export const filesList = (state = initState, action) => {
  switch (action.type) {
    case RESET_APPLICATION_DATA: {
      return {
        files_list: [],
        isPending: false,
        upload_progress: 0,
        file_type: "R",
        filtered_attachments: [],
        currentPendingFile: {},
      }
    }
    case SET_EXACT_FILE_PENDING_STATUS: {
      return {
        ...state,
        currentPendingFile: action.payload,
      }
    }
    case SET_FILTERED_ATTACHMENTS: {
      return {
        ...state,
        filtered_attachments: action.payload,
      }
    }
    case SET_FILE_TYPE: {
      return {
        ...state,
        file_type: action.payload,
      }
    }
    case UPDATE_FILE_SUCCESS: {
      return {
        ...state,
        files_list: state.files_list.map((file) => {
          return file.id === action.payload.id ? action.payload : file
        }),
      }
    }
    case REMOVE_FILE: {
      return {
        ...state,
        files_list: state.files_list.filter((item) => {
          return item.id !== action.payload
        }),
      }
    }
    case SET_NEW_FILE: {
      return {
        ...state,
        files_list: [action.payload].concat(state.files_list),
      }
    }
    case ON_UPLOAD_ERROR: {
      return {
        ...state,
        hasError: true,
      }
    }
    case ON_CHANGE_UPLOAD_PROGRESS: {
      return {
        ...state,
        upload_progress: action.payload,
        hasError: false,
      }
    }
    case GET_ALL_FILES: {
      return {
        ...state,
        files_list: action.payload,
        hasError: false,
      }
    }
    case SET_FILES_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
        hasError: false,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
