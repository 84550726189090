import React from "react"
import { Button, Collapse } from "antd"
import { generateId } from "../../common/helpers"
import { PlusCircleOutlined } from "@ant-design/icons"

const { Panel } = Collapse

export const TodoItems = () => {
  const todos = [
    {
      text: "Hello world",
    },
    {
      text: "Hello world",
    },
    {
      text: "Hello world",
    },
  ]
  return (
    <div
      className="communications-collapse align-center d-flex w-80 flex-start"
      style={{ marginTop: "1rem" }}
    >
      <span style={{ marginRight: ".8rem", width: "120px" }}>ToDo Item</span>
      <Collapse>
        {todos.map((item) => (
          <Panel header="This is panel header 1" key={generateId()}>
            <p>{item.text}</p>
          </Panel>
        ))}
      </Collapse>
      <div
        style={{
          marginBottom: "auto",
          marginLeft: "1rem",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          size={"default"}
        />
      </div>
    </div>
  )
}
