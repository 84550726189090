import React from "react"
import { Checkbox, Form, Typography } from "antd"

const { Title } = Typography

export const EmailPreferences = ({ initValue, setInitValue }) => {
  return (
    <>
      <Title level={2}>Email Preferences</Title>

      <Form.Item>

        <div className="d-flex">
          <Checkbox
            checked={initValue.diary_sw}
            onChange={() => {
              setInitValue({ ...initValue, diary_sw: !initValue.diary_sw })
            }}
          >
            Diary Notifications
          </Checkbox>
          <Checkbox
            checked={initValue.summary_sw}
            onChange={() => {
              setInitValue({ ...initValue, summary_sw: !initValue.summary_sw })
            }}
          >
            Activity Summary
          </Checkbox>
          <Checkbox
            checked={initValue.reminder_sw}
            onChange={() => {
              setInitValue({
                ...initValue,
                reminder_sw: !initValue.reminder_sw,
              })
            }}
          >
            Todo Reminders
          </Checkbox>
        </div>
      </Form.Item>
    </>
  )
}
