import { REMOVE_LINK } from "../../action-types/notes"
import { request } from "../../../api/axios_helper"
import { LINKS_API_URL } from "../../../common/constants"
import { setLinksPendingStatus } from "../links/get_links"

export const removeLinkAc = (payload) => {
  return {
    type: REMOVE_LINK,
    payload,
  }
}

export const removeLink = (link) => async (dispatch) => {
  try {
    dispatch(setLinksPendingStatus(true))
    await request().delete(`${LINKS_API_URL}${link}/`, dispatch, () => {
      dispatch(removeLinkAc(link))
    })
  } catch (err) {
  } finally {
    dispatch(setLinksPendingStatus(false))
  }
}
