import { useMemo } from "react"
import ReactQuill from "react-quill"

const MiniQuillEditor = ({
    onChange,
    content,
    editorRef,
}) => {

    const modules = useMemo(
        () => ({
            toolbar: false,
        }),
        []
    )
    return (
        <ReactQuill
            className={"mini-quill"}
            id={'mini-quill-editor'}
            ref={editorRef}
            defaultValue={content}
            onChange={onChange}
            modules={modules}
            onBlur={() => {
                document.querySelector("#mini-quill-editor").classList.remove("focus")
            }}
            onFocus={() => {
                document.querySelector("#mini-quill-editor").classList.add("focus")
            }}
        />
    )
}

export default MiniQuillEditor
