import { EDIT_LINK_SUCCESS } from "../../action-types/links"
import { request } from "../../../api/axios_helper"
import { LINKS_API_URL } from "../../../common/constants"
import { setLinksPendingStatus } from "./get_links"

export const setUpdatedLink = (payload) => ({
  type: EDIT_LINK_SUCCESS,
  payload,
})

export const editLinkTH = (data) => (dispatch) => {
  dispatch(setLinksPendingStatus(true))
  request()
    .edit(`${LINKS_API_URL}${data.id}/`, data, dispatch, "patch")
    .then((res) => dispatch(setUpdatedLink(res.data)))
    .finally(() => dispatch(setLinksPendingStatus(false)))
}
