/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getContactDraftTags,
  getContactTodosSelector,
  getSpecificContact,
} from "../store/selectors/contacts/get-contacts-selector"
import { setSpecificContact } from "../store/actions/contacts/get-contacts-list"
import {
  getCompanyResults,
  getSpecificCompany,
  getCompaniesSelector,
} from "../store/selectors/companies/get-companies-selector"
import { getCommunicationsSelector, getDraftTodoList } from "../store/selectors"
import { useLocation } from "react-router-dom"
import { createContact } from "../store/actions/contacts/create-contact"
import { contactEdit } from "../store/actions/contacts/edit-contact"
import { getCompany } from "../store/actions/companies/get-companies"
import moment from "moment"
import {
  createCommunicationTH,
  resetDrafts,
} from "../store/actions/communication/create-communication"
import { handleCompanyQueryChange } from "../store/actions/contacts/search-company"
import { updateCommunication } from "../store/actions/communication/update-communication"
import { removeCommunication } from "../store/actions/communication/remove-communication"
import { deleteContactTH } from "../store/actions/contacts/delete-contact"
import { getCommunications } from "../store/actions/communication/get-communications"
import { getContactTodos } from "../store/actions/contacts/get-contacts-list"
import { setDraftTagsContact } from "../store/actions/contacts/create-contact"
import { useTags } from "./useTags"
import {
  updateDraftTodoList,
  resetDraftTodoList,
} from "../store/actions/todos/draft_todos"
import { useActivityItem } from "./useActivityItem"
import { FeaturesTypes } from "../common/constants"
import { debounce, isEqual } from "lodash"
import { getAntdModals, getIsUILoading } from "../store/selectors/ui/get-ui"
import { useAction } from "./useAction"
import { updateModalProps } from "../store/actions/ui/update_ui"

export const useAddContact = (
  status,
  onClose,
  saveCompany,
  setDefaultKey,
  setShowError
) => {
  const linkInputEl = useRef(null)
  const firstNameRef = useRef(null)
  const [companyIsEdit, setCompanyIsEdit] = useState(false)
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  const inputRef = useRef()
  const contact = useSelector(getSpecificContact)
  const dispatch = useDispatch()
  const specificCompany = useSelector(getSpecificCompany)
  const companiesResults = useSelector(getCompanyResults)
  const companies = useSelector(getCompaniesSelector)
  const currentTags = useSelector(getContactDraftTags)
  const [openAddCommunications, setOpenAddCommunications] = useState(false)
  const communications = useSelector(getCommunicationsSelector)
  const [confirmModal, setConfirmModal] = useState(false)
  const todos = useSelector(getContactTodosSelector)
  const draftTodos = useSelector(getDraftTodoList)
  const antd_modals = useSelector(getAntdModals)
  const [isChanged, setIsChanged] = useState(false)
  const [addTagStep, setAddTagStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const isSearchCompanyLoading = useSelector(getIsUILoading)
  useTags()
  const [popOverVisible, handleVisibleChange] = useState(false)

  const location = useLocation()
  const [data, setData] = useState({
    ...contact,
  })
  const [urlIsEdit, setUrlIsEdit] = useState(false)

  const createContactAction = useAction(createContact)
  const updateContactAction = useAction(contactEdit)
  const deleteContactAction = useAction(deleteContactTH)
  const updateModalPropsAction = useAction(updateModalProps)
  const setSpecificContactAction = useAction(setSpecificContact)

  const { handleClick } = useActivityItem()

  const isCompanyExist = useCallback(() => {
    return companies?.find((item) => {
      return item?.name?.trim() === data?.company?.name?.trim()
    })
  }, [companies, data?.company?.name])

  const isClickOnCompany = useCallback(
    () => Boolean(data.first_name && isCompanyExist()),
    [data.first_name, isCompanyExist]
  )
  const isClickable = isClickOnCompany()

  const handleOpenCompanyEdit = () => {
    const myCompany = isCompanyExist()
    const isClickable = isClickOnCompany()
    if (myCompany && isClickable) {
      handleClick(myCompany, FeaturesTypes.COMPANY, myCompany)
    }
  }

  const error = useCallback(() => {
    setShowError(true)
    setDefaultKey("1")
    setTimeout(() => firstNameRef?.current?.focus(), 100)
  }, [setDefaultKey, setShowError])

  const autoCreateContact = useCallback(async () => {
    setIsLoading(true)
    try {
      if (status === "add") {
        const editData = { ...data, tags: currentTags }
        if (saveCompany) {
          const company = await saveCompany()
          const isExist = Boolean(
            company?.name?.trim() === data?.company?.name?.trim()
          )
          if (isExist) {
            editData.company_id = company?.id
            delete editData.company
          }
        }
        if (!data.company?.name) {
          delete editData.company
        }
        const in_company = antd_modals.some(
          (m) => m.type === FeaturesTypes.COMPANY
        )
        const c = await createContactAction(editData, in_company)
        await setSpecificContactAction(c)
        await updateModalPropsAction({
          isAdd: false,
          isEdit: true,
          status: "edit",
          is_communication_edit: true,
          type: FeaturesTypes.CONTACT,
        })
        setIsChanged(false)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [
    data,
    status,
    error,
    antd_modals,
    openAddCommunications,
    currentTags,
    saveCompany,
  ])

  const onToggleConversationModal = useCallback(async () => {
    if (!data.first_name) {
      error()
      return
    } else {
      await autoCreateContact()
      await handleClick(
        { id: "newConvo" },
        FeaturesTypes.MINI_COMMUNICATION,
        {},
        { isContact: true }
      )
    }
  }, [data, dispatch, status])
  const handleCloseCommunications = () => {
    setOpenAddCommunications(false)
    const modalContents = document.querySelectorAll(".ant-modal-body")
    const myModal = modalContents[modalContents.length - 2]
    if (myModal?.tabIndex) {
      myModal.tabIndex = 1
      myModal?.focus()
    }
  }

  const handleSubmit = useCallback(async () => {
    const editData = { ...data, tags: currentTags }
    if (!editData.first_name) {
      error()
      return
    }
    const testCompanyIsExist = companies?.find((item) => {
      return item?.name?.trim() === editData["company"]?.name?.trim()
    })
    if (testCompanyIsExist) {
      delete editData["company"]
      editData["company_id"] = testCompanyIsExist.id
    } else {
      delete editData["company_id"]
      editData["company"] = { ...editData.company, tags: [] }
    }
    const in_company = antd_modals.some((m) => m.type === FeaturesTypes.COMPANY)
    if (status === "add") {
      if (saveCompany) {
        const company = await saveCompany()
        const isExist = Boolean(
          company?.name?.trim() === data?.company?.name?.trim()
        )
        if (isExist) {
          editData.company_id = company?.id
          delete editData.company
        }
      }
      if (!data.company?.name) {
        delete editData.company
      }
      await createContactAction(editData, in_company)
    } else if (status === "edit") {
      const newContact = { ...contact }
      newContact?.company?.tags && delete newContact.company.tags
      editData?.company?.tags && delete editData.company.tags
      editData?.tags && delete editData.tags
      newContact?.tags && delete newContact.tags
      for (const i in editData) {
        if (!editData.hasOwnProperty(i) || !newContact.hasOwnProperty(i)) {
          continue
        }
        if (isEqual(editData[i], newContact[i])) {
          delete editData[i]
        }
      }

      editData.id = contact?.id
      if (!data?.company?.name) {
        delete editData.company
        editData.company_id = null
      }
      delete editData["last_edit_dt"]
      await updateContactAction(editData)
    }
    onClose()
    setData({})
    setShowError(false)
  }, [
    data,
    currentTags,
    specificCompany.id,
    saveCompany,
    status,
    onClose,
    error,
    location.pathname,
    dispatch,
    contact,
  ])

  const addCommunication = (communication) => {
    if (status === "edit" && contact !== null) {
      communication["contact"] = contact?.id
      communication["create_dt"] = moment(communication.create_dt).toISOString()
      createCommunicationTH(communication, true)
    }
  }
  const handleChangeData = (value, name) => {
    if (!isChanged) {
      setIsChanged(true)
    }
    let values = { ...data }
    values[name] = value
    setData(values)
  }
  const handleCompanySearch = (query) => {
    dispatch(handleCompanyQueryChange(query))
  }
  const debouncedAction = useCallback(debounce(handleCompanySearch, 700), [])
  const handleSave = (value) => {
    if (status === "add") {
      dispatch(updateCommunication(value, true))
    } else {
      dispatch(updateCommunication(value))
    }
  }

  const handleSaveTodo = useCallback(
    async (value) => {
      dispatch(updateDraftTodoList(value))
      if (!data.first_name) {
        error()
      } else {
        await autoCreateContact()
      }
    },
    [data, dispatch, status]
  )
  const handleRemoveCommunication = (id) => {
    dispatch(removeCommunication(id, Boolean(status === "add")))
  }
  const handleCloseOrConfirm = (e) => {
    if (pickerIsOpen) return
    if (e.keyCode === 27 && addTagStep > 0) {
      e.stopPropagation()
      e.preventDefault()
      setAddTagStep((prev) => prev - 1)
      return
    }

    if (isChanged && !specificCompany?.id) {
      setConfirmModal(true)
    } else {
      onClose()
    }
  }

  const onDeleteContact = async () => {
    if (!contact?.id) {
      onClose()
      return
    }
    await deleteContactAction(contact?.id)
    onClose()
  }
  const hide = () => {
    handleVisibleChange(false)
  }

  const unhide = () => {
    handleVisibleChange(true)
    if (data.lin_url) setUrlIsEdit(false)
    setTimeout(() => linkInputEl?.current?.focus(), 100)
  }
  const onEditLinUrl = (e) => {
    if (
      e.target.innerText === "Cancel" ||
      e.target.innerText === "Clear link ?"
    )
      return
    hide()
  }
  const handleClickOnLinkedin = () => {
    const url = data?.lin_url?.trim() ? data.lin_url : null
    if (url) {
      const target = url.includes("http" || "https") ? url : "http://" + url
      window.open(target)
    } else {
      unhide()
    }
  }
  const handleChangeCompany = useCallback(
    (company) => {
      const isExist = companiesResults?.find(
        (item) => item.name.trim() === company.trim()
      )
      if (isExist) {
        setData((prev) => ({
          ...prev,
          company_id: isExist.id,
          company: {
            ...isExist,
            name: company,
          },
        }))
        return
      }
      if (typeof company === "string") {
        setData({
          ...data,
          company: {
            name: company,
          },
        })
        return
      }
      setData({ ...data, company })
    },
    [companiesResults, data]
  )
  useEffect(() => {
    if (contact !== null && status === "edit" && contact?.id) {
      dispatch(getCommunications(`?contact_id=${contact?.id}`))
      dispatch(getContactTodos(`?contact_id=${contact?.id}`))
    }
    firstNameRef?.current?.focus()
  }, [])
  useEffect(() => {
    dispatch(resetDrafts())
    dispatch(resetDraftTodoList())
    // if (isEmpty(companies)) {
    //   dispatch(getCompaniesTh())
    // }
    if (!isCompanyExist() && data?.company?.id) {
      dispatch(getCompany(data?.company?.id))
    }
    return () => {
      dispatch(setDraftTagsContact([]))
      setData({})
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEqual(data.company, contact.company)) {
      if (contact.company) {
        setData((prev) => {
          return {
            ...prev,
            company: contact.company,
          }
        })
      }
    }
  }, [contact.company])

  const items = [
    {
      value: "0",
      label: "None",
    },
    {
      value: "3",
      label: "3 Mo",
    },
    {
      value: "6",
      label: "6 Mo",
    },
    {
      value: "9",
      label: "9 Mo",
    },
    {
      value: "12",
      label: "12 Mo",
    },
  ]
  return {
    items,
    handleCloseOrConfirm,
    data,
    handleChangeData,
    onEditLinUrl,
    contact,
    setData,
    linkInputEl,
    popOverVisible,
    confirmModal,
    setConfirmModal,
    openAddCommunications,
    handleCloseCommunications,
    addCommunication,
    communications,
    todos,
    unhide,
    hide,
    handleClickOnLinkedin,
    addTagStep,
    inputRef,
    firstNameRef,
    setAddTagStep,
    handleSubmit,
    onDeleteContact,
    handleCompanySearch: debouncedAction,
    handleChangeCompany,
    companiesResults,
    setPickerIsOpen,
    handleRemoveCommunication,
    handleSave,
    setOpenAddCommunications,
    specificCompany,
    handleSaveTodo,
    draftTodos,
    urlIsEdit,
    setUrlIsEdit,
    companyIsEdit,
    setCompanyIsEdit,
    isClickable,
    handleOpenCompanyEdit,
    onToggleConversationModal,
    isLoading,
    isSearchCompanyLoading,
  }
}
