import React from "react"
import { Typography } from "antd"
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import withShouldUpdate from "../../store/HOC/withShouldUpdate"
import { FeaturesTypes } from "../../common/constants"
import { useDrop } from "react-dnd"
import withConfirmModal from "../../store/HOC/withConfirmModal"

const { Text } = Typography

const DraggableWorkspaceTabItem = ({
  tab,
  isEdit,
  handleChangeTab,
  current_workspace = {},
  renderInput,
  handleRemoveWorkspace,
  handleEditWorkspace,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: FeaturesTypes.NOTE,
    drop: () => ({ ...tab }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const handleDoubleClick = (e) => {
    e.stopPropagation()
    handleEditWorkspace(e, tab.id)
  }
  return (
    <div
      ref={drop}
      role={tab.title}
      data-workspace_id={tab.id}
      className={`ant-tabs-tab workspace_container`}
      key={tab.id}
      onClick={handleChangeTab}
      style={{
        borderRadius: "6px",
        padding: "8px 16px",
        border: `${
          canDrop && !isOver
            ? "1px dashed gray"
            : canDrop && isOver
            ? "1px dashed #3ccc7a"
            : "1px solid transparent"
        }`,
        background: `${canDrop && isOver ? "#3ccc7a" : "none"}`,
        color: `${canDrop && isOver ? "white" : "inherit"}`,
      }}
    >
      {isEdit && current_workspace.id === tab.id ? (
        renderInput()
      ) : (
        <div className="docs-close-icons d-flex space-between">
          <Text
            className="workspace-draggable-tab"
            onDoubleClick={handleDoubleClick}
          >
            {tab.title}
          </Text>
          {!tab.is_dafault && (
            <CloseOutlined
              component={DeleteOutlined}
              style={{
                cursor: "pointer",
              }}
              onClick={handleRemoveWorkspace}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default withConfirmModal(withShouldUpdate(DraggableWorkspaceTabItem))
