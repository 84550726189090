import { TagsSelector } from "./get-tag-pending-status"
import { createSelector } from "reselect"

export const getTagsQuery = createSelector(TagsSelector, (state) => state.query)
export const getAllTags = createSelector(TagsSelector, (state) => state.items)
export const getFilteredTags = createSelector(
  [getAllTags, getTagsQuery],
  (items, query = "") => {
    if (!items || !Array.isArray(items)) {
      return []
    }
    let _items = items
    if (typeof query === "string" && query.trim()) {
      _items = items.filter((item) => {
        return String(item.tag)
          .trim()
          .toLowerCase()
          .includes(String(query.trim().toLowerCase()))
      })
    }

    return _items.sort((a, b) => {
      const _firstTagName = a.tag?.toLowerCase() || ""
      const _secondTagName = b.tag?.toLowerCase() || ""
      return _firstTagName < _secondTagName
        ? -1
        : _firstTagName > _secondTagName
        ? 1
        : 0
    })
  }
)

export const getSelectedTag = createSelector(TagsSelector, (state) => {
  return state.selectedTag
})

export const getCurrentTags = createSelector(
  TagsSelector,
  (state) => state.currentTags
)
